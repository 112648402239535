import { AuthError } from '@azure/msal-browser';
import { Result } from 'antd';
import { useTranslation } from 'react-i18next';

export type MsalErrorProps = {
  error?: AuthError;
};

function createMessageError(authError?: AuthError) {
  if (!authError) {
    return '';
  }
  const code = authError?.errorMessage;
  const message = authError?.subError;
  return `${code} ${message}`;
}

export function MsalError({ error }: MsalErrorProps) {
  const { t } = useTranslation();

  const missingAuthorizationPermission = new AuthError('401', t('login-unauthorized'));

  const errorMessage = createMessageError(error);
  const defaultMessage = createMessageError(missingAuthorizationPermission);

  return (
    <div className="App">
      <div className="login-wrapper">
        <div className="login-section logo">
          <img
            src={`${process.env.PUBLIC_URL}/images/electrolux-logo.png`}
            alt="logo"
            className="login-logo"
          />
        </div>
        <div className="login-section">
          <Result status="warning" title={error ? errorMessage : defaultMessage} />
        </div>
      </div>
    </div>
  );
}
