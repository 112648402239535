import { AsyncThunk, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { UploadCurrencyRequest } from '../../models/currency';
import {
  DropDown,
  ProjectModelInfo,
  DropDownDataType,
  DropDownKey,
  DropDownKeyMultiple,
} from '../../models/shared';
import { GeneralService } from '../../services/shared';
import { AppStatus } from '../generalTypes';

export interface GeneralState {
  sStatus: AppStatus;
  oDropdowns: Record<DropDownKey, DropDown[]>;
  sExchangeRateFileStatus: AppStatus;
  sProjectStatus: AppStatus;
  oProjectDropdowns: Record<DropDownKey, DropDown[]>;
}

const oInitialState: GeneralState = {
  sStatus: 'IDLE',
  oDropdowns: {} as Record<DropDownKey, DropDown[]>,
  sExchangeRateFileStatus: 'IDLE',
  sProjectStatus: 'IDLE',
  oProjectDropdowns: {} as Record<DropDownKey, DropDown[]>,
};

const getDropdowns: AsyncThunk<
  Record<DropDownKey, DropDown[]>,
  {
    oData: DropDownKeyMultiple;
    oType: DropDownDataType;
    oProjectData?: ProjectModelInfo | ProjectModelInfo[];
    iProjectId?: number;
  },
  {}
> = createAsyncThunk(
  'getDropdowns',
  async ({
    oData,
    oType,
    oProjectData,
    iProjectId,
  }: {
    oData: DropDownKeyMultiple;
    oType: DropDownDataType;
    oProjectData?: ProjectModelInfo | ProjectModelInfo[];
    iProjectId?: number;
  }) => {
    const response = await GeneralService.getDropdowns(oData, oType, oProjectData, iProjectId);
    return response;
  },
);

const getProjectDropdowns: AsyncThunk<
  Record<DropDownKey, DropDown[]>,
  {
    oData: DropDownKeyMultiple;
    oType: DropDownDataType;
    oProjectData?: ProjectModelInfo;
    iProjectId?: number;
    iCheckpointId?: number;
  },
  {}
> = createAsyncThunk(
  'getProjectDropdowns',
  async ({
    oData,
    oType,
    oProjectData,
    iProjectId,
    iCheckpointId,
  }: {
    oData: DropDownKeyMultiple;
    oType: DropDownDataType;
    oProjectData?: ProjectModelInfo;
    iProjectId?: number;
    iCheckpointId?: number;
  }) => {
    let response: Record<DropDownKey, DropDown[]> = {} as Record<DropDownKey, DropDown[]>;
    if (oType === DropDownDataType.ANCLIST) {
      response = await GeneralService.getANCListDropdowns(
        `${iProjectId};${iCheckpointId}`,
        oData.join(','),
      );
    } else {
      response = await GeneralService.getDropdowns(oData, oType, oProjectData, iProjectId);
    }

    return response;
  },
);

const uploadExchangeRate: AsyncThunk<any, UploadCurrencyRequest, {}> = createAsyncThunk(
  'uplaodCurrency',
  async (oData: UploadCurrencyRequest) => {
    const response = await GeneralService.uploadExchangeRate(oData);
    return response;
  },
);

const onStateChanging = (oState: GeneralState, oNewObj: Partial<GeneralState>) =>
  ({
    sStatus: oNewObj.sStatus ? oNewObj.sStatus : oState.sStatus,
    sProjectStatus: oNewObj.sProjectStatus ? oNewObj.sProjectStatus : oState.sProjectStatus,
    sExchangeRateFileStatus: oNewObj.sExchangeRateFileStatus || oState.sExchangeRateFileStatus,
    oDropdowns: oState.oDropdowns
      ? ({
          ...oState.oDropdowns,
          ...oNewObj.oDropdowns,
        } as Record<DropDownKey, DropDown[]>)
      : oNewObj.oDropdowns,
    oProjectDropdowns: oState.oProjectDropdowns
      ? ({
          ...oState.oProjectDropdowns,
          ...oNewObj.oProjectDropdowns,
        } as Record<DropDownKey, DropDown[]>)
      : oNewObj.oProjectDropdowns,
  } as GeneralState);

const onStart = (oState: GeneralState) => onStateChanging(oState, { sStatus: 'LOADING' });
const onProjectStart = (oState: GeneralState) =>
  onStateChanging(oState, { sProjectStatus: 'LOADING' });

const onError = (oState: GeneralState) => onStateChanging(oState, { sStatus: 'FAILED' });
const onProjectError = (oState: GeneralState) =>
  onStateChanging(oState, { sProjectStatus: 'FAILED' });

const onSuccessDropdowns = (oState: GeneralState, oDropdowns: Record<DropDownKey, DropDown[]>) =>
  onStateChanging(oState, { sStatus: 'SUCCEEDED', oDropdowns });

const onProjectSuccessDropdowns = (
  oState: GeneralState,
  oProjectDropdowns: Record<DropDownKey, DropDown[]>,
) => onStateChanging(oState, { sProjectStatus: 'SUCCEEDED', oProjectDropdowns });

const onStartExchangeRateFile = (oState: GeneralState) =>
  onStateChanging(oState, { sExchangeRateFileStatus: 'LOADING' });

const onSuccessExchangeRateFileUpload = (oState: GeneralState) =>
  onStateChanging(oState, {
    sExchangeRateFileStatus: 'SUCCEEDED',
  });

const onExchangeRateFileUploadError = (oState: GeneralState) =>
  onStateChanging(oState, { sExchangeRateFileStatus: 'FAILED' });

export const General = createSlice({
  name: 'generalContext',
  initialState: oInitialState,
  reducers: {
    clearGeneralData(state) {
      const oState = state;
      oState.sStatus = oInitialState.sStatus;
    },
    loadLocalDropDown(state, oDropdowns) {
      const oState = state;
      oState.sStatus = 'SUCCEEDED';
      oState.oDropdowns = oDropdowns.payload.oDropdowns;
    },
    clearCompareByDropdownData(state) {
      const oState = state;
      oState.sStatus = 'SUCCEEDED';
      oState.oDropdowns.ModuleArea = [];
      oState.oDropdowns.Module = [];
      oState.oDropdowns.Component = [];
    },
  },
  extraReducers: oBuilder => {
    oBuilder
      .addCase(getDropdowns.pending, onStart)
      .addCase(getDropdowns.fulfilled, (oState: GeneralState, oAction) =>
        onSuccessDropdowns(oState, oAction.payload),
      )
      .addCase(getDropdowns.rejected, onError)

      .addCase(getProjectDropdowns.pending, onProjectStart)
      .addCase(getProjectDropdowns.fulfilled, (oState: GeneralState, oAction) =>
        onProjectSuccessDropdowns(oState, oAction.payload),
      )
      .addCase(getProjectDropdowns.rejected, onProjectError)

      // upload overloaded BOM
      .addCase(uploadExchangeRate.pending, onStartExchangeRateFile)
      .addCase(uploadExchangeRate.fulfilled, (oState: GeneralState) =>
        onSuccessExchangeRateFileUpload(oState),
      )
      .addCase(uploadExchangeRate.rejected, onExchangeRateFileUploadError);
  },
});

export { getDropdowns, uploadExchangeRate, getProjectDropdowns };
export const { clearGeneralData, loadLocalDropDown, clearCompareByDropdownData } = General.actions;
export default General.reducer;
