import {
  CompareMultipleBOMModel,
  CompareMultipleRequest,
} from '../../models/bottomUp/compareMultipleModel';
import {
  CompareOneToOneBOMModel,
  CompareOneToOneRequest,
} from '../../models/bottomUp/compareOneToOneModel';
import API_URL from '../apiUtils';
import httpService from '../httpService';

class CompareService {
  static getCompareOneToOneBom = async (aData: CompareOneToOneRequest) =>
    httpService.getJson<CompareOneToOneBOMModel>(API_URL.PROJECT.MODEL.COMPARE_ONE_TO_ONE_BOM, [
      aData.sProjectId,
      aData.iSourceNextCheckPointId.toString(),
      aData.iSourceModelId.toString(),
      aData.iSourceVersionId.toString(),
      aData.iDestinationNextCheckPointId.toString(),
      aData.iDestinationModelId.toString(),
      aData.iDestinationVersionId.toString(),
    ]);

  static getCompareMultipleBom = async (aData: CompareMultipleRequest) =>
    httpService.sendJson<CompareMultipleBOMModel>(
      API_URL.PROJECT.MODEL.COMPARE_MULTIPLE_BOM,
      {
        sComparisonType: aData.sComparisonType,
        lstComparisonModel: aData.lstComparisonModel,
        lstModuleArea: aData.lstModuleArea,
        lstModule: aData.lstModule,
        lstComponent: aData.lstComponent,
      },
      [aData.sProjectId],
    );
}

export default CompareService;
