import HttpService, { API_METHOD } from '../httpService';
import API_URL from '../apiUtils';
import {
  SaveModelRequest,
  ProjectModelList,
  ProjectModel,
  ModelVersion,
  ConvertModelRequest,
  DeleteModelRequest,
  CompareVersionResponse,
  CompareVersionRequest,
} from '../../models/model';
import { GetVersionsRequest } from '../../models/model/getVersionsRequest';
import { UploadOverloadedBOMRequest } from '../../models/model/uploadOverloadedBOMRequest';
import { BOMForComparisonRequest } from '../../models/model/BOMForComparisonRequest';
import { ComparisonBOMModel } from '../../models/model/comparisonBOMModel';
import { GetModelViewBOMRequest, ModelViewBOMModel } from '../../models/modelView';
import { CostedBOMModel, GetCostedBomRequest } from '../../models/costedBom/costedBomModel';
import { CostedModuleBOMModel, GetCostedModuleBOMRequest } from '../../models/costedBom';
import { UploadBOMRequest } from '../../models/model/uploadBOMRequest';

class ModelService {
  static getModels = async (aData: string[]) =>
    HttpService.getJson<ProjectModelList>(API_URL.PROJECT.MODEL.LIST, aData);

  static saveModel = async (oData: SaveModelRequest) => {
    const lstVolumes = [...oData.lstVolumes];

    const volumes = lstVolumes
      .sort((prev, next) => prev.iYearNumber - next.iYearNumber)
      .map(volume => volume.iVolume)
      .join(', ');

    const oFormData = new FormData();
    oFormData.append('iBusinessAreaId', oData.iBusinessAreaId.toString());
    oFormData.append('iNextCheckPointId', oData.iNextCheckpointId.toString());
    oFormData.append('sComment', oData.sComment);
    oFormData.append('sFactory', oData.sFactory);
    oFormData.append('sModelName', oData.sModelName);
    oFormData.append('sProjectId', oData.iProjectId.toString());

    if (oData.sReferenceModelId && oData.sReferenceModelId !== '') {
      oFormData.append('iReferenceModelId', oData.sReferenceModelId);
    }

    oFormData.append('sSpecification', oData.sSpecification);
    oFormData.append('iModelId', oData.iModelId.toString());
    oFormData.append('lstVolumes', volumes);
    oFormData.append('bReference', oData.BReference ? 'TRUE' : 'FALSE');
    oFormData.append('oFile', oData.oFile?.originFileObj as Blob);

    return HttpService.sendFormData<ProjectModel>(API_URL.PROJECT.MODEL.SAVE, oFormData, [
      oData.iProjectId.toString(),
    ]);
  };

  static convertModel = async (oData: ConvertModelRequest) =>
    HttpService.sendJson<ProjectModel>(API_URL.PROJECT.MODEL.CONVERT, undefined, [
      oData.iProjectId.toString(),
      oData.iNextCheckpointId.toString(),
      oData.iModelId.toString(),
      oData.sModelName,
    ]);

  static getVersions = async (oData: GetVersionsRequest) =>
    HttpService.getJson<ModelVersion[]>(API_URL.PROJECT.MODEL.VERSION.LIST, [
      oData.sProjectId,
      oData.iModelId!.toString(),
      oData.iNextCheckpointId.toString(),
    ]);

  static getModelViewBOM = async (oData: GetModelViewBOMRequest) =>
    HttpService.getJson<ModelViewBOMModel>(API_URL.PROJECT.MODEL.VERSION.BOM_FOR_MODEL_VIEW, [
      oData.sProjectId,
      oData.iNextCheckpointId.toString(),
      oData.iModelId.toString(),
      oData.iVersionId.toString(),
      oData.sTypeVers,
    ]);

  static getCostedBOM = async (oData: GetCostedBomRequest) =>
    HttpService.getJson<CostedBOMModel>(API_URL.PROJECT.MODEL.COSTED_BOM, [
      oData.sProjectId,
      oData.iNextCheckpointId.toString(),
      oData.iModelId.toString(),
      oData.iVersionId.toString(),
      oData.sTypeVers,
    ]);

  static getCostedModuleBom = async (aData: GetCostedModuleBOMRequest) =>
    HttpService.getJson<CostedModuleBOMModel>(API_URL.PROJECT.MODEL.COSTED_MODULE_BOM, [
      aData.sProjectId,
      aData.iNextCheckpointId.toString(),
      aData.iModelId.toString(),
      aData.iVersionId.toString(),
      aData.sTypeVers,
    ]);

  static deleteModel = async (oData: DeleteModelRequest) =>
    HttpService.sendJson<boolean>(
      API_URL.PROJECT.MODEL.DELETE,
      undefined,
      [oData.sProjectId, oData.iNextCheckpointId.toString(), oData.iModelId.toString()],
      true,
      API_METHOD.DELETE,
    );

  static deleteVersion = async (oData: ModelVersion) =>
    HttpService.sendJson<boolean>(
      API_URL.PROJECT.MODEL.VERSION.DELETE,
      undefined,
      [
        oData.sProjectId,
        oData.iNextCheckPointId.toString(),
        oData.iModelId.toString(),
        oData.iVersionId.toString(),
        oData.sTypeVers,
      ],
      true,
      API_METHOD.DELETE,
    );

  static freezeVersion = async (oData: ModelVersion) =>
    HttpService.sendJson<boolean>(
      API_URL.PROJECT.MODEL.VERSION.FREEZE,
      undefined,
      [
        oData.sProjectId,
        oData.iNextCheckPointId.toString(),
        oData.iModelId.toString(),
        oData.iVersionId.toString(),
        oData.sTypeVers,
      ],
      true,
      API_METHOD.POST,
    );

  static editVersionComment = async (oData: ModelVersion) =>
    HttpService.sendJson<boolean>(API_URL.PROJECT.MODEL.VERSION.EDIT_COMMENT, undefined, [
      oData.sProjectId,
      oData.iNextCheckPointId.toString(),
      oData.iModelId.toString(),
      oData.iVersionId.toString(),
      oData.sTypeVers,
      oData.sNote,
    ]);

  static uploadOverloadedBOM = async (oData: UploadOverloadedBOMRequest) => {
    const oFormData = new FormData();
    oFormData.append('sProjectId', oData.iProjectId.toString());
    oFormData.append('sComment', oData.sComment);
    oFormData.append('lstModelIds', oData.lstModelIds.join(', '));
    oFormData.append('iNextCheckPointId', oData.iNextCheckpointId.toString());
    oFormData.append('oFile', oData.oFile?.originFileObj as Blob);

    return HttpService.sendFormData<any>(API_URL.PROJECT.MODEL.OVERLOADED_BOM, oFormData, [
      oData.iProjectId.toString(),
      oData.iNextCheckpointId.toString(),
    ]);
  };

  static uploadBOM = async (oData: UploadBOMRequest) => {
    const oFormData = new FormData();
    oFormData.append('sProjectId', oData.iProjectId.toString());
    oFormData.append('sComment', oData.sComment);
    oFormData.append('iModelId', oData.iModelId.toString());
    oFormData.append('iNextCheckPointId', oData.iNextCheckpointId.toString());
    oFormData.append('oFile', oData.oFile?.originFileObj as Blob);

    return HttpService.sendFormData<any>(API_URL.PROJECT.MODEL.MODEL_BOM, oFormData, [
      oData.iProjectId.toString(),
      oData.iNextCheckpointId.toString(),
      oData.iModelId.toString(),
    ]);
  };

  static retrieveBOMForComparison = async (oData: BOMForComparisonRequest) =>
    HttpService.getJson<ComparisonBOMModel>(API_URL.PROJECT.MODEL.BOM_FOR_COMPARISON, [
      oData.sProjectId,
      oData.iNextCheckpointId.toString(),
      oData.iModelId.toString(),
      oData.iVersionId.toString(),
      oData.sTypeVers,
    ]);

  static compareBOMVersion = async (oData: CompareVersionRequest) =>
    HttpService.sendJson<CompareVersionResponse[]>(
      API_URL.PROJECT.MODEL.VERSION.COMPARE,
      undefined,
      [
        oData.oSourceVersion.sProjectId,
        oData.oSourceVersion.iNextCheckPointId.toString(),
        oData.oDestinationVersion.iModelId.toString(),
        oData.oDestinationVersion.iVersionId.toString(),
        oData.oSourceVersion.iModelId.toString(),
        oData.oSourceVersion.iVersionId.toString(),
        oData.oDestinationVersion.sTypeVers,
        oData.oSourceVersion.sTypeVers,
      ],
    );
}

export default ModelService;
