import {
  UploadVolumeBOMRequest,
  VolumePostRequest,
  VolumeResponse,
  VolumesRequest,
} from '../../models/topDown/volumes';
import API_URL from '../apiUtils';
import httpService from '../httpService';

class VolumesService {
  static getVolumes = async (aData: VolumesRequest) =>
    httpService.getJson<VolumeResponse>(API_URL.PROJECT.MODEL.GET_VOLUMES, [
      aData.sProjectId,
      aData.sNextCheckpointId,
    ]);

  static updateVolumes = async (oData: VolumePostRequest) =>
    httpService.sendJson<{}>(API_URL.PROJECT.MODEL.UPDATE_VOLUMES, oData, [
      oData.sProjectId,
      String(oData.iNextCheckpointId),
    ]);

  static uploadVolumesBOM = async (oData: UploadVolumeBOMRequest) => {
    const oFormData = new FormData();
    oFormData.append('sProjectId', oData.iProjectId.toString());
    oFormData.append('sComment', oData.sComment);
    oFormData.append('iNextCheckPointId', oData.iNextCheckpointId.toString());
    oFormData.append('oFile', oData.oFile?.originFileObj as Blob);

    return httpService.sendFormData<any>(API_URL.PROJECT.MODEL.VOLUMES_BOM, oFormData, [
      oData.iProjectId.toString(),
      oData.iNextCheckpointId.toString(),
    ]);
  };
}

export default VolumesService;
