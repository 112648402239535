import { CostImplicationByModelColor } from './chartsColors';

// Convert any color string to an [r,g,b,a] array.
const colorValues = (color: string) => {
  const div = document.createElement('div');
  div.style.backgroundColor = color;
  document.body.appendChild(div);
  let rgba = getComputedStyle(div).getPropertyValue('background-color');
  div.remove();

  if (rgba.indexOf('rgba') === -1) {
    rgba += ',1'; // convert 'rgb(R,G,B)' to 'rgb(R,G,B)A' which looks awful but will pass the regxep below
  }

  return rgba.match(/[.\d]+/g)!.map(x => +x);
};

const getColorSteps = (colorStart: string, colorEnd: string, steps: number) => {
  const start = colorValues(colorStart);
  const end = colorValues(colorEnd);
  const opacityStep = (end[3] * 100 - start[3] * 100) / steps;
  const colors = [colorStart];
  let alpha = 0;
  let opacity = start[3] * 100;

  for (let index = 0; index < steps; index += 1) {
    alpha += 1.0 / steps;
    opacity += opacityStep;

    const color = [
      Math.round(end[0] * alpha + (1 - alpha) * start[0]),
      Math.round(end[1] * alpha + (1 - alpha) * start[1]),
      Math.round(end[2] * alpha + (1 - alpha) * start[2]),
    ];

    colors.push(
      opacity === 100
        ? `rgb(${color[0]},${color[1]},${color[2]})`
        : `rgba(${color[0]},${color[1]},${color[2]},${opacity / 100})`,
    );
  }
  return colors;
};

export const getColorsPalette = (iSteps: number, bColorsTendTowardsGreen: boolean): string[] => {
  // if the number of steps is 1 only two colors are needed and they are already defined, red and green,
  // if the steps are more than 1 we need three colors (red, yellow and green which are already defined)
  // and we need to calculate all the other colors in between

  if (iSteps === 1) {
    return getColorSteps(
      CostImplicationByModelColor.red,
      CostImplicationByModelColor.green,
      iSteps,
    );
  }

  let iStepsFromRedToYellow = 0;
  let iStepsFromYellowToGreen = 0;

  if (iSteps % 2 === 0) {
    // if the steps are even we have to subract 1 from the steps because it's already defined by the color in the middle which is yellow
    if (bColorsTendTowardsGreen) {
      iStepsFromRedToYellow = iSteps / 2 - 1;
      iStepsFromYellowToGreen = iSteps / 2;
    } else {
      iStepsFromRedToYellow = iSteps / 2;
      iStepsFromYellowToGreen = iSteps / 2 - 1;
    }
  } else {
    // if the steps are odd we have to equally divide the steps between the two arrays
    iStepsFromRedToYellow = Math.trunc(iSteps / 2);
    iStepsFromYellowToGreen = Math.trunc(iSteps / 2);
  }

  const aColorsFromRedToYellow = getColorSteps(
    CostImplicationByModelColor.red,
    CostImplicationByModelColor.yellow,
    iStepsFromRedToYellow,
  );
  const aColorsFromYellowToGreen = getColorSteps(
    CostImplicationByModelColor.yellow,
    CostImplicationByModelColor.green,
    iStepsFromYellowToGreen,
  );

  // yellow is present in both arrays but we need it just once so we're filtering the array to remove the duplicated color
  return aColorsFromRedToYellow
    .concat(aColorsFromYellowToGreen)
    .filter((item, pos, self) => self.indexOf(item) === pos);
};

export const getRandomColorByString = (sString: string) => {
  let iHash = 0;
  for (let iIndex = 0; iIndex < sString.length; iIndex += 1) {
    // eslint-disable-next-line no-bitwise
    iHash = sString.charCodeAt(iIndex) + ((iHash << 5) - iHash);
  }

  const iHex = iHash % 360;
  return `hsl(${iHex}, 70%, 45%)`;
};
