import {
  AncExchangeRateRequest,
  AncExchangeRateRequestModel,
  AncExchangeRateResponse,
  AncListBOMModel,
  AncListRequest,
  AncListRequestModel,
  AncListResponse,
  ConfidentLevelBOMModel,
  GetAncListRequest,
  UploadAncListRequest,
} from '../../models/ancList';
import API_URL from '../apiUtils';
import httpService from '../httpService';

class AncListService {
  static getAncList = async (aData: GetAncListRequest) =>
    httpService.getJson<AncListBOMModel>(API_URL.PROJECT.MODEL.ANCLIST_BOM, [
      aData.sProjectId,
      aData.iNextCheckpointId.toString(),
    ]);

  static getConidentLevel = async () =>
    httpService.getJson<ConfidentLevelBOMModel[]>(
      API_URL.PROJECT.MODEL.ANCLIST_CONFIDENCE_LEVEL,
      [],
    );

  static submitExchangeRate = async (oData: AncExchangeRateRequest) =>
    httpService.sendJson<AncExchangeRateResponse>(
      API_URL.PROJECT.MODEL.ANCLIST_SUBMIT_EXCHANGE_RATE,
      oData.items as AncExchangeRateRequestModel[],
      [oData.sProjectId],
    );

  static uploadAncList = async (oData: UploadAncListRequest) => {
    const oFormData = new FormData();
    oFormData.append('sProjectId', oData.projectId);
    oFormData.append('oFile', oData.oFile?.originFileObj as Blob);

    return httpService.sendFormData<any>(API_URL.PROJECT.MODEL.UPLOAD_ANC_LIST, oFormData, [
      oData.projectId,
    ]);
  };

  static submitAncList = async (oData: AncListRequest) =>
    httpService.sendJson<AncListResponse>(
      API_URL.PROJECT.MODEL.ANCLIST_UPDATE,
      oData.items as AncListRequestModel[],
      [oData.sProjectId],
    );
}

export default AncListService;
