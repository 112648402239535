import { DropDownKeyCommon, DropDownSelectable } from '../models/shared';

export const extractSelectedDropdowns = (
  oDropdowns: Record<DropDownKeyCommon, DropDownSelectable[]>,
): Partial<Record<DropDownKeyCommon, DropDownSelectable[]>> => {
  if (!oDropdowns) {
    return {} as Record<DropDownKeyCommon, DropDownSelectable[]>;
  }

  if (Object.keys(oDropdowns).length === 0) {
    return {} as Record<DropDownKeyCommon, DropDownSelectable[]>;
  }

  const aData: (Partial<Record<DropDownKeyCommon, DropDownSelectable[]>> | null)[] = Object.entries(
    oDropdowns,
  )
    .map(dropdown => {
      const aSelectedEl = dropdown[1]?.filter(item => item.bSelected === true);
      if (aSelectedEl && aSelectedEl.length) {
        return { [dropdown[0] as DropDownKeyCommon]: aSelectedEl.slice(0, 1) };
      }

      return null;
    })
    .filter(Boolean);

  if (aData.length === 0) {
    return {} as Record<DropDownKeyCommon, DropDownSelectable[]>;
  }

  const oFilteredDropdrowns: Partial<Record<DropDownKeyCommon, DropDownSelectable[]>> = {};

  Object.entries(oDropdowns).forEach(dropdown => {
    const [key, value] = dropdown;

    const aSelected = value.filter(item => item.bSelected === true);

    if (aSelected.length > 0) {
      // const item = aSelected[0];
      oFilteredDropdrowns[key as DropDownKeyCommon] = aSelected;
    }
  });

  return oFilteredDropdrowns;
};
