/* eslint-disable no-console */
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { EnhancedStore } from '@reduxjs/toolkit';
import { notification } from 'antd';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import saveAs from 'file-saver';
import { authRequest } from '../../config/auth';
import msalInstance from '../auth/azureAD';
import { formatContent } from '../utils';
import API_URL from './apiUtils';

export enum API_METHOD {
  POST = 'POST',
  GET = 'GET',
  DELETE = 'DELETE',
}

interface ResponseType<T> {
  sResponseCode: string;
  sMessage: string;
  oContent: T;
}

class HttpService {
  private oClient: AxiosInstance | null = null;

  private oStore: any | null = null;

  private static oInstance: HttpService;

  public static get instance() {
    if (HttpService.oInstance === undefined) {
      HttpService.oInstance = new HttpService();
    }

    return HttpService.oInstance;
  }

  constructor() {
    this.oClient = axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL,
      headers: {
        Accept: 'application/json, application/octet-stream',
      },
      timeout: 0,
    });

    this.oClient.interceptors.request.use(HttpService.onRequest, HttpService.onRequestError);
    this.oClient.interceptors.response.use(HttpService.onResponse, HttpService.onResponseError);
  }

  public injectStore = (oStore: EnhancedStore) => {
    this.oStore = oStore;
  };

  private static onRequest = async (config: AxiosRequestConfig<any>) => {
    console.log('REQ', config);
    return config;
  };

  private static onRequestError = (error: any) => {
    console.log(error);
    return Promise.reject(error);
  };

  private static onResponse = (response: AxiosResponse<any, any>) => {
    console.log('RES', response);
    return response;
  };

  private static onResponseError = (error: any) => {
    console.log(error);
    return Promise.reject(error);
  };

  private requestHandler = async <T>(oConfig: AxiosRequestConfig) => {
    try {
      const { data } = await this.oClient!.request<ResponseType<T>>(oConfig);

      switch (data.sResponseCode) {
        case '200': {
          return data.oContent;
        }
        case '500':
        case '400':
        case '404': {
          if (!oConfig.url?.includes(API_URL.AUTHENTICATION.AUTHORIZE)) {
            notification.error({ message: data.sMessage });
          }
          break;
        }
        case '401': {
          await Promise.resolve(msalInstance.acquireTokenRedirect(authRequest));
          break;
        }

        default:
          break;
      }

      return await Promise.reject();
    } catch (error: any) {
      notification.error({ message: error.message });
      return await Promise.reject(error);
    }
  };

  public sendJson = async <T>(
    sPath: string,
    oData: any,
    asParams: string[] = [],
    bProtected: boolean = true,
    sMethod: API_METHOD = API_METHOD.POST,
  ): Promise<T> => {
    const oConfig: AxiosRequestConfig<any> = {
      method: sMethod,
      url: formatContent(sPath, asParams),
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
    };

    if (oData) {
      oConfig.data = oData;
    }

    if (bProtected) {
      oConfig.headers = {
        Authorization: await this.getToken(),
        'Content-Type': 'application/json;charset=UTF-8',
      };
    }

    return this.requestHandler<T>(oConfig);
  };

  public getJson = async <T>(
    sPath: string,
    asParams: string[] = [],
    bProtected: boolean = true,
  ): Promise<T> => {
    const oConfig: AxiosRequestConfig<any> = {
      method: API_METHOD.GET,
      url: formatContent(sPath, asParams),
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
    };

    if (bProtected) {
      oConfig.headers = {
        Authorization: await this.getToken(),
        'Content-Type': 'application/json;charset=UTF-8',
      };
    }

    return this.requestHandler<T>(oConfig);
  };

  public sendFormData = async <T>(
    sPath: string,
    oFormData: FormData,
    asParams: string[] = [],
    bProtected: boolean = true,
  ): Promise<T> => {
    const oConfig: AxiosRequestConfig<FormData> = {
      method: API_METHOD.POST,
      url: formatContent(sPath, asParams),
      data: oFormData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    if (bProtected) {
      oConfig.headers = {
        Authorization: await this.getToken(),
        'Content-Type': 'multipart/form-data',
      };
    }

    return this.requestHandler<T>(oConfig);
  };

  public downloadFile = async (
    sPath: string,
    asParams: string[] = [],
    oData: any = undefined,
    sMethod: API_METHOD = API_METHOD.GET,
    bProtected: boolean = true,
  ) => {
    const oConfig: AxiosRequestConfig<FormData> = {
      method: sMethod,
      url: formatContent(sPath, asParams),
      responseType: 'blob',
    };

    if (oData) {
      oConfig.data = oData;
    }

    if (bProtected) {
      oConfig.headers = {
        Authorization: await this.getToken(),
      };
    }

    try {
      const oResponse = await this.oClient!.request(oConfig);
      const sFilename = oResponse.headers['content-disposition']
        .replace(/"/g, '')
        .split(';')
        .find(sPart => sPart.includes('filename='))
        ?.replace('filename=', '')
        .trim();

      saveAs(oResponse.data, sFilename);
    } catch (exc: any) {
      notification.error(exc);
    }
  };

  private getToken = async () => {
    const token = await this.refreshAccessToken();
    return token ? `Bearer ${token}` : '';
  };

  // eslint-disable-next-line class-methods-use-this
  private refreshAccessToken = async () => {
    const account = msalInstance.getAllAccounts()[0];
    console.log('CONFIG', msalInstance.getConfiguration());
    if (!account) {
      return msalInstance.acquireTokenRedirect(authRequest);
    }

    try {
      const token = await msalInstance.acquireTokenSilent({
        scopes: authRequest.scopes,
        account,
      });
      return token.accessToken;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        return await Promise.resolve(msalInstance.acquireTokenRedirect(authRequest));
      }
      return await Promise.reject(error);
    }
  };
}

export default HttpService.instance;
