import { useEffect } from 'react';
import { getWorkflowDetail } from '../../store/project';
import { useAppDispatch, useAppSelector } from '../store/customHooks';
import { RootState } from '../../store';
import { ProjectCheckpointsModel } from '../../models/projectOverview';

export type ProjectCheckpointData = {
  readonly oProjectCheckpoints: ProjectCheckpointsModel | null;
  readonly bCheckpointLoading: boolean;
  readonly bHasError: boolean;
};

export function useProjectCheckpoints(sProjectId: string): ProjectCheckpointData {
  const dispatch = useAppDispatch();
  const { oProjectCheckpoints, sStatus } = useAppSelector(
    (state: RootState) => state.projectContext,
  );

  useEffect(() => {
    if (sProjectId) dispatch(getWorkflowDetail(sProjectId));
  }, [sProjectId]);

  if (sStatus === 'LOADING') {
    return { oProjectCheckpoints, bHasError: false, bCheckpointLoading: true };
  }

  if (sStatus === 'FAILED') {
    return { oProjectCheckpoints, bHasError: true, bCheckpointLoading: false };
  }

  if (sStatus === 'SUCCEEDED') {
    return { oProjectCheckpoints, bHasError: false, bCheckpointLoading: false };
  }

  return { oProjectCheckpoints, bHasError: false, bCheckpointLoading: true };
}
