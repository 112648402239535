import {
  ModuleAreaModel,
  ModuleAreaTargetSettingsDetailModel,
  ModuleAreaTargetSettingsRequest,
  TargetSettingModel,
  TargetSettingResponse,
  TargetSettingsDetailModel,
  TargetSettingsRequest,
  TargetSubmitSettingsRequest,
} from '../../models/targetSettings';
import API_URL from '../apiUtils';
import httpService from '../httpService';

class TargetSettingService {
  static getTargetSettings = async (oData: string[]) =>
    httpService.getJson<TargetSettingsDetailModel>(
      API_URL.PROJECT.MODEL.LOAD_TARGET_SETTINGS,
      oData,
    );

  static getModuleAreaTargetSettings = async (oData: string[]) =>
    httpService.getJson<ModuleAreaTargetSettingsDetailModel>(
      API_URL.PROJECT.MODEL.MODULE_AREA_TARGET_SETTINGS,
      oData,
    );

  static submitTargetSetting = async (oData: TargetSubmitSettingsRequest) =>
    httpService.sendJson<TargetSettingResponse>(
      API_URL.PROJECT.MODEL.SUBMIT_TARGET_SETTINGS,
      oData as TargetSubmitSettingsRequest,
      [oData.sProjectId.toString(), oData.iNextCheckpointId.toString()],
    );

  static saveTargetSetting = async (oData: TargetSettingsRequest) => {
    const oFormData = new FormData();
    // alert(oData.iNextCheckpointId.toString());
    oFormData.append('iNextCheckpointId', oData.iNextCheckpointId.toString());
    oFormData.append('iProjectId', oData.iNextCheckpointId.toString());

    Object.keys(oData).forEach(key => {
      if (key === 'lstModels') {
        const obj: TargetSettingModel[] = oData.lstModels;
        oFormData.append(key, JSON.stringify(obj));
      }
    });

    return httpService.sendJson<TargetSettingsDetailModel>(
      API_URL.PROJECT.MODEL.SAVE_TARGET_SETTINGS,
      oData as TargetSettingsRequest,
      [oData.sProjectId.toString(), oData.iNextCheckpointId.toString()],
    );
  };

  static saveModuleAreaTargetSetting = async (oData: ModuleAreaTargetSettingsRequest) => {
    const oFormData = new FormData();
    // alert(oData.iNextCheckpointId.toString());
    oFormData.append('iNextCheckpointId', oData.iNextCheckpointId.toString());
    oFormData.append('iProjectId', oData.iNextCheckpointId.toString());

    Object.keys(oData).forEach(key => {
      if (key === 'lstModulesArea') {
        const obj: ModuleAreaModel[] = oData.lstModulesArea;
        oFormData.append(key, JSON.stringify(obj));
      }
    });

    return httpService.sendJson<ModuleAreaTargetSettingsDetailModel>(
      API_URL.PROJECT.MODEL.SAVE_MODULE_AREA_TARGET_SETTINGS,
      oData as ModuleAreaTargetSettingsRequest,
      [oData.sProjectId.toString(), oData.iNextCheckpointId.toString()],
    );
  };
}

export default TargetSettingService;
