import { AsyncThunk, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GetOverloadedBOMRequest, OverloadedBOMModel } from '../../models/bottomUp';
import OverloadedService from '../../services/bottomUp/overloadedService';
import { AppStatus } from '../generalTypes';

export interface OverloadedBomContextState {
  sOverloadedBomStatus: AppStatus;
  oOverloadedBomObj: OverloadedBOMModel;
}

const oInitialState: OverloadedBomContextState = {
  sOverloadedBomStatus: 'IDLE',
  oOverloadedBomObj: {} as OverloadedBOMModel,
};

const onStateChanging = (
  oState: OverloadedBomContextState,
  oNewObj: Partial<OverloadedBomContextState>,
) => ({ ...oState, ...oNewObj } as OverloadedBomContextState);

const onSuccessOverloadedBOM = (
  oState: OverloadedBomContextState,
  oOverloadedBomObj: OverloadedBOMModel,
) =>
  onStateChanging(oState, {
    sOverloadedBomStatus: 'SUCCEEDED',
    oOverloadedBomObj,
  });

const onStartOverloadedBOM = (oState: OverloadedBomContextState) =>
  onStateChanging(oState, { sOverloadedBomStatus: 'LOADING' });
const onErrorOverloadedBOM = (oState: OverloadedBomContextState) =>
  onStateChanging(oState, { sOverloadedBomStatus: 'FAILED' });

const getOverloadedBOM: AsyncThunk<OverloadedBOMModel, GetOverloadedBOMRequest, {}> =
  createAsyncThunk('getModelViewBOM', async (oData: GetOverloadedBOMRequest) => {
    const response = await OverloadedService.getOverloadedBom(oData);
    return response;
  });

export const OverloadedBomContext = createSlice({
  name: 'overloadedBomContext',
  initialState: oInitialState,
  reducers: {},
  extraReducers: oBuilder => {
    oBuilder
      // get bom for model view
      .addCase(getOverloadedBOM.pending, onStartOverloadedBOM)
      .addCase(getOverloadedBOM.fulfilled, (oState: OverloadedBomContextState, oAction) =>
        onSuccessOverloadedBOM(oState, oAction.payload),
      )
      .addCase(getOverloadedBOM.rejected, onErrorOverloadedBOM);
  },
});

export { getOverloadedBOM };
export default OverloadedBomContext.reducer;
