import { AsyncThunk, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AppStatus } from '../generalTypes';
import { ModelService } from '../../services/model';
import {
  ProjectModelList,
  ProjectModel,
  SaveModelRequest,
  ModelVersion,
  ConvertModelRequest,
  DeleteModelRequest,
  CompareVersionResponse,
  CompareVersionRequest,
} from '../../models/model';
import { GetVersionsRequest } from '../../models/model/getVersionsRequest';
import { UploadOverloadedBOMRequest } from '../../models/model/uploadOverloadedBOMRequest';
import { ComparisonBOMModel } from '../../models/model/comparisonBOMModel';
import { BOMForComparisonRequest } from '../../models/model/BOMForComparisonRequest';
import { UploadBOMRequest } from '../../models/model/uploadBOMRequest';

export interface ModelContextState {
  sModelStatus: AppStatus;
  aModels: ProjectModel[];
  iTotalCount: number;
  aModelVersions: ModelVersion[];
  sVersionStatus: AppStatus;
  oComparisonBOM: ComparisonBOMModel | null;
  oCompareResult: CompareVersionResponse[];
  sCompareStatus: AppStatus;
}

interface VersionObj {
  bDone: boolean;
  oModelVersion: ModelVersion;
}

interface ModelObj {
  bDone: boolean;
  oModel: DeleteModelRequest;
}

const oInitialState: ModelContextState = {
  sModelStatus: 'IDLE',
  iTotalCount: 0,
  aModels: [],
  aModelVersions: [],
  sVersionStatus: 'IDLE',
  oComparisonBOM: null,
  oCompareResult: [],
  sCompareStatus: 'IDLE',
};

const getModels: AsyncThunk<ProjectModelList, string[], {}> = createAsyncThunk(
  'getModels',
  async (aData: string[]) => {
    const response = await ModelService.getModels(aData);
    return response;
  },
);

const saveModel: AsyncThunk<ProjectModel, SaveModelRequest, {}> = createAsyncThunk(
  'saveModel',
  async (oData: SaveModelRequest) => {
    const response = await ModelService.saveModel(oData);
    return response;
  },
);

const getVersions: AsyncThunk<ModelVersion[], GetVersionsRequest, {}> = createAsyncThunk(
  'getVersions',
  async (oData: GetVersionsRequest) => {
    const response = await ModelService.getVersions(oData);
    return response;
  },
);

const convertModel: AsyncThunk<ProjectModel, ConvertModelRequest, {}> = createAsyncThunk(
  'convertModel',
  async (oData: ConvertModelRequest) => {
    const response = await ModelService.convertModel(oData);
    return response;
  },
);

const deleteModel: AsyncThunk<ModelObj, DeleteModelRequest, {}> = createAsyncThunk(
  'deleteModel',
  async (oData: DeleteModelRequest) => {
    const response = await ModelService.deleteModel(oData);
    return { bDone: response, oModel: oData };
  },
);

const deleteModelVersion: AsyncThunk<VersionObj, ModelVersion, {}> = createAsyncThunk(
  'deleteModelVersion',
  async (oData: ModelVersion) => {
    const response = await ModelService.deleteVersion(oData);
    return { bDone: response, oModelVersion: oData };
  },
);

const freezeModelVersion: AsyncThunk<VersionObj, ModelVersion, {}> = createAsyncThunk(
  'freezeModelVersion',
  async (oData: ModelVersion) => {
    const response = await ModelService.freezeVersion(oData);
    return { bDone: response, oModelVersion: oData };
  },
);

const editVersionComment: AsyncThunk<VersionObj, ModelVersion, {}> = createAsyncThunk(
  'editVersionComment',
  async (oData: ModelVersion) => {
    const response = await ModelService.editVersionComment(oData);
    return { bDone: response, oModelVersion: oData };
  },
);

const uploadOverloadedBOM: AsyncThunk<any, UploadOverloadedBOMRequest, {}> = createAsyncThunk(
  'uplaodOverloadedBOM',
  async (oData: UploadOverloadedBOMRequest) => {
    const response = await ModelService.uploadOverloadedBOM(oData);
    return response;
  },
);

const uploadBOM: AsyncThunk<any, UploadBOMRequest, {}> = createAsyncThunk(
  'uploadBOM',
  async (oData: UploadBOMRequest) => {
    const response = await ModelService.uploadBOM(oData);
    return response;
  },
);

const retrieveBOMForComparison: AsyncThunk<ComparisonBOMModel, BOMForComparisonRequest, {}> =
  createAsyncThunk('retrieveBOMForComparison', async (oData: BOMForComparisonRequest) => {
    const response = await ModelService.retrieveBOMForComparison(oData);
    return response;
  });

const compareBOMVersion: AsyncThunk<CompareVersionResponse[], CompareVersionRequest, {}> =
  createAsyncThunk('compareBomVersion', async (oData: CompareVersionRequest) => {
    const response = await ModelService.compareBOMVersion(oData);
    return response;
  });

const onStateChanging = (oState: ModelContextState, oNewObj: Partial<ModelContextState>) =>
  ({ ...oState, ...oNewObj } as ModelContextState);

const onStartModel = (oState: ModelContextState) =>
  onStateChanging(oState, { sModelStatus: 'LOADING' });

const onErrorModel = (oState: ModelContextState) =>
  onStateChanging(oState, { sModelStatus: 'FAILED' });

const onStartVersion = (oState: ModelContextState) =>
  onStateChanging(oState, { sVersionStatus: 'LOADING' });

const onErrorVersion = (oState: ModelContextState) =>
  onStateChanging(oState, { sVersionStatus: 'FAILED' });

const onStartCompare = (oState: ModelContextState) =>
  onStateChanging(oState, { sCompareStatus: 'LOADING' });

const onErrorCompare = (oState: ModelContextState) =>
  onStateChanging(oState, { sCompareStatus: 'FAILED' });

const onSuccess = (oState: ModelContextState, aProjectModels: ProjectModelList) =>
  onStateChanging(oState, {
    sModelStatus: 'SUCCEEDED',
    iTotalCount: aProjectModels.iTotalCount,
    aModels: aProjectModels.lstModels,
  });

const getListModels = (oState: ModelContextState, oProjectModels: ProjectModel) => {
  if (oState.aModels.some(oModel => oModel.iModelId === oProjectModels.iModelId)) {
    const lstModels = [...oState.aModels];
    const iIndex = oState.aModels.findIndex(oModel => oModel.iModelId === oProjectModels.iModelId);

    lstModels[iIndex] = oProjectModels;
    return lstModels;
  }

  return [...oState.aModels, oProjectModels];
};

const onSuccessSave = (oState: ModelContextState, oProjectModels: ProjectModel) =>
  onStateChanging(oState, {
    sModelStatus: 'SUCCEEDED',
    iTotalCount: oState.aModels.some(oModel => oModel.iModelId === oProjectModels.iModelId)
      ? oState.iTotalCount
      : oState.iTotalCount + 1,
    aModels: getListModels(oState, oProjectModels),
  });

const onSuccessVersions = (oState: ModelContextState, aModelVersions: ModelVersion[]) =>
  onStateChanging(oState, {
    sVersionStatus: 'SUCCEEDED',
    aModelVersions,
  });

const removeDeletedVersion = (oState: ModelContextState, oObj: VersionObj) => {
  const lstVersion = [...oState.aModelVersions];

  if (oObj.bDone) {
    const iIndex = lstVersion.findIndex(
      oVersion => oVersion.iVersionId === oObj.oModelVersion.iVersionId,
    );
    lstVersion.splice(iIndex, 1);
  }

  return lstVersion;
};

const onSuccessDeleteVersion = (oState: ModelContextState, oObj: VersionObj) =>
  onStateChanging(oState, {
    sVersionStatus: 'SUCCEEDED',
    aModelVersions: removeDeletedVersion(oState, oObj),
  });

const updateFreezedVersion = (oState: ModelContextState, oObj: VersionObj) => {
  const lstVersion = [...oState.aModelVersions];

  return lstVersion.map(item => {
    const tItem = { ...item };
    if (item.iVersionId === oObj.oModelVersion.iVersionId) {
      tItem.bIsFreeze = true;
    } else {
      tItem.bIsFreeze = false;
    }
    return tItem;
  });
};

const updateFrozenVersionInModel = (oState: ModelContextState, oObj: VersionObj) => {
  const lstModels = [...oState.aModels];

  return lstModels.map(item => {
    const tItem = { ...item };
    if (item.iModelId === oObj.oModelVersion.iModelId) {
      tItem.iFreezeVersionId = oObj.oModelVersion.iVersionId;
    }
    return tItem;
  });
};

const onSuccessFreezeVersion = (oState: ModelContextState, oObj: VersionObj) =>
  onStateChanging(oState, {
    sVersionStatus: 'SUCCEEDED',
    aModelVersions: updateFreezedVersion(oState, oObj),
    aModels: updateFrozenVersionInModel(oState, oObj),
  });

const removeDeletedModel = (oState: ModelContextState, oObj: ModelObj) => {
  const lstModel = [...oState.aModels];

  if (oObj.bDone) {
    const iIndex = lstModel.findIndex(oModel => oModel.iModelId === oObj.oModel.iModelId);
    lstModel.splice(iIndex, 1);
  }

  return lstModel;
};

const onSuccessDeleteModel = (oState: ModelContextState, oObj: ModelObj) =>
  onStateChanging(oState, {
    sModelStatus: 'SUCCEEDED',
    aModels: removeDeletedModel(oState, oObj),
  });

const getListVersions = (oState: ModelContextState, oObj: VersionObj) => {
  if (oObj.bDone) {
    const lstVersions = [...oState.aModelVersions];
    const iIndex = oState.aModelVersions.findIndex(
      oItem => oItem.iVersionId === oObj.oModelVersion.iVersionId,
    );

    lstVersions[iIndex] = oObj.oModelVersion;
    return lstVersions;
  }

  return [...oState.aModelVersions];
};

const onSuccessVersionSave = (oState: ModelContextState, oObj: VersionObj) =>
  onStateChanging(oState, {
    sVersionStatus: 'SUCCEEDED',
    iTotalCount: oState.aModelVersions.some(
      oVersion => oVersion.iVersionId === oObj.oModelVersion.iVersionId,
    )
      ? oState.iTotalCount
      : oState.iTotalCount + 1,
    aModelVersions: getListVersions(oState, oObj),
  });

const onSuccessUpload = (oState: ModelContextState) =>
  onStateChanging(oState, {
    sVersionStatus: 'SUCCEEDED',
  });

const onComparisonSuccess = (oState: ModelContextState, oComparisonBOM: ComparisonBOMModel) =>
  onStateChanging(oState, { sCompareStatus: 'SUCCEEDED', oComparisonBOM });

const onCompareSuccess = (oState: ModelContextState, oCompareResult: CompareVersionResponse[]) =>
  onStateChanging(oState, { sCompareStatus: 'SUCCEEDED', oCompareResult });

export const ModelContext = createSlice({
  name: 'modelContext',
  initialState: oInitialState,
  reducers: {
    resetModelData(state) {
      Object.assign(state, oInitialState);
    },
    clearModelList(state) {
      const oState = state;
      oState.aModels = [];
    },
  },
  extraReducers: oBuilder => {
    oBuilder
      // project models section
      .addCase(getModels.pending, onStartModel)
      .addCase(getModels.fulfilled, (oState: ModelContextState, oAction) =>
        onSuccess(oState, oAction.payload),
      )
      .addCase(getModels.rejected, onErrorModel)

      // create model section
      .addCase(saveModel.pending, onStartModel)
      .addCase(saveModel.fulfilled, (oState: ModelContextState, oAction) =>
        onSuccessSave(oState, oAction.payload),
      )
      .addCase(saveModel.rejected, onErrorModel)

      // get versions section
      .addCase(getVersions.pending, onStartVersion)
      .addCase(getVersions.fulfilled, (oState: ModelContextState, oAction) =>
        onSuccessVersions(oState, oAction.payload),
      )
      .addCase(getVersions.rejected, onErrorVersion)

      // convert model section
      .addCase(convertModel.pending, onStartModel)
      .addCase(convertModel.fulfilled, (oState: ModelContextState, oAction) =>
        onSuccessSave(oState, oAction.payload),
      )
      .addCase(convertModel.rejected, onErrorModel)

      // delete model
      .addCase(deleteModel.pending, onStartModel)
      .addCase(deleteModel.fulfilled, (oState: ModelContextState, oAction) =>
        onSuccessDeleteModel(oState, oAction.payload),
      )
      .addCase(deleteModel.rejected, onErrorModel)

      // delete model version
      .addCase(deleteModelVersion.pending, onStartVersion)
      .addCase(deleteModelVersion.fulfilled, (oState: ModelContextState, oAction) =>
        onSuccessDeleteVersion(oState, oAction.payload),
      )
      .addCase(deleteModelVersion.rejected, onErrorVersion)

      // freeze model version
      .addCase(freezeModelVersion.pending, onStartVersion)
      .addCase(freezeModelVersion.fulfilled, (oState: ModelContextState, oAction) =>
        onSuccessFreezeVersion(oState, oAction.payload),
      )
      .addCase(freezeModelVersion.rejected, onErrorVersion)

      // edit model version
      .addCase(editVersionComment.pending, onStartVersion)
      .addCase(editVersionComment.fulfilled, (oState: ModelContextState, oAction) =>
        onSuccessVersionSave(oState, oAction.payload),
      )
      .addCase(editVersionComment.rejected, onErrorVersion)

      // upload overloaded BOM
      .addCase(uploadOverloadedBOM.pending, onStartVersion)
      .addCase(uploadOverloadedBOM.fulfilled, (oState: ModelContextState) =>
        onSuccessUpload(oState),
      )
      .addCase(uploadOverloadedBOM.rejected, onErrorVersion)

      // upload BOM
      .addCase(uploadBOM.pending, onStartVersion)
      .addCase(uploadBOM.fulfilled, (oState: ModelContextState) => onSuccessUpload(oState))
      .addCase(uploadBOM.rejected, onErrorVersion)

      // retrieve BOM for comparison
      .addCase(retrieveBOMForComparison.pending, onStartCompare)
      .addCase(retrieveBOMForComparison.fulfilled, (oState: ModelContextState, oAction) =>
        onComparisonSuccess(oState, oAction.payload),
      )
      .addCase(retrieveBOMForComparison.rejected, onErrorCompare)

      // compare versions
      .addCase(compareBOMVersion.pending, onStartCompare)
      .addCase(compareBOMVersion.fulfilled, (oState: ModelContextState, oAction) =>
        onCompareSuccess(oState, oAction.payload),
      )
      .addCase(compareBOMVersion.rejected, onErrorCompare);
  },
});
export const { resetModelData, clearModelList } = ModelContext.actions;
export {
  getModels,
  saveModel,
  getVersions,
  convertModel,
  deleteModelVersion,
  editVersionComment,
  uploadOverloadedBOM,
  uploadBOM,
  retrieveBOMForComparison,
  deleteModel,
  compareBOMVersion,
  freezeModelVersion,
};
export default ModelContext.reducer;
