enum TableLegendCondition {
  Above5 = 0,
  From5To2,
  From2To0,
  From0To5,
  Under5,
  STK1,
  CTF,
  CTFPER,
}

type TableColor = {
  [key: number]: string;
};

export const TableColors: TableColor = {
  [TableLegendCondition.Above5]: '#7A8A9C',
  [TableLegendCondition.From5To2]: '#ACB9C1',
  [TableLegendCondition.From2To0]: '#787386',
  [TableLegendCondition.From0To5]: '#ffe58f',
  [TableLegendCondition.Under5]: '#937259',
  [TableLegendCondition.STK1]: '#F4F6F7',
  [TableLegendCondition.CTF]: '#DDE8EE',
  [TableLegendCondition.CTFPER]: '#DDE8EE',
};

export const GeneralChartColors = {
  green: '#7A8A9C',
  lightGreen: '#ACB9C1',
  red: '#937259',
  lightRed: '#ffe58f',
  yellow: '#787386',
  lightGrey: '#F7F7F7',
  grey: '#ECECEC',
  darkGrey: '#E0E0E0',
  black: '#000',
  blue: '#4D87B1',
  lightBlue: '#B7C8E1',
  darkBlue: 'rgba(59, 89, 134, .7)',
  darkRed: '#f94f00',
  lightPorcelain: '#F4F6F7',
  catskillWhite: '#DDE8EE',
  lightLime: '#E7DCC9',
  white: '#ffffff',
  greenSheen: '#7ABA9C',
  crayola: '#ACB9C1',
  liverChestnut: '#937259',
  tan: '#D0B993',
  shadow: '#7A8A9C',
  rhythm: '#787386',
};

export const lineChartColors = [
  GeneralChartColors.rhythm,
  GeneralChartColors.crayola,
  GeneralChartColors.tan,
];

export const CostImplicationByModelColor = {
  red: '#937259',
  yellow: 'rgb(120,115,134)',
  green: '#7A8A9C',
};

export const levelColors: string[] = ['#073068', '#d5e6fd', '#e0e7ea', 'yellow'];

export const pieChartColors: string[] = [
  GeneralChartColors.liverChestnut,
  GeneralChartColors.crayola,
  GeneralChartColors.tan,
  GeneralChartColors.shadow,
  GeneralChartColors.rhythm,
  GeneralChartColors.greenSheen,
  '#F4F6F7',
];
