import { UserContextData } from '../../models/login';
import API_URL from '../apiUtils';

import HttpService from '../httpService';

class LoginService {
  static authorize = async () =>
    HttpService.getJson<UserContextData>(API_URL.AUTHENTICATION.AUTHORIZE);

  static getPermissions = async () =>
    HttpService.getJson<string[]>(API_URL.AUTHENTICATION.PERMISSION);
}

export default LoginService;
