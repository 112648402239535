import { useEffect } from 'react';
import { UserContextData } from '../../models/login';
import { RootState } from '../../store';
import { getPermissions } from '../../store/login';
import { UserPermissions } from '../../utils';
import { useAppDispatch, useAppSelector } from '../store/customHooks';

type UserContextDataType = {
  readonly oUserContext: UserContextData | null;
  readonly bHasError: boolean;
};

export function useUserContextData(): UserContextDataType {
  const { oUserContext } = useAppSelector((state: RootState) => state.authContext);
  return { oUserContext, bHasError: !!oUserContext };
}

export function usePermissionChecker(lstPermission: UserPermissions[]): boolean {
  const { asPermission, oUserContext } = useAppSelector((state: RootState) => state.authContext);
  const dispatch = useAppDispatch();

  if (!oUserContext) {
    return false;
  }

  if (lstPermission.length === 1 && lstPermission[0] === UserPermissions.APPLICATION_ACCESS) {
    return true;
  }

  useEffect(() => {
    dispatch(getPermissions());
  }, []);

  return lstPermission.some(oPermission => asPermission.includes(oPermission));
}
