import { AsyncThunk, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AppStatus } from '../generalTypes';
import { ModelViewBOMModel, GetModelViewBOMRequest } from '../../models/modelView';
import { ModelService } from '../../services/model';

export interface ModelViewContextState {
  sModelViewStatus: AppStatus;
  aModelViewModels: ModelViewBOMModel;
}

const oInitialState: ModelViewContextState = {
  sModelViewStatus: 'IDLE',
  aModelViewModels: {} as ModelViewBOMModel,
};

const onStateChanging = (oState: ModelViewContextState, oNewObj: Partial<ModelViewContextState>) =>
  ({ ...oState, ...oNewObj } as ModelViewContextState);

const onSuccessModelViewBOM = (
  oState: ModelViewContextState,
  aModelViewModels: ModelViewBOMModel,
) =>
  onStateChanging(oState, {
    sModelViewStatus: 'SUCCEEDED',
    aModelViewModels,
  });

const onStartModelViewBOM = (oState: ModelViewContextState) =>
  onStateChanging(oState, { sModelViewStatus: 'LOADING' });
const onErrorModelViewBOM = (oState: ModelViewContextState) =>
  onStateChanging(oState, { sModelViewStatus: 'FAILED' });

const getModelViewBOM: AsyncThunk<ModelViewBOMModel, GetModelViewBOMRequest, {}> = createAsyncThunk(
  'getModelViewBOM',
  async (oData: GetModelViewBOMRequest) => {
    const response = await ModelService.getModelViewBOM(oData);
    return response;
  },
);

export const ModelViewContext = createSlice({
  name: 'modelViewContext',
  initialState: oInitialState,
  reducers: {
    resetModelViewData(state) {
      Object.assign(state, oInitialState);
    },
  },
  extraReducers: oBuilder => {
    oBuilder
      // get bom for model view
      .addCase(getModelViewBOM.pending, onStartModelViewBOM)
      .addCase(getModelViewBOM.fulfilled, (oState: ModelViewContextState, oAction) =>
        onSuccessModelViewBOM(oState, oAction.payload),
      )
      .addCase(getModelViewBOM.rejected, onErrorModelViewBOM);
  },
});

export { getModelViewBOM };
export const { resetModelViewData } = ModelViewContext.actions;
export default ModelViewContext.reducer;
