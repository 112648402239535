import { AsyncThunk, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  CompareMultipleBOMModel,
  CompareMultipleRequest,
} from '../../models/bottomUp/compareMultipleModel';
import CompareService from '../../services/bottomUp/compareService';
import { AppStatus } from '../generalTypes';

export interface CompareMultipleContextState {
  sCompareMultipleStatus: AppStatus;
  oCompareMultipleObj: CompareMultipleBOMModel;
}

const oInitialState: CompareMultipleContextState = {
  sCompareMultipleStatus: 'IDLE',
  oCompareMultipleObj: {} as CompareMultipleBOMModel,
};

const onStateChanging = (
  oState: CompareMultipleContextState,
  oNewObj: Partial<CompareMultipleContextState>,
) => ({ ...oState, ...oNewObj } as CompareMultipleContextState);

const onSuccessCompareOneToOneBOM = (
  oState: CompareMultipleContextState,
  oCompareMultipleObj: CompareMultipleBOMModel,
) =>
  onStateChanging(oState, {
    sCompareMultipleStatus: 'SUCCEEDED',
    oCompareMultipleObj,
  });

const onStartCompareMultipleBOM = (oState: CompareMultipleContextState) =>
  onStateChanging(oState, { sCompareMultipleStatus: 'LOADING' });
const onErrorCompareMultipleBOM = (oState: CompareMultipleContextState) =>
  onStateChanging(oState, { sCompareMultipleStatus: 'FAILED' });

const getCompareMultipleBOM: AsyncThunk<CompareMultipleBOMModel, CompareMultipleRequest, {}> =
  createAsyncThunk('getCompareMultipleBOM', async (oData: CompareMultipleRequest) => {
    const response = await CompareService.getCompareMultipleBom(oData);
    return response;
  });

export const CompareMultipleContext = createSlice({
  name: 'compareMultipleContext',
  initialState: oInitialState,
  reducers: {
    clearCompareMutipleData: () => oInitialState,
  },
  extraReducers: oBuilder => {
    oBuilder
      // get bom for model view
      .addCase(getCompareMultipleBOM.pending, onStartCompareMultipleBOM)
      .addCase(getCompareMultipleBOM.fulfilled, (oState: CompareMultipleContextState, oAction) =>
        onSuccessCompareOneToOneBOM(oState, oAction.payload),
      )
      .addCase(getCompareMultipleBOM.rejected, onErrorCompareMultipleBOM);
  },
});

export { getCompareMultipleBOM };
export const { clearCompareMutipleData } = CompareMultipleContext.actions;
export default CompareMultipleContext.reducer;
