import { AsyncThunk, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TopDownVsBottomUpRequest } from '../../models/projectOverview';
import { HighLevelMatchingModel } from '../../models/projectOverview/highLevelMatchingModel';
import AnalysisService from '../../services/analysis/analysisService';
import { AppStatus } from '../generalTypes';

export interface HighLevelMatchingContextState {
  sHighLevelMatchingStatus: AppStatus;
  aHighLevelMatchingList: HighLevelMatchingModel[];
}

const oInitialState: HighLevelMatchingContextState = {
  sHighLevelMatchingStatus: 'IDLE',
  aHighLevelMatchingList: [] as HighLevelMatchingModel[],
};

const onStateChanging = (
  oState: HighLevelMatchingContextState,
  oNewObj: Partial<HighLevelMatchingContextState>,
) => ({ ...oState, ...oNewObj } as HighLevelMatchingContextState);

const onSuccessOverloadedBOM = (
  oState: HighLevelMatchingContextState,
  aHighLevelMatchingList: HighLevelMatchingModel[],
) =>
  onStateChanging(oState, {
    sHighLevelMatchingStatus: 'SUCCEEDED',
    aHighLevelMatchingList,
  });

const onStartHighLevelMatching = (oState: HighLevelMatchingContextState) =>
  onStateChanging(oState, { sHighLevelMatchingStatus: 'LOADING' });
const onErrorHighLevelMatching = (oState: HighLevelMatchingContextState) =>
  onStateChanging(oState, { sHighLevelMatchingStatus: 'FAILED' });

const getHighLevelMatching: AsyncThunk<HighLevelMatchingModel[], TopDownVsBottomUpRequest, {}> =
  createAsyncThunk('getHighLevelMatchingList', async (oData: TopDownVsBottomUpRequest) => {
    const response = await AnalysisService.getHighLevelMatchingList(oData);
    return response;
  });

export const HighLevelMatchingContext = createSlice({
  name: 'highLevelMatchingContext',
  initialState: oInitialState,
  reducers: {
    clearHighLevelMatchingData: () => oInitialState,
  },
  extraReducers: oBuilder => {
    oBuilder
      // get bom for model view
      .addCase(getHighLevelMatching.pending, onStartHighLevelMatching)
      .addCase(getHighLevelMatching.fulfilled, (oState: HighLevelMatchingContextState, oAction) =>
        onSuccessOverloadedBOM(oState, oAction.payload),
      )
      .addCase(getHighLevelMatching.rejected, onErrorHighLevelMatching);
  },
});

export { getHighLevelMatching };
export const { clearHighLevelMatchingData } = HighLevelMatchingContext.actions;
export default HighLevelMatchingContext.reducer;
