/* eslint-disable no-plusplus */
import { UploadCurrencyRequest } from '../../models/currency';
import { BOMStructure, ModuleAreaLevel } from '../../models/model/comparisonBOMModel';
import {
  DropDown,
  ProjectModelInfo,
  DropDownDataType,
  DropDownKey,
  DropDownKeyMultiple,
} from '../../models/shared';
import API_URL from '../apiUtils';
import httpService from '../httpService';

class GeneralService {
  static getANCListDropdowns = async (sPrjKey: string, _lstKeys: string) => {
    const url = API_URL.GENERAL.FILTERS_PROJECT_BY_ANC_LIST;
    return httpService.getJson<Record<DropDownKey, DropDown[]>>(url, [_lstKeys, sPrjKey]);
  };

  static getDropdowns = async (
    aData: DropDownKeyMultiple,
    oType: DropDownDataType,
    oProjectData?: ProjectModelInfo | ProjectModelInfo[],
    iProjectId?: number,
  ) => {
    let url = API_URL.GENERAL.FILTERS_COMMON;

    if (oType === DropDownDataType.PROJECT) {
      url = API_URL.GENERAL.FILTERS_PROJECT;
    } else if (oType === DropDownDataType.MODEL) {
      url = API_URL.GENERAL.FILTERS_MODEL;
    } else if (oType === DropDownDataType.MULTI_MODEL) {
      url = API_URL.GENERAL.FILTERS_MULTI_MODEL;
    }

    if (oProjectData) {
      const projectModelString: string[] = [];
      if (Array.isArray(oProjectData)) {
        oProjectData.forEach(obj => {
          const singleModelString: string[] = [];
          Object.keys(obj).forEach((key: string) => {
            singleModelString.push(String(obj[key as keyof ProjectModelInfo]));
          });
          projectModelString.push(singleModelString.join(';'));
        });

        return httpService.getJson<Record<DropDownKey, DropDown[]>>(url, [
          aData.join(),
          projectModelString.join(),
        ]);
      }

      Object.keys(oProjectData).forEach((key: string) => {
        projectModelString.push(String(oProjectData[key as keyof ProjectModelInfo]));
      });

      return httpService.getJson<Record<DropDownKey, DropDown[]>>(url, [
        aData.join(),
        projectModelString.join(';'),
      ]);
    }

    if (iProjectId) {
      return httpService.getJson<Record<DropDownKey, DropDown[]>>(url, [
        aData.join(),
        iProjectId.toString(),
      ]);
    }

    return httpService.getJson<Record<DropDownKey, DropDown[]>>(url, [aData.join()]);
  };

  static convertArrayForTreeTable = (aBOMArray: BOMStructure[]) => {
    const sortedArray = [...aBOMArray];
    sortedArray.sort(
      (prev, next) =>
        prev.sModuleArea?.localeCompare(next.sModuleArea) ||
        prev.sModule?.localeCompare(next.sModule) ||
        prev.sComponent?.localeCompare(next.sComponent) ||
        prev.sANC?.localeCompare(next.sANC),
    );

    const finalArray: ModuleAreaLevel[] = [];
    let added = false;

    for (let int = 0; int < sortedArray.length; int++) {
      const bomItem = sortedArray[int];

      for (let x = 0; x < finalArray.length; x++) {
        const moduleArea = finalArray[x];

        if (bomItem.sModuleArea === moduleArea.sModuleArea) {
          for (let index = 0; index < moduleArea.children.length; index++) {
            const modules = moduleArea.children[index];

            if (bomItem.sModule === modules.sModule) {
              for (let y = 0; y < modules.children.length; y++) {
                const component = modules.children[y];

                if (bomItem.sComponent === component.sComponent) {
                  component.children.push({
                    key: `${component.key}_${component.children.length + 1}`,
                    iBomRow: bomItem.iBomRow,
                    sANC: bomItem.sANC,
                    sAncDescription: bomItem.sAncDescription,
                    sSupplier: bomItem.sSupplier,
                    iVersionId: bomItem.iVersionId,
                    iQuantity: bomItem.iQuantity,
                    iLevel: bomItem.iLevel,
                  });
                  added = true;
                }
              }

              if (!added) {
                modules.children.push({
                  key: bomItem.sModuleArea + bomItem.sModule + bomItem.sComponent,
                  sComponent: bomItem.sComponent,
                  children: [
                    {
                      key: `${bomItem.sModuleArea}${bomItem.sModule}${bomItem.sComponent}_1`,
                      iBomRow: bomItem.iBomRow,
                      sANC: bomItem.sANC,
                      sAncDescription: bomItem.sAncDescription,
                      sSupplier: bomItem.sSupplier,
                      iVersionId: bomItem.iVersionId,
                      iQuantity: bomItem.iQuantity,
                      iLevel: bomItem.iLevel,
                    },
                  ],
                });

                added = true;
              }
              break;
            }
          }

          if (!added) {
            moduleArea.children.push({
              key: bomItem.sModuleArea + bomItem.sModule,
              sModule: bomItem.sModule,
              children: [
                {
                  key: bomItem.sModuleArea + bomItem.sModule + bomItem.sComponent,
                  sComponent: bomItem.sComponent,
                  children: [
                    {
                      key: `${bomItem.sModuleArea}${bomItem.sModule}${bomItem.sComponent}_1`,
                      iBomRow: bomItem.iBomRow,
                      sANC: bomItem.sANC,
                      sAncDescription: bomItem.sAncDescription,
                      sSupplier: bomItem.sSupplier,
                      iVersionId: bomItem.iVersionId,
                      iQuantity: bomItem.iQuantity,
                      iLevel: bomItem.iLevel,
                    },
                  ],
                },
              ],
            });

            added = true;
          }
          break;
        }
      }

      if (!added) {
        finalArray.push({
          key: bomItem.sModuleArea,
          sModuleArea: bomItem.sModuleArea,
          children: [
            {
              key: bomItem.sModuleArea + bomItem.sModule,
              sModule: bomItem.sModule,
              children: [
                {
                  key: bomItem.sModuleArea + bomItem.sModule + bomItem.sComponent,
                  sComponent: bomItem.sComponent,
                  children: [
                    {
                      key: `${bomItem.sModuleArea}${bomItem.sModule}${bomItem.sComponent}_1`,
                      iBomRow: bomItem.iBomRow,
                      sANC: bomItem.sANC,
                      sAncDescription: bomItem.sAncDescription,
                      sSupplier: bomItem.sSupplier,
                      iVersionId: bomItem.iVersionId,
                      iQuantity: bomItem.iQuantity,
                      iLevel: bomItem.iLevel,
                    },
                  ],
                },
              ],
            },
          ],
        });
      }

      added = false;
    }

    return finalArray;
  };

  static uploadExchangeRate = async (oData: UploadCurrencyRequest) => {
    const oFormData = new FormData();
    oFormData.append('iYear', oData.iYear.toString());
    oFormData.append('oFile', oData.oFile?.originFileObj as Blob);

    return httpService.sendFormData<any>(API_URL.SETTINGS.UPLOAD_EXCHANGE_RATE_FILE, oFormData, []);
  };
}

export default GeneralService;
