import { AsyncThunk, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AppStatus } from '../generalTypes';
import { ModelService } from '../../services/model';
import { CostedBOMModel, GetCostedBomRequest } from '../../models/costedBom/costedBomModel';

export interface CostedBomContextState {
  sCostedBomStatus: AppStatus;
  aCostedBomModels: CostedBOMModel;
}

const oInitialState: CostedBomContextState = {
  sCostedBomStatus: 'IDLE',
  aCostedBomModels: {} as CostedBOMModel,
};

const onStateChanging = (oState: CostedBomContextState, oNewObj: Partial<CostedBomContextState>) =>
  ({ ...oState, ...oNewObj } as CostedBomContextState);

const onSuccessCostedBOM = (oState: CostedBomContextState, aCostedBomModels: CostedBOMModel) =>
  onStateChanging(oState, {
    sCostedBomStatus: 'SUCCEEDED',
    aCostedBomModels,
  });

const onStartCostedBOM = (oState: CostedBomContextState) =>
  onStateChanging(oState, { sCostedBomStatus: 'LOADING' });
const onErrorCostedBOM = (oState: CostedBomContextState) =>
  onStateChanging(oState, { sCostedBomStatus: 'FAILED' });

const getCostedBOM: AsyncThunk<CostedBOMModel, GetCostedBomRequest, {}> = createAsyncThunk(
  'getCostedBOM',
  async (oData: GetCostedBomRequest) => {
    const response = await ModelService.getCostedBOM(oData);
    return response;
  },
);

export const CostedBomContext = createSlice({
  name: 'costedBomContext',
  initialState: oInitialState,
  reducers: {
    resetCostedBomData(state) {
      Object.assign(state, oInitialState);
    },
  },
  extraReducers: oBuilder => {
    oBuilder
      // get bom for costed bom
      .addCase(getCostedBOM.pending, onStartCostedBOM)
      .addCase(getCostedBOM.fulfilled, (oState: CostedBomContextState, oAction) =>
        onSuccessCostedBOM(oState, oAction.payload),
      )
      .addCase(getCostedBOM.rejected, onErrorCostedBOM);
  },
});

export { getCostedBOM };
export const { resetCostedBomData } = CostedBomContext.actions;
export default CostedBomContext.reducer;
