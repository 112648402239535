import {
  GetSafetyMarginListRequest,
  SafetyMarginModelObj,
  SafetyMarginRequest,
  SafetyMarginResponse,
  SafetyMarginSubmitModel,
} from '../../models/bottomUp/safetyMarginModel';
import API_URL from '../apiUtils';
import httpService from '../httpService';

class SafetyMarginService {
  static getSafetyMargin = async (aData: GetSafetyMarginListRequest) =>
    httpService.getJson<SafetyMarginModelObj>(API_URL.PROJECT.MODEL.SAFETY_MARGIN, [
      aData.sProjectId,
      aData.iNextCheckpointId.toString(),
    ]);

  static submitSafetyMargin = async (oData: SafetyMarginRequest) =>
    httpService.sendJson<SafetyMarginResponse>(
      API_URL.PROJECT.MODEL.SUBMIT_SAFETY_MARGIN,
      oData.items as SafetyMarginSubmitModel,
      [oData.sProjectID],
    );
}

export default SafetyMarginService;
