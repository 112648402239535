/* eslint-disable no-plusplus */
/* eslint no-param-reassign: "error" */

import {
  BOMStructure,
  ModuleAreaLevel,
  OverloadedBomTableList,
  OverloadedBomModuleAreaTableSingleObj,
} from '../../models/bottomUp/overloadedBOMModel';
import { makeid } from '../../utils';

class OverloadedBomGeneralService {
  static convertArrayForTreeTable = (aBOMArray: BOMStructure[]) => {
    const sortedArray = [...aBOMArray];
    const filterList: OverloadedBomTableList = {
      iLevel: [],
      sModuleArea: [],
      sModule: [],
      sGenericComp: [],
      sAnc: [],
      sAncDescription: [],
    };

    const finalArray: ModuleAreaLevel[] = [];

    const getOtherFieldsData = (obj1: any, obj2: any) => {
      // const lObj: any = { ...obj1 };
      const rObj: any = { ...obj2 };

      Object.entries(rObj).forEach(entry => {
        const [key] = entry;

        if (
          key !== 'key' &&
          key !== 'children' &&
          key !== 'sModuleArea' &&
          key !== 'sModule' &&
          key !== 'sGenericComp'
        ) {
          obj1[key as keyof OverloadedBomModuleAreaTableSingleObj] =
            obj2[key as keyof OverloadedBomModuleAreaTableSingleObj];
        }
      });
      // return lObj;
    };

    for (let int = 0; int < sortedArray.length; int++) {
      const lItem = sortedArray[int];
      const bomItem: OverloadedBomModuleAreaTableSingleObj =
        {} as OverloadedBomModuleAreaTableSingleObj;
      bomItem.sModuleArea = lItem.sModuleArea;
      bomItem.sModule = lItem.sModule || '';
      bomItem.sGenericComp = lItem.sGenericComp;
      bomItem.iLevel = lItem.iLevel;
      bomItem.sAnc = lItem.sAnc;
      bomItem.sAncDescription = lItem.sAncDescription;

      // prepare other columns
      if (lItem?.lstModelQuantity)
        lItem.lstModelQuantity.forEach(litem => {
          bomItem[litem.sModelName as keyof ModuleAreaLevel] = litem.iQuantity;
        });

      Object.keys(filterList).forEach(key => {
        if (bomItem[key as keyof BOMStructure] || bomItem[key as keyof BOMStructure] === 0) {
          filterList[key as keyof OverloadedBomTableList].push({
            text: String(bomItem[key as keyof BOMStructure]),
            value: String(bomItem[key as keyof BOMStructure]),
            column: key,
          });
        }
      });

      if (lItem?.lstModelQuantity)
        lItem.lstModelQuantity.forEach(item => {
          if (!filterList[item.sModelName as keyof OverloadedBomTableList]) {
            filterList[item.sModelName as keyof OverloadedBomTableList] = [];
          }
          filterList[item.sModelName as keyof OverloadedBomTableList].push({
            text: String(item.iQuantity),
            value: String(item.iQuantity),
            column: item.sModelName,
          });
        });

      const obj: ModuleAreaLevel = {
        key: `${bomItem.sModuleArea}-${makeid(4)}`,
        sModuleArea: bomItem.sModuleArea,
        sModule: bomItem.sModule,
        sGenericComp: bomItem.sGenericComp,
        iLevel: bomItem.iLevel,
        sAncDescription: bomItem.sAncDescription,
        sAnc: bomItem.sAnc,
        children: undefined,
      };

      getOtherFieldsData(obj, bomItem);

      finalArray.push(obj);
    }
    return { finalArray, filterList };
  };
}

export default OverloadedBomGeneralService;
