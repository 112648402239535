const API_URL = {
  AUTHENTICATION: {
    AUTHORIZE: '/api/v1/user/profile',
    LOGOUT: '/api/v1/user/logoff',
    REFRESH_TOKEN: '/api/v1/user/refreshToken',
    PERMISSION: '/api/v1/user/permissions',
  },
  PROJECT: {
    REFRESH_UPDATE_TIME: '/api/v1/projects/{0}/update-project-condition',
    LIST: '/api/v1/projects',
    EDITABLE_LIST: '/api/v1/editable-projects',
    CHECKPOINTS: '/api/v1/projects/{0}/checkpoints',
    DETAIL: '/api/v1/projects/{0}',
    HIGH_LEVEL_MATCHING:
      '/api/v1/projects/{0}/checkpoints/{4}/cost-bridge-table?iYear={1}&lstModelIds={2}&sCurrencyCode={3}',
    MODEL: {
      LIST: '/api/v1/projects/{0}/models?_iNextCheckpoint={1}',
      SAVE: '/api/v1/projects/{0}/model/managemodel',
      CONVERT:
        '/api/v1/projects/{0}/checkpoints/{1}/models/{2}/convertToTrackModel?_sTrackModelName={3}',
      DELETE: '/api/v1/projects/{0}/checkpoints/{1}/models/{2}',
      VERSION: {
        LIST: '/api/v1/projects/{0}/models/{1}/versions?_iNextCheckPointId={2}',
        DOWNLOAD_BOM:
          '/api/v1/projects/{0}/checkpoints/{1}/models/{2}/versions/{3}/{4}/downloadBomVersion',
        EDIT_COMMENT:
          '/api/v1/projects/{0}/checkpoints/{1}/models/{2}/versions/{3}/{4}/updateComments?sNote={5}',
        DELETE: '/api/v1/projects/{0}/checkpoints/{1}/models/{2}/versions/{3}/{4}',
        FREEZE: '/api/v1/projects/{0}/checkpoints/{1}/models/{2}/versions/{3}/{4}/freeze',
        COMPARE:
          '/api/v1/projects/{0}/checkpoints/{1}/models/compareBoms?iDestinationModelId={2}&iDestinationVersionId={3}&iSourceModelId={4}&iSourceVersionId={5}&sDestinationTypeVersion={6}&sSourceTypeVersion={7}',
        BOM_FOR_MODEL_VIEW:
          '/api/v1/projects/{0}/checkpoints/{1}/models/{2}/versions/{3}/{4}/bom-for-modelview',
      },
      GET_VOLUMES: '/api/v1/projects/{0}/checkpoints/{1}/list-volumes',
      UPDATE_VOLUMES: '/api/v1/projects/{0}/checkpoints/{1}/list-volumes',
      DOWNLOAD_OVERLOADED_BOM:
        '/api/v1/projects/{0}/checkpoints/{1}/models/download-overloaded-bom?lstModelIds={2}',
      DOWNLOAD_VOLUMES: '/api/v1/projects/{0}/checkpoints/{1}/export-volumes',
      VOLUMES_BOM: '/api/v1/projects/{0}/checkpoints/{1}/import-volumes',
      OVERLOADED_BOM: '/api/v1/projects/{0}/checkpoints/{1}/models/overloaded-bom',
      OVERLOADED_BOM_V2:
        '/api/v2/projects/{0}/checkpoints/{1}/models/overloaded-bom?lstModelIds={2}',
      MODEL_BOM: '/api/v1/projects/{0}/checkpoints/{1}/models/{2}/versions',
      COMPARE_ONE_TO_ONE_BOM:
        '/api/v1/projects/{0}/compare-one-one?iSourceNextCheckPointId={1}&iSourceModelId={2}&iSourceVersionId={3}&iDestinationNextCheckPointId={4}&iDestinationModelId={5}&iDestinationVersionId={6}',
      COMPARE_MULTIPLE_BOM: '/api/v1/projects/{0}/compare-side-side',
      SAFETY_MARGIN: '/api/v1/projects/{0}/safety-margin?iNextCheckpointId={1}',
      SUBMIT_SAFETY_MARGIN: '/api/v1/projects/{0}/safety-margin',
      UPLOAD_ANC_LIST: '/api/v1/projects/{0}/update-ancs-from-excel',
      COSTED_BOM: '/api/v1/projects/{0}/checkpoints/{1}/models/{2}/versions/{3}/{4}/costed-bom',
      EXPORT_COSTED_BOM:
        '/api/v1/projects/{0}/checkpoints/{1}/models/{2}/versions/{3}/{4}/export-costed-bom',
      COSTED_MODULE_BOM:
        '/api/v1/projects/{0}/checkpoints/{1}/models/{2}/versions/{3}/{4}/costed-module-bom',
      ANCLIST_BOM: '/api/v1/projects/{0}/checkpoints/{1}/anc-list',
      ANCLIST_CONFIDENCE_LEVEL: '/api/v1/projects/confidence-levels',
      ANCLIST_SUBMIT_EXCHANGE_RATE: '/api/v1/projects/{0}/anc-exchange-rates',
      ANCLIST_UPDATE: '/api/v1/projects/{0}/anc-list',
      EXPORT_ANC_LIST: '/api/v1/projects/{0}/checkpoints/{1}/export-anc-list',
      EXPORT_ANC_LIST_HISTORY: '/api/v1/projects/{0}/export-anc-list-history',
      BOM_FOR_COMPARISON:
        '/api/v1/projects/{0}/checkpoints/{1}/models/{2}/versions/{3}/{4}/bom-for-comparison',
      LOAD_TARGET_SETTINGS: '/api/v1/projects/{0}/checkpoints/{1}/track-model-target-setting/load',
      MODULE_AREA_TARGET_SETTINGS: '/api/v1/projects/{0}/checkpoints/{1}/module-target-settings',
      SAVE_TARGET_SETTINGS: '/api/v1/projects/{0}/checkpoints/{1}/track-model-target-setting/save',
      SAVE_MODULE_AREA_TARGET_SETTINGS:
        '/api/v1/projects/{0}/checkpoints/{1}/module-target-setting/save',
      SUBMIT_TARGET_SETTINGS: '/api/v1/projects/{0}/checkpoints/{1}/target-settings/submit',
    },
    DEVIATIONS_MODULE_AREA:
      '/api/v1/projects/{0}/checkpoints/{1}/largest-deviations-by-module-area?sCurrencyCode={2}',
    STK1_DEVIATION_BY_MODEL:
      '/api/v1/projects/{0}/checkpoints/{1}/stk1-deviation-by-model?sCurrencyCode={2}',
    MODULE_AREA_COST_IMPLICATION:
      '/api/v1/projects/{0}/checkpoints/{1}/models/{2}/module-area-cost-implication?sCurrencyCode={3}',
    STK1_DEVIATION_BY_MODULE_AREA:
      '/api/v1/projects/{0}/checkpoints/{1}/models/{2}/stk1-deviation-by-module-area?sCurrencyCode={3}',
    YEARLY_VOLUME:
      '/api/v1/projects/{0}/checkpoints/{4}/yearly-volume?projectId={0}&iYear={1}&lstModelIds={2}&sCurrencyCode={3}',
    COST_IMPLICATION_BY_MODEL:
      '/api/v1/projects/{0}/checkpoints/{4}/total-cost-implication-by-track-model?projectId={0}&iYear={1}&lstModelIds={2}&sCurrencyCode={3}',
    MODULE_SUMMARY_MODEL:
      '/api/v1/projects/{0}/checkpoints/{1}/models/{2}/module-summary?sCurrencyCode={3}',
  },
  GENERAL: {
    FILTERS_COMMON: '/api/v1/project/filters-without-key?_lstKeys={0}',
    FILTERS_PROJECT: '/api/v1/project/filters-by-project?_lstKeys={0}&_sPrjKey={1}',
    FILTERS_PROJECT_BY_ANC_LIST:
      '/api/v1/project/filters-by-project-and-nextcheckpoint?_lstKeys={0}&_sPrjKey={1}',
    FILTERS_MODEL: '/api/v1/project/filters-by-model-version?_lstKeys={0}&_sPrjKey={1}',
    FILTERS_MULTI_MODEL: '/api/v1/project/filters-by-model-versions?_lstKeys={0}&_lstPrjKeys={1}',
  },
  DOWNLOAD: {
    BOM_TEMPLATE: '/api/v1/templates/bom',
  },
  EXPORT: {
    EXCEL: '/api/v1/projects/{0}/checkpoints/{1}/export-excel',
    POWER_POINT: '/api/v1/projects/{0}/checkpoints/{1}/export-power-point',
    COMPARE_ONE_TO_ONE_EXCEL:
      '/api/v1/projects/{0}/export-compare-one-one?iDestinationModelId={1}&iDestinationNextCheckPointId={2}&iDestinationVersionId={3}&iSourceModelId={4}&iSourceNextCheckPointId={5}&iSourceVersionId={6}',
    COMPARE_MULTIPLE_EXCEL: '/api/v2/projects/{0}/export-compare-side-side',
  },
  USER: {
    PROJECT: {
      PARTICIPANTS: '/api/v1/projects/{0}/participants',
      EDIT_PARTECIPANT: '/api/v1/projects/{0}/participants/{1}',
    },
    SEARCH_USER: '/api/v1/searchUsers?sProjectId={0}&sSearchString={1}',
    LIST: '/api/v1/users',
  },
  ANALYSIS: {
    MODULE_BREAKDOWN:
      '/api/v1/projects/{0}/checkpoints/{1}/models/{2}/module-breakdown?sCurrencyCode={3}',
    COST_BRIDGE:
      '/api/v1/projects/{0}/checkpoints/{1}/cost-bridge?projectId={0}&iYear={2}&lstModelIds={3}&sCurrencyCode={4}',
    MODULE_AREA_COST_IMPLICATION:
      '/api/v1/projects/{0}/checkpoints/{1}/models/{2}/module-area-cost-implication?sCurrencyCode={3}',
    MODEL_COST_IMPLICATION:
      '/api/v1/projects/{0}/checkpoints/{4}/model-cost-implication?projectId={0}&iYear={1}&lstModelIds={2}&sCurrencyCode={3}',
    DISCREPANCY_MODULE_AREA:
      '/api/v1/projects/{0}/checkpoints/{4}/discrepancy-by-module-area?lstModelIds={2}&sCurrencyCode={3}',
    DISCREPANCY_MODEL:
      '/api/v1/projects/{0}/checkpoints/{4}/discrepancy-with-volumes?projectId={0}&iYear={1}&lstModelIds={2}&sCurrencyCode={3}',
    DISCREPANCY_MODEL_WITHOUT_VOLUME:
      '/api/v1/projects/{0}/checkpoints/{4}/discrepancy-without-volumes?projectId={0}&lstModelIds={2}&sCurrencyCode={3}',
    OVERALL_COST:
      '/api/v1/projects/{0}/checkpoints/{4}/overall-cost-implication?projectId={0}&iYear={1}&lstModelIds={2}&sCurrencyCode={3}',
    EVOLUTION_CHART:
      '/api/v1/projects/{0}/checkpoints/{4}/dm-dl-FOV-evolution?projectId={0}&iYear={1}&lstModelIds={2}&sCurrencyCode={3}&sType={5}',
    STK1_EVOLUTION_CHART:
      '/api/v1/projects/{0}/checkpoints/{4}/stk1-evolution?projectId={0}&iYear={1}&lstModelIds={2}&sCurrencyCode={3}',
    SAFETY_MARGIN_EVOLUTION_CHART:
      '/api/v1/projects/{0}/checkpoints/{4}/safety-margin-evolution?projectId={0}&iYear={1}&lstModelIds={2}&sCurrencyCode={3}',
    Confident_Level_EVOLUTION_CHART:
      '/api/v1/projects/{0}/checkpoints/{4}/confident-level-evolution?projectId={0}&iYear={1}&lstModelIds={2}&sCurrencyCode={3}',
    EXCHANGE_RATE_EVOLUTION_CHART:
      '/api/v1/projects/{0}/checkpoints/{4}/exchange-rate-evolution?projectId={0}&iYear={1}&lstModelIds={2}&sCurrencyCode={3}',
    Product_Level_Cost_OVerview:
      '/api/v1/projects/{0}/checkpoints/{3}/cost-overview-product-level?projectId={0}&lstModelIds={1}&sCurrencyCode={2}',
    MODULE_AREA_COST_OVERVIEW:
      '/api/v1/projects/{0}/checkpoints/{3}/cost-overview-module-area?projectId={0}&lstModelIds={1}&sCurrencyCode={2}',
    Idco_Cost_OVerview:
      '/api/v1/projects/{0}/checkpoints/{3}/cost-overview-idco?projectId={0}&lstModelIds={1}&sCurrencyCode={2}',
    FUNCTION_COST_OVERVIEW:
      '/api/v1/projects/{0}/checkpoints/{3}/cost-overview-function?projectId={0}&lstModelIds={1}&sCurrencyCode={2}',
    CLUSTER_COST_OVERVIEW:
      '/api/v1/projects/{0}/checkpoints/{3}/cost-overview-cluster?projectId={0}&lstModelIds={1}&sCurrencyCode={2}',
    COMPARE_WITH_TARGET:
      '/api/v1/projects/{0}/checkpoints/{4}/compare-target?iYear={1}&lstModelIds={2}&sCurrencyCode={3}',
    COMPARE_BETWEEN_CPS:
      '/api/v1/projects/{0}/checkpoints/{4}/compare-checkpoints-spend-gap?iYear={1}&lstModelIds={2}&sCurrencyCode={3}&iLeftCheckpointId={5}&iRightCheckpointId={6}',
    COMPARE_BETWEEN_CPS_UNIT_COST:
      '/api/v1/projects/{0}/checkpoints/{4}/compare-checkpoints-unit-cost-gap?iYear={1}&lstModelIds={2}&sCurrencyCode={3}&iLeftCheckpointId={5}&iRightCheckpointId={6}',
    COMPARE_BETWEEN_CPS_STK_BRIDGE:
      '/api/v1/projects/{0}/checkpoints/{4}/compare-checkpoints-stk1-bridge?iYear={1}&lstModelIds={2}&sCurrencyCode={3}&iLeftCheckpointId={5}&iRightCheckpointId={6}',
    COMPARE_WITH_PREDECESSOR:
      '/api/v1/projects/{0}/checkpoints/{4}/compare-predecessor?iYear={1}&lstModelIds={2}&sCurrencyCode={3}&sComparisonType={5}',
    COMPARE_BETWEEN_CPS_MULTI:
      '/api/v1/projects/{0}/checkpoints/{4}/compare-checkpoints?iYear={1}&lstModelIds={2}&sCurrencyCode={3}&iLeftCheckpointId={5}&iRightCheckpointId={6}&sComparisonType={7}',
    COST_MATURITY:
      '/api/v1/projects/{0}/checkpoints/{4}/cost-maturity-sourcing?iYear={1}&lstModelIds={2}&sCurrencyCode={3}',
    COST_MATURITY_INDICATOR:
      '/api/v1/projects/{0}/checkpoints/{4}/cost-maturity-process-indicators?iYear={1}&lstModelIds={2}&sCurrencyCode={3}',
    EVALUATION:
      '/api/v1/projects/{0}/checkpoints/{4}/evaluation?iYear={1}&lstModelIds={2}&sCurrencyCode={3}',
    COST_MATURITY_CONFIDENCE_LEVEL:
      '/api/v1/projects/{0}/checkpoints/{4}/cost-maturity-confidence-level-by-checkpoints?iYear={1}&lstModelIds={2}&sCurrencyCode={3}',
    CONFIDENCE_LEVEL_BY_IDCO:
      '/api/v1/projects/{0}/checkpoints/{4}/cost-maturity-confidence-level?iYear={1}&lstModelIds={2}&sCurrencyCode={3}',
  },
  SETTINGS: {
    UPLOAD_EXCHANGE_RATE_FILE: '/api/v1/exchange-rates',
  },
};

export default API_URL;
