import { AsyncThunk, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CostBridge } from '../../models/analysis/costBridge';
import { ModelCostImplication } from '../../models/analysis/modelCostImplication';
import { ModuleAreaCostImplication } from '../../models/analysis/moduleAreaCostImplication';
import { ChartModelRequest, ChartModelRequestObj } from '../../models/analysis/chartModelRequest';
import { ModuleCost } from '../../models/analysis/moduleCost';
import AnalysisService from '../../services/analysis/analysisService';
import { AppStatus } from '../generalTypes';
import { DiscrepancyModuleArea } from '../../models/analysis/DiscrepancyModuleArea';
import { DiscrepancyModel } from '../../models/analysis/DiscrepancyModel';
import { OverallCost } from '../../models/analysis/overallCost';
import { GETModuleSummaryRequest, ModuleSummaryModel } from '../../models/moduleSummary';
import {
  TopDownVsBottomUpProductLevelRequest,
  TopDownVsBottomUpRequest,
} from '../../models/projectOverview';
import {
  ConfidentLevelEvolutionChart,
  EvolutionChart,
  ExchangeRateEvolutionChart,
  ProductLevelCostOVerviewChart,
  SafetyMarginEvolutionChart,
  STK1EvolutionChart,
} from '../../models/analysis/evolutionChart';
import { ModuleAreaCostOVerviewChart } from '../../models/analysis/costOverviewChart';
import { IDCOCostOVerviewChart } from '../../models/analysis/idcoCostOverview';
import { FunctionCostOVerviewChart } from '../../models/analysis/functionCostOverview';
import { ClusterCostOVerviewChart } from '../../models/analysis/clusterCostOverview';
import {
  CompareWithTargetRequest,
  CompareWithTargetResponse,
} from '../../models/analysis/compareWithTarget';
import { CompareWithPredecessorResponse } from '../../models/analysis/compareWithPredecessor';
import {
  CompareBetweenCpsMultiResponse,
  CompareBetweenCpsRequest,
  CompareBetweenCpsResponse,
  CompareBetweenCpsSTKBridgeResponse,
  CompareBetweenCpsUnitResponse,
} from '../../models/analysis/compareBetweenCps';
import {
  CostMaturityRequest,
  CostMaturityResponse,
  MaturityIndicatorResponse,
} from '../../models/analysis/costMaturity';
import { EvaluationResponse } from '../../models/analysis/evaluation';
import {
  CostMaturityConfidenceLevelRequest,
  CostMaturityConfidenceLevelResponse,
} from '../../models/analysis/confidenceLevel';
import { ConfidenceLevelByIDCOResponse } from '../../models/analysis/confidenceLevelByIDCO';

export interface AnalysisContextState {
  sModuleBreakdownStatus: AppStatus;
  aModuleBreakdown: ModuleCost[];
  sCostBridgeStatus: AppStatus;
  oCostBridge: CostBridge;
  sModuleAreaCostImplicationStatus: AppStatus;
  aModuleAreaCostImplication: ModuleAreaCostImplication[];
  sModelCostImplicationStatus: AppStatus;
  aModelCostImplication: ModelCostImplication[];
  sDiscrepancyModuleAreaStatus: AppStatus;
  aDiscrepancyModuleArea: DiscrepancyModuleArea;
  sDiscrepancyModelStatus: AppStatus;
  aDiscrepancyModel: DiscrepancyModel[];
  sOverallCostImplicationStatus: AppStatus;
  aOverallCostImplication: OverallCost[];
  sModuleSummaryStatus: AppStatus;
  aModuleSummaryModel: ModuleSummaryModel[];
  sEvolutionChartStatus: AppStatus;
  aEvolutionChart: EvolutionChart[];
  sSTK1EvolutionChartStatus: AppStatus;
  aSTK1EvolutionChart: STK1EvolutionChart[];
  sSafetyMarginEvolutionChartStatus: AppStatus;
  aSafetyMarginEvolutionChart: SafetyMarginEvolutionChart[];
  sConfidentLevelEvolutionChartStatus: AppStatus;
  aConfidentLevelEvolutionChart: ConfidentLevelEvolutionChart[];
  sExchangeRateEvolutionChartStatus: AppStatus;
  aExchangeRateEvolutionChart: ExchangeRateEvolutionChart[];
  sProductLevelCostOVerviewChartStatus: AppStatus;
  aProductLevelCostOVerviewChart: ProductLevelCostOVerviewChart;
  sModuleAreaCostOVerviewChartStatus: AppStatus;
  aModuleAreaCostOVerviewChart: ModuleAreaCostOVerviewChart;
  sIdcoCostOVerviewChartStatus: AppStatus;
  aIdcoCostOVerviewChart: IDCOCostOVerviewChart;
  sFunctionCostOVerviewChartStatus: AppStatus;
  aFunctionCostOVerviewChart: FunctionCostOVerviewChart;
  sClusterCostOVerviewChartStatus: AppStatus;
  aClusterCostOVerviewChart: ClusterCostOVerviewChart;
  sCompareWithTargetStatus: AppStatus;
  oCompareWithTarget: CompareWithTargetResponse;
  sCompareWithPredecessorStatus: AppStatus;
  oCompareWithPredecessor: CompareWithPredecessorResponse;
  sCompareBetweenCpsStatus: AppStatus;
  oCompareBetweenCps: CompareBetweenCpsResponse;
  sCompareBetweenCpsUnitStatus: AppStatus;
  oCompareBetweenCpsUnit: CompareBetweenCpsUnitResponse[];
  sCompareBetweenCpsSTKBridgeStatus: AppStatus;
  oCompareBetweenCpsSTKBridge: CompareBetweenCpsSTKBridgeResponse;
  sCompareBetweenCpsMultiStatus: AppStatus;
  oCompareBetweenCpsMulti: CompareBetweenCpsMultiResponse;
  sCostMaturityStatus: AppStatus;
  oCostMaturity: CostMaturityResponse;
  sCostMaturityIndicatorStatus: AppStatus;
  oCostMaturityIndicator: MaturityIndicatorResponse;
  sEvaluationStatus: AppStatus;
  aEvaluationList: EvaluationResponse[];
  sCostMaturityConfidenceLevelStatus: AppStatus;
  aCostMaturityConfidenceLevel: CostMaturityConfidenceLevelResponse[];
  sConfidenceLevelByIDCOStatus: AppStatus;
  oConfidenceLevelByIDCO: ConfidenceLevelByIDCOResponse;
}

const oInitialState: AnalysisContextState = {
  sModuleBreakdownStatus: 'IDLE',
  aModuleBreakdown: [],
  sCostBridgeStatus: 'IDLE',
  oCostBridge: {} as CostBridge,
  sModuleAreaCostImplicationStatus: 'IDLE',
  aModuleAreaCostImplication: [],
  sModelCostImplicationStatus: 'IDLE',
  aModelCostImplication: [],
  sDiscrepancyModuleAreaStatus: 'IDLE',
  aDiscrepancyModuleArea: {
    lstModuleAreas: [],
    lstTotals: [],
    lstSafetyMargin: [],
  },
  sDiscrepancyModelStatus: 'IDLE',
  aDiscrepancyModel: [],
  sOverallCostImplicationStatus: 'IDLE',
  aOverallCostImplication: [],
  sModuleSummaryStatus: 'IDLE',
  aModuleSummaryModel: [],
  sEvolutionChartStatus: 'IDLE',
  aEvolutionChart: [],
  sSTK1EvolutionChartStatus: 'IDLE',
  aSTK1EvolutionChart: [],
  sSafetyMarginEvolutionChartStatus: 'IDLE',
  aSafetyMarginEvolutionChart: [],
  sConfidentLevelEvolutionChartStatus: 'IDLE',
  aConfidentLevelEvolutionChart: [],
  sExchangeRateEvolutionChartStatus: 'IDLE',
  aExchangeRateEvolutionChart: [],
  sProductLevelCostOVerviewChartStatus: 'IDLE',
  aProductLevelCostOVerviewChart: {
    lstData: [],
    lstTotals: {
      lstModels: [],
      lstReferenceModel: [],
    },
    lstSafetyMargin: {
      lstModels: [],
      lstReferenceModel: [],
    },
  } as ProductLevelCostOVerviewChart,
  sModuleAreaCostOVerviewChartStatus: 'IDLE',
  aModuleAreaCostOVerviewChart: {
    lstData: [],
    lstTotals: {
      lstModels: [],
      lstReferenceModel: [],
    },
    lstSafetyMargin: {
      lstModels: [],
      lstReferenceModel: [],
    },
  },
  sIdcoCostOVerviewChartStatus: 'IDLE',
  aIdcoCostOVerviewChart: {
    lstData: [],
    lstTotals: {
      lstModels: [],
      lstReferenceModel: [],
    },
  } as IDCOCostOVerviewChart,
  sFunctionCostOVerviewChartStatus: 'IDLE',
  aFunctionCostOVerviewChart: {
    lstData: [],
    lstTotals: {
      lstModels: [],
      lstReferenceModel: [],
    },
    lstSafetyMargin: {
      lstModels: [],
      lstReferenceModel: [],
    },
  } as FunctionCostOVerviewChart,
  sClusterCostOVerviewChartStatus: 'IDLE',
  aClusterCostOVerviewChart: {
    lstData: [],
    lstTotals: {
      lstModels: [],
      lstReferenceModel: [],
    },
    lstSafetyMargin: {
      lstModels: [],
      lstReferenceModel: [],
    },
  } as ClusterCostOVerviewChart,
  sCompareWithTargetStatus: 'IDLE',
  oCompareWithTarget: {
    lstModuleArea: [],
    deTotalTarget: 0,
    deTotalGap: 0,
    deTotal: 0,
  } as CompareWithTargetResponse,
  sCompareWithPredecessorStatus: 'IDLE',
  oCompareWithPredecessor: {
    lstItem: [],
    deTotalPredecessor: 0,
    deTotal: 0,
    deTotalGap: 0,
    deTotalTarget: 0,
    deTotalSecondGap: 0,
  } as CompareWithPredecessorResponse,
  sCompareBetweenCpsStatus: 'IDLE',
  oCompareBetweenCps: {
    lstModels: [],
    deTotal1: 0,
    deTotal2: 0,
  },
  sCompareBetweenCpsUnitStatus: 'IDLE',
  oCompareBetweenCpsUnit: [],
  sCompareBetweenCpsSTKBridgeStatus: 'IDLE',
  oCompareBetweenCpsSTKBridge: {} as CompareBetweenCpsSTKBridgeResponse,
  sCompareBetweenCpsMultiStatus: 'IDLE',
  oCompareBetweenCpsMulti: {} as CompareBetweenCpsMultiResponse,
  sCostMaturityStatus: 'IDLE',
  oCostMaturity: {
    lstIDCO: [],
    deTotalImportedPerc: 0,
    deTotalNationalPerc: 0,
  } as CostMaturityResponse,
  sCostMaturityIndicatorStatus: 'IDLE',
  oCostMaturityIndicator: {
    lstRandD: [],
    lstPurchasing: [],
    lstCostModeling: [],
  } as MaturityIndicatorResponse,
  sEvaluationStatus: 'IDLE',
  aEvaluationList: [] as EvaluationResponse[],
  sCostMaturityConfidenceLevelStatus: 'IDLE',
  aCostMaturityConfidenceLevel: [],
  sConfidenceLevelByIDCOStatus: 'IDLE',
  oConfidenceLevelByIDCO: {
    lstIDCO: [],
    deTotalMaturityPerc: 0,
    deTotalConfidentPerc: 0,
  } as ConfidenceLevelByIDCOResponse,
};

const getModuleBreakdown: AsyncThunk<ModuleCost[], ChartModelRequest, {}> = createAsyncThunk(
  'getModuleBreakdown',
  async (oData: ChartModelRequest) => {
    const response = await AnalysisService.getModuleBreakdown(oData);
    return response;
  },
);

const getModuleSummary: AsyncThunk<ModuleSummaryModel[], GETModuleSummaryRequest, {}> =
  createAsyncThunk('getModuleSummary', async (oData: GETModuleSummaryRequest) => {
    const response = await AnalysisService.getModuleSummary(oData);
    return response;
  });

const getCostBridge: AsyncThunk<CostBridge, ChartModelRequestObj, {}> = createAsyncThunk(
  'getCostBridge',
  async (oData: ChartModelRequestObj) => {
    const response = await AnalysisService.getCostBridge(oData);
    return response;
  },
);

const retrieveModuleAreaCostImplication: AsyncThunk<
  ModuleAreaCostImplication[],
  ChartModelRequest,
  {}
> = createAsyncThunk('retrieveModuleAreaCostImplication', async (oData: ChartModelRequest) => {
  const response = await AnalysisService.retrieveModuleAreaCostImplication(oData);
  return response;
});

const retrieveModelCostImplication: AsyncThunk<
  ModelCostImplication[],
  TopDownVsBottomUpRequest,
  {}
> = createAsyncThunk('retrieveModelCostImplication', async (oData: TopDownVsBottomUpRequest) => {
  const response = await AnalysisService.retrieveModelCostImplication(oData);
  return response;
});

const retrieveDiscrepancyByModuleArea: AsyncThunk<
  DiscrepancyModuleArea,
  TopDownVsBottomUpRequest,
  {}
> = createAsyncThunk('retrieveDiscrepancyByModuleArea', async (oData: TopDownVsBottomUpRequest) => {
  const response = await AnalysisService.retrieveDiscrepancyByModuleArea(oData);
  return response;
});

const retrieveDiscrepancyByModel: AsyncThunk<DiscrepancyModel[], TopDownVsBottomUpRequest, {}> =
  createAsyncThunk('retrieveDiscrepancyByModel', async (oData: TopDownVsBottomUpRequest) => {
    const response = await AnalysisService.retrieveDiscrepancyByModel(oData);
    return response;
  });

const retrieveOverallCostImplication: AsyncThunk<OverallCost[], TopDownVsBottomUpRequest, {}> =
  createAsyncThunk('retrieveOverallCostImplication', async (oData: TopDownVsBottomUpRequest) => {
    const response = await AnalysisService.retrieveOverallCostImplication(oData);
    return response;
  });

const retrieveEvolutionData: AsyncThunk<EvolutionChart[], TopDownVsBottomUpRequest, {}> =
  createAsyncThunk('retrieveEvolutionChart', async (oData: TopDownVsBottomUpRequest) => {
    const response = await AnalysisService.retrieveEvolutionChart(oData);
    return response;
  });

const retrieveSTK1EvolutionData: AsyncThunk<STK1EvolutionChart[], TopDownVsBottomUpRequest, {}> =
  createAsyncThunk('retrieveSTK1EvolutionChart', async (oData: TopDownVsBottomUpRequest) => {
    const response = await AnalysisService.retrieveSTK1EvolutionChart(oData);
    return response;
  });

const retrieveProductLevelCostOVerview: AsyncThunk<
  ProductLevelCostOVerviewChart,
  TopDownVsBottomUpProductLevelRequest,
  {}
> = createAsyncThunk(
  'retrieveProductLevelCostOVerviewChart',
  async (oData: TopDownVsBottomUpProductLevelRequest) => {
    const response = await AnalysisService.retrieveProductLevelCostOVerview(oData);
    return response;
  },
);

const retrieveIDCOCostOVerview: AsyncThunk<
  IDCOCostOVerviewChart,
  TopDownVsBottomUpProductLevelRequest,
  {}
> = createAsyncThunk(
  'retrieveIDCOCostOVerviewChart',
  async (oData: TopDownVsBottomUpProductLevelRequest) => {
    const response = await AnalysisService.retrieveIDCOCostOVerview(oData);
    return response;
  },
);

const retrieveFunctionCostOVerview: AsyncThunk<
  FunctionCostOVerviewChart,
  TopDownVsBottomUpProductLevelRequest,
  {}
> = createAsyncThunk(
  'retrieveFunctionCostOVerviewChart',
  async (oData: TopDownVsBottomUpProductLevelRequest) => {
    const response = await AnalysisService.retrieveFunctionCostOVerview(oData);
    return response;
  },
);

const retrieveClusterCostOVerview: AsyncThunk<
  ClusterCostOVerviewChart,
  TopDownVsBottomUpProductLevelRequest,
  {}
> = createAsyncThunk(
  'retrieveClusterCostOVerviewChart',
  async (oData: TopDownVsBottomUpProductLevelRequest) => {
    const response = await AnalysisService.retrieveClusterCostOVerview(oData);
    return response;
  },
);

const retrieveModuleAreaCostOVerview: AsyncThunk<
  ModuleAreaCostOVerviewChart,
  TopDownVsBottomUpProductLevelRequest,
  {}
> = createAsyncThunk(
  'retrieveModuleAreaCostOVerviewChart',
  async (oData: TopDownVsBottomUpProductLevelRequest) => {
    const response = await AnalysisService.retrieveModuleAreaCostOVerview(oData);
    return response;
  },
);

const retrieveExchangeRateEvolutionData: AsyncThunk<
  ExchangeRateEvolutionChart[],
  TopDownVsBottomUpRequest,
  {}
> = createAsyncThunk(
  'retrieveEchangeRateEvolutionChart',
  async (oData: TopDownVsBottomUpRequest) => {
    const response = await AnalysisService.retrieveExchangeRateEvolutionChart(oData);
    return response;
  },
);

const retrieveConfidentLevelEvolutionData: AsyncThunk<
  ConfidentLevelEvolutionChart[],
  TopDownVsBottomUpRequest,
  {}
> = createAsyncThunk(
  'retrieveConfidentLevelEvolutionChart',
  async (oData: TopDownVsBottomUpRequest) => {
    const response = await AnalysisService.retrieveConfidentLevelEvolutionChart(oData);
    return response;
  },
);

const retrieveSafetyMarginEvolutionData: AsyncThunk<
  SafetyMarginEvolutionChart[],
  TopDownVsBottomUpRequest,
  {}
> = createAsyncThunk(
  'retrieveSafetyMarginEvolutionChart',
  async (oData: TopDownVsBottomUpRequest) => {
    const response = await AnalysisService.retrieveSafetyMarginEvolutionChart(oData);
    return response;
  },
);

const onStateChanging = (oState: AnalysisContextState, oNewObj: Partial<AnalysisContextState>) =>
  ({ ...oState, ...oNewObj } as AnalysisContextState);

const onStartModule = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sModuleBreakdownStatus: 'LOADING' });
const onErrorModule = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sModuleBreakdownStatus: 'FAILED' });
const onSuccessModule = (oState: AnalysisContextState, aModuleBreakdown: ModuleCost[]) =>
  onStateChanging(oState, {
    sModuleBreakdownStatus: 'SUCCEEDED',
    aModuleBreakdown,
  });

const onStartBridge = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sCostBridgeStatus: 'LOADING' });
const onErrorBridge = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sCostBridgeStatus: 'FAILED' });
const onSuccessBridge = (oState: AnalysisContextState, oCostBridge: CostBridge) =>
  onStateChanging(oState, {
    sCostBridgeStatus: 'SUCCEEDED',
    oCostBridge,
  });

const onStartModuleAreaCost = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sModuleAreaCostImplicationStatus: 'LOADING' });
const onErrorModuleAreaCost = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sModuleAreaCostImplicationStatus: 'FAILED' });
const onSuccessModuleAreaCost = (
  oState: AnalysisContextState,
  aModuleAreaCostImplication: ModuleAreaCostImplication[],
) =>
  onStateChanging(oState, {
    sModuleAreaCostImplicationStatus: 'SUCCEEDED',
    aModuleAreaCostImplication,
  });

const onStartModelCost = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sModelCostImplicationStatus: 'LOADING' });
const onErrorModelCost = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sModelCostImplicationStatus: 'FAILED' });
const onSuccessModelCost = (
  oState: AnalysisContextState,
  aModelCostImplication: ModelCostImplication[],
) =>
  onStateChanging(oState, {
    sModelCostImplicationStatus: 'SUCCEEDED',
    aModelCostImplication,
  });

const onStartDiscrepancyModuleArea = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sDiscrepancyModuleAreaStatus: 'LOADING' });
const onErrorDiscrepancyModuleArea = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sDiscrepancyModuleAreaStatus: 'FAILED' });
const onSuccessDiscrepancyModuleArea = (
  oState: AnalysisContextState,
  aDiscrepancyModuleArea: DiscrepancyModuleArea,
) =>
  onStateChanging(oState, {
    sDiscrepancyModuleAreaStatus: 'SUCCEEDED',
    aDiscrepancyModuleArea,
  });

const onStartDiscrepancyModel = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sDiscrepancyModelStatus: 'LOADING' });
const onErrorDiscrepancyModel = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sDiscrepancyModelStatus: 'FAILED' });
const onSuccessDiscrepancyModel = (
  oState: AnalysisContextState,
  aDiscrepancyModel: DiscrepancyModel[],
) =>
  onStateChanging(oState, {
    sDiscrepancyModelStatus: 'SUCCEEDED',
    aDiscrepancyModel,
  });

const onStartOverallCost = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sOverallCostImplicationStatus: 'LOADING' });
const onErrorOverallCost = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sOverallCostImplicationStatus: 'FAILED' });
const onSuccessOverallCost = (
  oState: AnalysisContextState,
  aOverallCostImplication: OverallCost[],
) =>
  onStateChanging(oState, {
    sOverallCostImplicationStatus: 'SUCCEEDED',
    aOverallCostImplication,
  });

const onStartEvolutionChart = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sEvolutionChartStatus: 'LOADING' });
const onErrorEvolutionChart = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sEvolutionChartStatus: 'FAILED' });
const onSuccessEvolutionChart = (oState: AnalysisContextState, aEvolutionChart: EvolutionChart[]) =>
  onStateChanging(oState, {
    sEvolutionChartStatus: 'SUCCEEDED',
    aEvolutionChart,
  });

const onStartSTK1EvolutionChart = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sSTK1EvolutionChartStatus: 'LOADING' });
const onErrorSTK1EvolutionChart = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sSTK1EvolutionChartStatus: 'FAILED' });
const onSuccessSTK1EvolutionChart = (
  oState: AnalysisContextState,
  aSTK1EvolutionChart: STK1EvolutionChart[],
) =>
  onStateChanging(oState, {
    sSTK1EvolutionChartStatus: 'SUCCEEDED',
    aSTK1EvolutionChart,
  });

const onStartSafetyMarginEvolutionChart = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sSafetyMarginEvolutionChartStatus: 'LOADING' });
const onErrorSafetyMarginEvolutionChart = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sSafetyMarginEvolutionChartStatus: 'FAILED' });
const onSuccessSafetyMarginEvolutionChart = (
  oState: AnalysisContextState,
  aSafetyMarginEvolutionChart: SafetyMarginEvolutionChart[],
) =>
  onStateChanging(oState, {
    sSafetyMarginEvolutionChartStatus: 'SUCCEEDED',
    aSafetyMarginEvolutionChart,
  });

const onStartConfidentLevelEvolutionChart = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sConfidentLevelEvolutionChartStatus: 'LOADING' });
const onErrorConfidentLevelEvolutionChart = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sConfidentLevelEvolutionChartStatus: 'FAILED' });
const onSuccessConfidentLevelEvolutionChart = (
  oState: AnalysisContextState,
  aConfidentLevelEvolutionChart: ConfidentLevelEvolutionChart[],
) =>
  onStateChanging(oState, {
    sConfidentLevelEvolutionChartStatus: 'SUCCEEDED',
    aConfidentLevelEvolutionChart,
  });

const onStartExchangeRateEvolutionChart = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sExchangeRateEvolutionChartStatus: 'LOADING' });
const onErrorExchangeRateEvolutionChart = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sExchangeRateEvolutionChartStatus: 'FAILED' });
const onSuccessExchangeRateEvolutionChart = (
  oState: AnalysisContextState,
  aExchangeRateEvolutionChart: ExchangeRateEvolutionChart[],
) =>
  onStateChanging(oState, {
    sExchangeRateEvolutionChartStatus: 'SUCCEEDED',
    aExchangeRateEvolutionChart,
  });

const onStartProductLevelCostOVerviewChart = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sProductLevelCostOVerviewChartStatus: 'LOADING' });
const onErrorProductLevelCostOVerviewChart = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sProductLevelCostOVerviewChartStatus: 'FAILED' });
const onSuccessProductLevelCostOVerviewChart = (
  oState: AnalysisContextState,
  aProductLevelCostOVerviewChart: ProductLevelCostOVerviewChart,
) =>
  onStateChanging(oState, {
    sProductLevelCostOVerviewChartStatus: 'SUCCEEDED',
    aProductLevelCostOVerviewChart,
  });

const onStartModuleAreaCostOVerviewChart = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sModuleAreaCostOVerviewChartStatus: 'LOADING' });
const onErrorModuleAreaCostOVerviewChart = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sModuleAreaCostOVerviewChartStatus: 'FAILED' });
const onSuccessModuleAreaCostOVerviewChart = (
  oState: AnalysisContextState,
  aModuleAreaCostOVerviewChart: ModuleAreaCostOVerviewChart,
) =>
  onStateChanging(oState, {
    sModuleAreaCostOVerviewChartStatus: 'SUCCEEDED',
    aModuleAreaCostOVerviewChart,
  });

const onStartIdcoCostOVerviewChart = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sIdcoCostOVerviewChartStatus: 'LOADING' });
const onErrorIdcoCostOVerviewChart = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sIdcoCostOVerviewChartStatus: 'FAILED' });
const onSuccessIdcoCostOVerviewChart = (
  oState: AnalysisContextState,
  aIdcoCostOVerviewChart: IDCOCostOVerviewChart,
) =>
  onStateChanging(oState, {
    sIdcoCostOVerviewChartStatus: 'SUCCEEDED',
    aIdcoCostOVerviewChart,
  });

const onStartFunctionCostOVerviewChart = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sFunctionCostOVerviewChartStatus: 'LOADING' });
const onErrorFunctionCostOVerviewChart = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sFunctionCostOVerviewChartStatus: 'FAILED' });
const onSuccessFunctionCostOVerviewChart = (
  oState: AnalysisContextState,
  aFunctionCostOVerviewChart: FunctionCostOVerviewChart,
) =>
  onStateChanging(oState, {
    sFunctionCostOVerviewChartStatus: 'SUCCEEDED',
    aFunctionCostOVerviewChart,
  });

const onStartClusterCostOVerviewChart = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sClusterCostOVerviewChartStatus: 'LOADING' });
const onErrorClusterCostOVerviewChart = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sClusterCostOVerviewChartStatus: 'FAILED' });
const onSuccessClusterCostOVerviewChart = (
  oState: AnalysisContextState,
  aClusterCostOVerviewChart: ClusterCostOVerviewChart,
) =>
  onStateChanging(oState, {
    sClusterCostOVerviewChartStatus: 'SUCCEEDED',
    aClusterCostOVerviewChart,
  });

const onStartModuleSummary = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sModuleSummaryStatus: 'LOADING' });
const onErrorModuleSummary = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sModuleSummaryStatus: 'FAILED' });

const onSuccessModuleSummary = (
  oState: AnalysisContextState,
  aModuleSummaryModel: ModuleSummaryModel[],
) =>
  onStateChanging(oState, {
    sModuleSummaryStatus: 'SUCCEEDED',
    aModuleSummaryModel,
  });

const onStartCompareWithTarget = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sCompareWithTargetStatus: 'LOADING' });
const onErrorCompareWithTarget = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sCompareWithTargetStatus: 'FAILED' });

const onSuccessCompareWithTarget = (
  oState: AnalysisContextState,
  oCompareWithTarget: CompareWithTargetResponse,
) =>
  onStateChanging(oState, {
    sCompareWithTargetStatus: 'SUCCEEDED',
    oCompareWithTarget,
  });

const onStartCompareWithPredecessor = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sCompareWithPredecessorStatus: 'LOADING' });
const onErrorCompareWithPredecessor = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sCompareWithPredecessorStatus: 'FAILED' });

const onSuccessCompareWithPredecessor = (
  oState: AnalysisContextState,
  oCompareWithPredecessor: CompareWithPredecessorResponse,
) =>
  onStateChanging(oState, {
    sCompareWithPredecessorStatus: 'SUCCEEDED',
    oCompareWithPredecessor,
  });

const onStartCompareBetweenCps = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sCompareBetweenCpsStatus: 'LOADING' });
const onErrorCompareBetweenCps = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sCompareBetweenCpsStatus: 'FAILED' });

const onSuccessCompareBetweenCps = (
  oState: AnalysisContextState,
  oCompareBetweenCps: CompareBetweenCpsResponse,
) =>
  onStateChanging(oState, {
    sCompareBetweenCpsStatus: 'SUCCEEDED',
    oCompareBetweenCps,
  });

const onStartCompareBetweenCpsUnit = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sCompareBetweenCpsUnitStatus: 'LOADING' });
const onErrorCompareBetweenCpsUnit = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sCompareBetweenCpsUnitStatus: 'FAILED' });

const onSuccessCompareBetweenCpsUnit = (
  oState: AnalysisContextState,
  oCompareBetweenCpsUnit: CompareBetweenCpsUnitResponse[],
) =>
  onStateChanging(oState, {
    sCompareBetweenCpsUnitStatus: 'SUCCEEDED',
    oCompareBetweenCpsUnit,
  });

const onStartCompareBetweenCpsSTKBridge = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sCompareBetweenCpsSTKBridgeStatus: 'LOADING' });
const onErrorCompareBetweenCpsSTKBridge = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sCompareBetweenCpsSTKBridgeStatus: 'FAILED' });

const onSuccessCompareBetweenCpsSTKBridge = (
  oState: AnalysisContextState,
  oCompareBetweenCpsSTKBridge: CompareBetweenCpsSTKBridgeResponse,
) =>
  onStateChanging(oState, {
    sCompareBetweenCpsSTKBridgeStatus: 'SUCCEEDED',
    oCompareBetweenCpsSTKBridge,
  });

const onStartCompareBetweenCpsMulti = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sCompareBetweenCpsMultiStatus: 'LOADING' });
const onErrorCompareBetweenCpsMulti = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sCompareBetweenCpsMultiStatus: 'FAILED' });

const onSuccessCompareBetweenCpsMulti = (
  oState: AnalysisContextState,
  oCompareBetweenCpsMulti: CompareBetweenCpsMultiResponse,
) =>
  onStateChanging(oState, {
    sCompareBetweenCpsMultiStatus: 'SUCCEEDED',
    oCompareBetweenCpsMulti,
  });

const onStartCostMaturity = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sCostMaturityStatus: 'LOADING' });
const onErrorCostMaturity = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sCostMaturityStatus: 'FAILED' });

const onSuccessCostMaturity = (oState: AnalysisContextState, oCostMaturity: CostMaturityResponse) =>
  onStateChanging(oState, {
    sCostMaturityStatus: 'SUCCEEDED',
    oCostMaturity,
  });

const onStartConfidenceLevelByIDCO = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sConfidenceLevelByIDCOStatus: 'LOADING' });
const onErrorConfidenceLevelByIDCO = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sConfidenceLevelByIDCOStatus: 'FAILED' });

const onSuccessConfidenceLevelByIDCO = (
  oState: AnalysisContextState,
  oConfidenceLevelByIDCO: ConfidenceLevelByIDCOResponse,
) =>
  onStateChanging(oState, {
    sConfidenceLevelByIDCOStatus: 'SUCCEEDED',
    oConfidenceLevelByIDCO,
  });

const onStartEvaluation = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sEvaluationStatus: 'LOADING' });
const onErrorEvaluation = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sEvaluationStatus: 'FAILED' });

const onSuccessEvaluation = (oState: AnalysisContextState, aEvaluationList: EvaluationResponse[]) =>
  onStateChanging(oState, {
    sEvaluationStatus: 'SUCCEEDED',
    aEvaluationList,
  });

const onStartCostMaturityIndicator = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sCostMaturityIndicatorStatus: 'LOADING' });
const onErrorCostMaturityIndicator = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sCostMaturityIndicatorStatus: 'FAILED' });

const onSuccessCostMaturityIndicator = (
  oState: AnalysisContextState,
  oCostMaturityIndicator: MaturityIndicatorResponse,
) =>
  onStateChanging(oState, {
    sCostMaturityIndicatorStatus: 'SUCCEEDED',
    oCostMaturityIndicator,
  });

const onStartCostMaturityConfidenceLevel = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sCostMaturityConfidenceLevelStatus: 'LOADING' });
const onErrorCostMaturityConfidenceLevel = (oState: AnalysisContextState) =>
  onStateChanging(oState, { sCostMaturityConfidenceLevelStatus: 'FAILED' });

const onSuccessCostMaturityConfidenceLevel = (
  oState: AnalysisContextState,
  aCostMaturityConfidenceLevel: CostMaturityConfidenceLevelResponse[],
) =>
  onStateChanging(oState, {
    sCostMaturityConfidenceLevelStatus: 'SUCCEEDED',
    aCostMaturityConfidenceLevel,
  });

const getCompareWithTarget: AsyncThunk<CompareWithTargetResponse, CompareWithTargetRequest, {}> =
  createAsyncThunk('getCompareWithTargetObject', async (oData: CompareWithTargetRequest) => {
    const response = await AnalysisService.getCompareWithTarget(oData);
    return response;
  });

const getCostMaturity: AsyncThunk<CostMaturityResponse, CostMaturityRequest, {}> = createAsyncThunk(
  'getCostMaturity',
  async (oData: CostMaturityRequest) => {
    const response = await AnalysisService.getCostMaturity(oData);
    return response;
  },
);

const getConfidenceLevelByIDCO: AsyncThunk<ConfidenceLevelByIDCOResponse, CostMaturityRequest, {}> =
  createAsyncThunk('getConfidenceLevelByIDCO', async (oData: CostMaturityRequest) => {
    const response = await AnalysisService.getConfidenceLevelByIDCO(oData);
    return response;
  });

const getEvaluation: AsyncThunk<EvaluationResponse[], CostMaturityRequest, {}> = createAsyncThunk(
  'getEvaluation',
  async (oData: CostMaturityRequest) => {
    const response = await AnalysisService.getEvaluation(oData);
    return response;
  },
);

const getCostMaturityIndicator: AsyncThunk<MaturityIndicatorResponse, CostMaturityRequest, {}> =
  createAsyncThunk('getCostMaturityIndicator', async (oData: CostMaturityRequest) => {
    const response = await AnalysisService.getCostMaturityIndicator(oData);
    return response;
  });

const getCostMaturityConfidenceLevel: AsyncThunk<
  CostMaturityConfidenceLevelResponse[],
  CostMaturityConfidenceLevelRequest,
  {}
> = createAsyncThunk(
  'getCostMaturityConfidenceLevel',
  async (oData: CostMaturityConfidenceLevelRequest) => {
    const response = await AnalysisService.getCostMaturityConfidenceLevel(oData);
    return response;
  },
);

const getCompareBetweenCps: AsyncThunk<CompareBetweenCpsResponse, CompareBetweenCpsRequest, {}> =
  createAsyncThunk('getCompareBetweenCpsObject', async (oData: CompareBetweenCpsRequest) => {
    const response = await AnalysisService.getCompareBetweenCps(oData);
    return response;
  });

const getCompareBetweenCpsUnit: AsyncThunk<
  CompareBetweenCpsUnitResponse[],
  CompareBetweenCpsRequest,
  {}
> = createAsyncThunk('getCompareBetweenCpsUnitObject', async (oData: CompareBetweenCpsRequest) => {
  const response = await AnalysisService.getCompareBetweenCpsUnit(oData);
  return response;
});

const getCompareBetweenCpsSTKBridge: AsyncThunk<
  CompareBetweenCpsSTKBridgeResponse,
  CompareBetweenCpsRequest,
  {}
> = createAsyncThunk('getCompareBetweenCpsSTKBridge', async (oData: CompareBetweenCpsRequest) => {
  const response = await AnalysisService.getCompareBetweenCpsSTKBridge(oData);
  return response;
});

const getCompareWithPredecessor: AsyncThunk<
  CompareWithPredecessorResponse,
  CompareWithTargetRequest,
  {}
> = createAsyncThunk('getCompareWithPredecessorObject', async (oData: CompareWithTargetRequest) => {
  const response = await AnalysisService.getCompareWithPredecessor(oData);
  return response;
});

const getCompareBetweenCpsMulti: AsyncThunk<
  CompareBetweenCpsMultiResponse,
  CompareBetweenCpsRequest,
  {}
> = createAsyncThunk('getCompareBetweenCpsMulti', async (oData: CompareBetweenCpsRequest) => {
  const response = await AnalysisService.getCompareBetweenCpsMulti(oData);
  return response;
});

export const AnalysisContext = createSlice({
  name: 'analysisContext',
  initialState: oInitialState,
  reducers: {
    resetAnalysisData(state) {
      Object.assign(state, oInitialState);
    },
  },
  extraReducers: oBuilder => {
    oBuilder
      // module breakdown section
      .addCase(getModuleBreakdown.pending, onStartModule)
      .addCase(getModuleBreakdown.fulfilled, (oState: AnalysisContextState, oAction) =>
        onSuccessModule(oState, oAction.payload),
      )
      .addCase(getModuleBreakdown.rejected, onErrorModule)

      // cost bridge section
      .addCase(getCostBridge.pending, onStartBridge)
      .addCase(getCostBridge.fulfilled, (oState: AnalysisContextState, oAction) =>
        onSuccessBridge(oState, oAction.payload),
      )
      .addCase(getCostBridge.rejected, onErrorBridge)

      // module area cost implication section
      .addCase(retrieveModuleAreaCostImplication.pending, onStartModuleAreaCost)
      .addCase(
        retrieveModuleAreaCostImplication.fulfilled,
        (oState: AnalysisContextState, oAction) => onSuccessModuleAreaCost(oState, oAction.payload),
      )
      .addCase(retrieveModuleAreaCostImplication.rejected, onErrorModuleAreaCost)

      // model cost implication section
      .addCase(retrieveModelCostImplication.pending, onStartModelCost)
      .addCase(retrieveModelCostImplication.fulfilled, (oState: AnalysisContextState, oAction) =>
        onSuccessModelCost(oState, oAction.payload),
      )
      .addCase(retrieveModelCostImplication.rejected, onErrorModelCost)

      // discrepancy by module area section
      .addCase(retrieveDiscrepancyByModuleArea.pending, onStartDiscrepancyModuleArea)
      .addCase(retrieveDiscrepancyByModuleArea.fulfilled, (oState: AnalysisContextState, oAction) =>
        onSuccessDiscrepancyModuleArea(oState, oAction.payload),
      )
      .addCase(retrieveDiscrepancyByModuleArea.rejected, onErrorDiscrepancyModuleArea)

      // discrepancy by model section
      .addCase(retrieveDiscrepancyByModel.pending, onStartDiscrepancyModel)
      .addCase(retrieveDiscrepancyByModel.fulfilled, (oState: AnalysisContextState, oAction) =>
        onSuccessDiscrepancyModel(oState, oAction.payload),
      )
      .addCase(retrieveDiscrepancyByModel.rejected, onErrorDiscrepancyModel)

      // overall cost implication section
      .addCase(retrieveOverallCostImplication.pending, onStartOverallCost)
      .addCase(retrieveOverallCostImplication.fulfilled, (oState: AnalysisContextState, oAction) =>
        onSuccessOverallCost(oState, oAction.payload),
      )
      .addCase(retrieveOverallCostImplication.rejected, onErrorOverallCost)

      // evolution Chart
      .addCase(retrieveEvolutionData.pending, onStartEvolutionChart)
      .addCase(retrieveEvolutionData.fulfilled, (oState: AnalysisContextState, oAction) =>
        onSuccessEvolutionChart(oState, oAction.payload),
      )
      .addCase(retrieveEvolutionData.rejected, onErrorEvolutionChart)

      // stk1 evolution Chart
      .addCase(retrieveSTK1EvolutionData.pending, onStartSTK1EvolutionChart)
      .addCase(retrieveSTK1EvolutionData.fulfilled, (oState: AnalysisContextState, oAction) =>
        onSuccessSTK1EvolutionChart(oState, oAction.payload),
      )
      .addCase(retrieveSTK1EvolutionData.rejected, onErrorSTK1EvolutionChart)

      // safety margin evolution Chart
      .addCase(retrieveSafetyMarginEvolutionData.pending, onStartSafetyMarginEvolutionChart)
      .addCase(
        retrieveSafetyMarginEvolutionData.fulfilled,
        (oState: AnalysisContextState, oAction) =>
          onSuccessSafetyMarginEvolutionChart(oState, oAction.payload),
      )
      .addCase(retrieveSafetyMarginEvolutionData.rejected, onErrorSafetyMarginEvolutionChart)

      // confident level evolution Chart
      .addCase(retrieveConfidentLevelEvolutionData.pending, onStartConfidentLevelEvolutionChart)
      .addCase(
        retrieveConfidentLevelEvolutionData.fulfilled,
        (oState: AnalysisContextState, oAction) =>
          onSuccessConfidentLevelEvolutionChart(oState, oAction.payload),
      )
      .addCase(retrieveConfidentLevelEvolutionData.rejected, onErrorConfidentLevelEvolutionChart)

      // confident level evolution Chart
      .addCase(retrieveExchangeRateEvolutionData.pending, onStartExchangeRateEvolutionChart)
      .addCase(
        retrieveExchangeRateEvolutionData.fulfilled,
        (oState: AnalysisContextState, oAction) =>
          onSuccessExchangeRateEvolutionChart(oState, oAction.payload),
      )
      .addCase(retrieveExchangeRateEvolutionData.rejected, onErrorExchangeRateEvolutionChart)

      // product level Chart
      .addCase(retrieveProductLevelCostOVerview.pending, onStartProductLevelCostOVerviewChart)
      .addCase(
        retrieveProductLevelCostOVerview.fulfilled,
        (oState: AnalysisContextState, oAction) =>
          onSuccessProductLevelCostOVerviewChart(oState, oAction.payload),
      )
      .addCase(retrieveProductLevelCostOVerview.rejected, onErrorProductLevelCostOVerviewChart)

      // module area cost overview Chart
      .addCase(retrieveModuleAreaCostOVerview.pending, onStartModuleAreaCostOVerviewChart)
      .addCase(retrieveModuleAreaCostOVerview.fulfilled, (oState: AnalysisContextState, oAction) =>
        onSuccessModuleAreaCostOVerviewChart(oState, oAction.payload),
      )
      .addCase(retrieveModuleAreaCostOVerview.rejected, onErrorModuleAreaCostOVerviewChart)

      // idco cost overview Chart
      .addCase(retrieveIDCOCostOVerview.pending, onStartIdcoCostOVerviewChart)
      .addCase(retrieveIDCOCostOVerview.fulfilled, (oState: AnalysisContextState, oAction) =>
        onSuccessIdcoCostOVerviewChart(oState, oAction.payload),
      )
      .addCase(retrieveIDCOCostOVerview.rejected, onErrorIdcoCostOVerviewChart)

      // function cost overview Chart
      .addCase(retrieveFunctionCostOVerview.pending, onStartFunctionCostOVerviewChart)
      .addCase(retrieveFunctionCostOVerview.fulfilled, (oState: AnalysisContextState, oAction) =>
        onSuccessFunctionCostOVerviewChart(oState, oAction.payload),
      )
      .addCase(retrieveFunctionCostOVerview.rejected, onErrorFunctionCostOVerviewChart)

      // cluster cost overview Chart
      .addCase(retrieveClusterCostOVerview.pending, onStartClusterCostOVerviewChart)
      .addCase(retrieveClusterCostOVerview.fulfilled, (oState: AnalysisContextState, oAction) =>
        onSuccessClusterCostOVerviewChart(oState, oAction.payload),
      )
      .addCase(retrieveClusterCostOVerview.rejected, onErrorClusterCostOVerviewChart)

      // get module summary
      .addCase(getModuleSummary.pending, onStartModuleSummary)
      .addCase(getModuleSummary.fulfilled, (oState: AnalysisContextState, oAction) =>
        onSuccessModuleSummary(oState, oAction.payload),
      )
      .addCase(getModuleSummary.rejected, onErrorModuleSummary)

      // get compare with target
      .addCase(getCompareWithTarget.pending, onStartCompareWithTarget)
      .addCase(getCompareWithTarget.fulfilled, (oState: AnalysisContextState, oAction) =>
        onSuccessCompareWithTarget(oState, oAction.payload),
      )
      .addCase(getCompareWithTarget.rejected, onErrorCompareWithTarget)

      // get compare with predecessor
      .addCase(getCompareWithPredecessor.pending, onStartCompareWithPredecessor)
      .addCase(getCompareWithPredecessor.fulfilled, (oState: AnalysisContextState, oAction) =>
        onSuccessCompareWithPredecessor(oState, oAction.payload),
      )
      .addCase(getCompareWithPredecessor.rejected, onErrorCompareWithPredecessor)

      // get compare between cps
      .addCase(getCompareBetweenCps.pending, onStartCompareBetweenCps)
      .addCase(getCompareBetweenCps.fulfilled, (oState: AnalysisContextState, oAction) =>
        onSuccessCompareBetweenCps(oState, oAction.payload),
      )
      .addCase(getCompareBetweenCps.rejected, onErrorCompareBetweenCps)

      // get compare between cps unit cost
      .addCase(getCompareBetweenCpsUnit.pending, onStartCompareBetweenCpsUnit)
      .addCase(getCompareBetweenCpsUnit.fulfilled, (oState: AnalysisContextState, oAction) =>
        onSuccessCompareBetweenCpsUnit(oState, oAction.payload),
      )
      .addCase(getCompareBetweenCpsUnit.rejected, onErrorCompareBetweenCpsUnit)

      // get compare between cps stk bridge
      .addCase(getCompareBetweenCpsSTKBridge.pending, onStartCompareBetweenCpsSTKBridge)
      .addCase(getCompareBetweenCpsSTKBridge.fulfilled, (oState: AnalysisContextState, oAction) =>
        onSuccessCompareBetweenCpsSTKBridge(oState, oAction.payload),
      )
      .addCase(getCompareBetweenCpsSTKBridge.rejected, onErrorCompareBetweenCpsSTKBridge)

      // get compare between cps multi
      .addCase(getCompareBetweenCpsMulti.pending, onStartCompareBetweenCpsMulti)
      .addCase(getCompareBetweenCpsMulti.fulfilled, (oState: AnalysisContextState, oAction) =>
        onSuccessCompareBetweenCpsMulti(oState, oAction.payload),
      )
      .addCase(getCompareBetweenCpsMulti.rejected, onErrorCompareBetweenCpsMulti)

      // get cost maturity data
      .addCase(getCostMaturity.pending, onStartCostMaturity)
      .addCase(getCostMaturity.fulfilled, (oState: AnalysisContextState, oAction) =>
        onSuccessCostMaturity(oState, oAction.payload),
      )
      .addCase(getCostMaturity.rejected, onErrorCostMaturity)

      // get confidence level by idco
      .addCase(getConfidenceLevelByIDCO.pending, onStartConfidenceLevelByIDCO)
      .addCase(getConfidenceLevelByIDCO.fulfilled, (oState: AnalysisContextState, oAction) =>
        onSuccessConfidenceLevelByIDCO(oState, oAction.payload),
      )
      .addCase(getConfidenceLevelByIDCO.rejected, onErrorConfidenceLevelByIDCO)

      // get cost maturity indicator data
      .addCase(getCostMaturityIndicator.pending, onStartCostMaturityIndicator)
      .addCase(getCostMaturityIndicator.fulfilled, (oState: AnalysisContextState, oAction) =>
        onSuccessCostMaturityIndicator(oState, oAction.payload),
      )
      .addCase(getCostMaturityIndicator.rejected, onErrorCostMaturityIndicator)

      // get evaluation data
      .addCase(getEvaluation.pending, onStartEvaluation)
      .addCase(getEvaluation.fulfilled, (oState: AnalysisContextState, oAction) =>
        onSuccessEvaluation(oState, oAction.payload),
      )
      .addCase(getEvaluation.rejected, onErrorEvaluation)

      // get cost maturity confidence level data
      .addCase(getCostMaturityConfidenceLevel.pending, onStartCostMaturityConfidenceLevel)
      .addCase(getCostMaturityConfidenceLevel.fulfilled, (oState: AnalysisContextState, oAction) =>
        onSuccessCostMaturityConfidenceLevel(oState, oAction.payload),
      )
      .addCase(getCostMaturityConfidenceLevel.rejected, onErrorCostMaturityConfidenceLevel);
  },
});

export {
  getModuleBreakdown,
  getCostBridge,
  retrieveModuleAreaCostImplication,
  retrieveModelCostImplication,
  retrieveDiscrepancyByModuleArea,
  retrieveDiscrepancyByModel,
  retrieveOverallCostImplication,
  getModuleSummary,
  retrieveEvolutionData,
  retrieveSTK1EvolutionData,
  retrieveSafetyMarginEvolutionData,
  retrieveConfidentLevelEvolutionData,
  retrieveExchangeRateEvolutionData,
  retrieveProductLevelCostOVerview,
  retrieveModuleAreaCostOVerview,
  retrieveIDCOCostOVerview,
  retrieveFunctionCostOVerview,
  retrieveClusterCostOVerview,
  getCompareWithTarget,
  getCompareWithPredecessor,
  getCompareBetweenCps,
  getCompareBetweenCpsUnit,
  getCompareBetweenCpsSTKBridge,
  getCompareBetweenCpsMulti,
  getCostMaturity,
  getCostMaturityIndicator,
  getEvaluation,
  getCostMaturityConfidenceLevel,
  getConfidenceLevelByIDCO,
};
export const { resetAnalysisData } = AnalysisContext.actions;
export default AnalysisContext.reducer;
