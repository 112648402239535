import { AsyncThunk, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  GetSafetyMarginListRequest,
  SafetyMarginModelObj,
  SafetyMarginRequest,
  SafetyMarginResponse,
} from '../../models/bottomUp/safetyMarginModel';
import SafetyMarginService from '../../services/bottomUp/safetyMarginService';
import { AppStatus } from '../generalTypes';

export interface SafetyMarginListContextState {
  sSafetyMarginListStatus: AppStatus;
  oSafetyMarginObj: SafetyMarginModelObj;
}

const oInitialState: SafetyMarginListContextState = {
  sSafetyMarginListStatus: 'IDLE',
  oSafetyMarginObj: {} as SafetyMarginModelObj,
};

const onStateChanging = (
  oState: SafetyMarginListContextState,
  oNewObj: Partial<SafetyMarginListContextState>,
) => ({ ...oState, ...oNewObj } as SafetyMarginListContextState);

const onStartSafetyMarginList = (oState: SafetyMarginListContextState) =>
  onStateChanging(oState, { sSafetyMarginListStatus: 'LOADING' });
const onErrorSafetyMarginList = (oState: SafetyMarginListContextState) =>
  onStateChanging(oState, { sSafetyMarginListStatus: 'FAILED' });

const onSuccessSafetyMarginList = (
  oState: SafetyMarginListContextState,
  oSafetyMarginObj: SafetyMarginModelObj,
) =>
  onStateChanging(oState, {
    sSafetyMarginListStatus: 'SUCCEEDED',
    oSafetyMarginObj,
  });

const getSafetyMarginList: AsyncThunk<SafetyMarginModelObj, GetSafetyMarginListRequest, {}> =
  createAsyncThunk('getSafetyMarginList', async (oData: GetSafetyMarginListRequest) => {
    const response = await SafetyMarginService.getSafetyMargin(oData);
    return response;
  });

const submitSafetyMargin: AsyncThunk<SafetyMarginResponse, SafetyMarginRequest, {}> =
  createAsyncThunk('submitSafetyMargin', async (oData: SafetyMarginRequest) => {
    const response = await SafetyMarginService.submitSafetyMargin(oData);
    return response;
  });

const onStartSubmitSafetyMargin = (oState: SafetyMarginListContextState) =>
  onStateChanging(oState, { sSafetyMarginListStatus: 'LOADING' });
const onErrorSubmitSafetyMargin = (oState: SafetyMarginListContextState) =>
  onStateChanging(oState, { sSafetyMarginListStatus: 'FAILED' });

const onSuccessSubmitSafetyMargin = (oState: SafetyMarginListContextState) =>
  onStateChanging(oState, {
    sSafetyMarginListStatus: 'SUCCEEDED',
  });

export const SafetyMarginListContext = createSlice({
  name: 'safetyMarginContext',
  initialState: oInitialState,
  reducers: {
    clearSafetyMarginData: () => oInitialState,
  },
  extraReducers: oBuilder => {
    oBuilder
      // get safety margin data
      .addCase(getSafetyMarginList.pending, onStartSafetyMarginList)
      .addCase(getSafetyMarginList.fulfilled, (oState: SafetyMarginListContextState, oAction) =>
        onSuccessSafetyMarginList(oState, oAction.payload),
      )
      .addCase(getSafetyMarginList.rejected, onErrorSafetyMarginList)

      // submit safety margin save section
      .addCase(submitSafetyMargin.pending, onStartSubmitSafetyMargin)
      .addCase(submitSafetyMargin.fulfilled, (oState: SafetyMarginListContextState) =>
        onSuccessSubmitSafetyMargin(oState),
      )
      .addCase(submitSafetyMargin.rejected, onErrorSubmitSafetyMargin);
  },
});

export { getSafetyMarginList, submitSafetyMargin };
export const { clearSafetyMarginData } = SafetyMarginListContext.actions;
export default SafetyMarginListContext.reducer;
