import { usePermissionChecker } from '../../hooks';
import { UserPermissions } from '../../utils';

type WithPermissionAndFallbackProps = {
  children: JSX.Element;
  lstPermission: UserPermissions[];
  fallback: JSX.Element;
};

function WithPermissionAndFallback({
  children,
  lstPermission,
  fallback,
}: WithPermissionAndFallbackProps) {
  const hasPermission = usePermissionChecker(lstPermission);

  return hasPermission ? children : fallback;
}

export default WithPermissionAndFallback;
