export enum ChartExportType {
  IMAGE = 1,
  EXCEL = 2,
  POWER_POINT = 3,
}

export enum ChartId {
  DISCREPANCY_BY_MODEL = 'discrepancy-by-model',
  DISCREPANCY_BY_MODULE_AREA = 'discrepancy-by-module-area',
  MODEL_COST_IMPLICATION = 'model-cost-implication',
  COST_BRIDGE = 'cost-bridge',
  COST_HISTORY = 'cost-history',
  MODULE_AREA_COST_IMPLICATION = 'module-area-cost-implication',
  MODULE_BREAKDOWN = 'module-breakdown',
  STK1_DEVIATION_BY_MODULE_AREA = 'stk1-deviation-by-module-area',
  MODULE_TARGET_SETTINGS = 'module-target-settings',
  OVERALL_COST_IMPLICATION = 'overall-cost-implication',
  MODULE_SUMMARY = 'module-summary',
  HIGH_LEVEL_MATCHING = 'high-level-matching',
}
