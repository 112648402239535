import { CostBridge } from '../../models/analysis/costBridge';
import { ModelCostImplication } from '../../models/analysis/modelCostImplication';
import { ModuleAreaCostImplication } from '../../models/analysis/moduleAreaCostImplication';
import { ChartModelRequest, ChartModelRequestObj } from '../../models/analysis/chartModelRequest';
import { ModuleCost } from '../../models/analysis/moduleCost';
import API_URL from '../apiUtils';
import httpService from '../httpService';
import { DiscrepancyModuleArea } from '../../models/analysis/DiscrepancyModuleArea';
import { DiscrepancyModel } from '../../models/analysis/DiscrepancyModel';
import { OverallCost } from '../../models/analysis/overallCost';
import { GETModuleSummaryRequest, ModuleSummaryModel } from '../../models/moduleSummary';
import { HighLevelMatchingModel } from '../../models/projectOverview/highLevelMatchingModel';
import {
  TopDownVsBottomUpProductLevelRequest,
  TopDownVsBottomUpRequest,
} from '../../models/projectOverview';
import {
  ConfidentLevelEvolutionChart,
  EvolutionChart,
  ExchangeRateEvolutionChart,
  ProductLevelCostOVerviewChart,
  SafetyMarginEvolutionChart,
  STK1EvolutionChart,
} from '../../models/analysis/evolutionChart';
import { ModuleAreaCostOVerviewChart } from '../../models/analysis/costOverviewChart';
import { IDCOCostOVerviewChart } from '../../models/analysis/idcoCostOverview';
import { FunctionCostOVerviewChart } from '../../models/analysis/functionCostOverview';
import { ClusterCostOVerviewChart } from '../../models/analysis/clusterCostOverview';
import {
  CompareWithTargetRequest,
  CompareWithTargetResponse,
} from '../../models/analysis/compareWithTarget';
import {
  CompareWithPredecessorRequest,
  CompareWithPredecessorResponse,
} from '../../models/analysis/compareWithPredecessor';
import {
  CompareBetweenCpsMultiResponse,
  CompareBetweenCpsRequest,
  CompareBetweenCpsResponse,
  CompareBetweenCpsSTKBridgeResponse,
  CompareBetweenCpsUnitResponse,
} from '../../models/analysis/compareBetweenCps';
import {
  CostMaturityRequest,
  CostMaturityResponse,
  MaturityIndicatorResponse,
} from '../../models/analysis/costMaturity';
import { EvaluationResponse } from '../../models/analysis/evaluation';
import {
  CostMaturityConfidenceLevelRequest,
  CostMaturityConfidenceLevelResponse,
} from '../../models/analysis/confidenceLevel';
import { ConfidenceLevelByIDCOResponse } from '../../models/analysis/confidenceLevelByIDCO';

class AnalysisService {
  static getModuleBreakdown = async (oData: ChartModelRequest) =>
    httpService.getJson<ModuleCost[]>(API_URL.ANALYSIS.MODULE_BREAKDOWN, [
      oData.iProjectId,
      oData.iNextCheckpointId.toString(),
      oData.iModelId.toString(),
      oData.sCurrencyCode,
    ]);

  static getModuleSummary = async (sData: GETModuleSummaryRequest) =>
    httpService.getJson<ModuleSummaryModel[]>(API_URL.PROJECT.MODULE_SUMMARY_MODEL, [
      sData.sProjectId,
      sData.iNextCheckpointId.toString(),
      sData.iModelId.toString(),
      sData.sCurrencyCode,
    ]);

  static getCostBridge = async (oData: ChartModelRequestObj) =>
    httpService.getJson<CostBridge>(API_URL.ANALYSIS.COST_BRIDGE, [
      oData.sProjectId,
      oData.sNextCheckpointId,
      oData.iYear,
      oData.lstModelIds.map(value => value).join(','),
      oData.sCurrencyCode,
    ]);

  static getHighLevelMatchingList = async (aData: TopDownVsBottomUpRequest) =>
    httpService.getJson<HighLevelMatchingModel[]>(API_URL.PROJECT.HIGH_LEVEL_MATCHING, [
      aData.sProjectId,
      aData.iYear,
      aData.lstModelIds.map(value => value).join(','),
      aData.sCurrencyCode,
      aData.sNextCheckpointId?.toString() || '',
    ]);

  static getCompareWithTarget = async (aData: CompareWithTargetRequest) =>
    httpService.getJson<CompareWithTargetResponse>(API_URL.ANALYSIS.COMPARE_WITH_TARGET, [
      aData.sProjectId,
      aData.iYear,
      aData.lstModelIds.map(value => value).join(','),
      aData.sCurrencyCode,
      aData.sNextCheckpointId?.toString() || '',
    ]);

  static getCostMaturity = async (aData: CostMaturityRequest) =>
    httpService.getJson<CostMaturityResponse>(API_URL.ANALYSIS.COST_MATURITY, [
      aData.sProjectId,
      aData.iYear,
      aData.lstModelIds.map(value => value).join(','),
      aData.sCurrencyCode,
      aData.sNextCheckpointId?.toString() || '',
    ]);

  static getConfidenceLevelByIDCO = async (aData: CostMaturityRequest) =>
    httpService.getJson<ConfidenceLevelByIDCOResponse>(API_URL.ANALYSIS.CONFIDENCE_LEVEL_BY_IDCO, [
      aData.sProjectId,
      aData.iYear,
      aData.lstModelIds.map(value => value).join(','),
      aData.sCurrencyCode,
      aData.sNextCheckpointId?.toString() || '',
    ]);

  static getEvaluation = async (aData: CostMaturityRequest) =>
    httpService.getJson<EvaluationResponse[]>(API_URL.ANALYSIS.EVALUATION, [
      aData.sProjectId,
      aData.iYear,
      aData.lstModelIds.map(value => value).join(','),
      aData.sCurrencyCode,
      aData.sNextCheckpointId?.toString() || '',
    ]);

  static getCostMaturityIndicator = async (aData: CostMaturityRequest) =>
    httpService.getJson<MaturityIndicatorResponse>(API_URL.ANALYSIS.COST_MATURITY_INDICATOR, [
      aData.sProjectId,
      aData.iYear,
      aData.lstModelIds.map(value => value).join(','),
      aData.sCurrencyCode,
      aData.sNextCheckpointId?.toString() || '',
    ]);

  static getCostMaturityConfidenceLevel = async (aData: CostMaturityConfidenceLevelRequest) =>
    httpService.getJson<CostMaturityConfidenceLevelResponse[]>(
      API_URL.ANALYSIS.COST_MATURITY_CONFIDENCE_LEVEL,
      [
        aData.sProjectId,
        aData.iYear,
        aData.lstModelIds.map(value => value).join(','),
        aData.sCurrencyCode,
        aData.sNextCheckpointId?.toString() || '',
      ],
    );

  static getCompareBetweenCps = async (aData: CompareBetweenCpsRequest) =>
    httpService.getJson<CompareBetweenCpsResponse>(API_URL.ANALYSIS.COMPARE_BETWEEN_CPS, [
      aData.sProjectId,
      aData.iYear,
      aData.lstModelIds.map(value => value).join(','),
      aData.sCurrencyCode,
      aData.sNextCheckpointId?.toString() || '',
      aData.iLeftCheckpointId,
      aData.iRightCheckpointId,
    ]);

  static getCompareBetweenCpsUnit = async (aData: CompareBetweenCpsRequest) =>
    httpService.getJson<CompareBetweenCpsUnitResponse[]>(
      API_URL.ANALYSIS.COMPARE_BETWEEN_CPS_UNIT_COST,
      [
        aData.sProjectId,
        aData.iYear,
        aData.lstModelIds.map(value => value).join(','),
        aData.sCurrencyCode,
        aData.sNextCheckpointId?.toString() || '',
        aData.iLeftCheckpointId,
        aData.iRightCheckpointId,
      ],
    );

  static getCompareBetweenCpsSTKBridge = async (aData: CompareBetweenCpsRequest) =>
    httpService.getJson<CompareBetweenCpsSTKBridgeResponse>(
      API_URL.ANALYSIS.COMPARE_BETWEEN_CPS_STK_BRIDGE,
      [
        aData.sProjectId,
        aData.iYear,
        aData.lstModelIds.map(value => value).join(','),
        aData.sCurrencyCode,
        aData.sNextCheckpointId?.toString() || '',
        aData.iLeftCheckpointId,
        aData.iRightCheckpointId,
      ],
    );

  static getCompareWithPredecessor = async (aData: CompareWithPredecessorRequest) =>
    httpService.getJson<CompareWithPredecessorResponse>(API_URL.ANALYSIS.COMPARE_WITH_PREDECESSOR, [
      aData.sProjectId,
      aData.iYear,
      aData.lstModelIds.map(value => value).join(','),
      aData.sCurrencyCode,
      aData.sNextCheckpointId?.toString() || '',
      String(aData.sComparisonType),
    ]);

  static getCompareBetweenCpsMulti = async (aData: CompareBetweenCpsRequest) =>
    httpService.getJson<CompareBetweenCpsMultiResponse>(
      API_URL.ANALYSIS.COMPARE_BETWEEN_CPS_MULTI,
      [
        aData.sProjectId,
        aData.iYear,
        aData.lstModelIds.map(value => value).join(','),
        aData.sCurrencyCode,
        aData.sNextCheckpointId?.toString() || '',
        aData.iLeftCheckpointId,
        aData.iRightCheckpointId,
        String(aData.sComparisonType),
      ],
    );

  static retrieveModuleAreaCostImplication = async (oData: ChartModelRequest) =>
    httpService.getJson<ModuleAreaCostImplication[]>(
      API_URL.ANALYSIS.MODULE_AREA_COST_IMPLICATION,
      [
        oData.iProjectId,
        oData.iNextCheckpointId.toString(),
        oData.iModelId.toString(),
        oData.sCurrencyCode,
      ],
    );

  static retrieveModelCostImplication = async (oData: TopDownVsBottomUpRequest) =>
    httpService.getJson<ModelCostImplication[]>(API_URL.ANALYSIS.MODEL_COST_IMPLICATION, [
      oData.sProjectId,
      oData.iYear,
      oData.lstModelIds.map(value => value).join(','),
      oData.sCurrencyCode,
      oData.sNextCheckpointId || '',
    ]);

  static retrieveDiscrepancyByModuleArea = async (oData: TopDownVsBottomUpRequest) =>
    httpService.getJson<DiscrepancyModuleArea>(API_URL.ANALYSIS.DISCREPANCY_MODULE_AREA, [
      oData.sProjectId,
      oData.iYear,
      oData.lstModelIds.map(value => value).join(','),
      oData.sCurrencyCode,
      oData.sNextCheckpointId?.toString() || '',
    ]);

  static retrieveDiscrepancyByModel = async (oData: TopDownVsBottomUpRequest) =>
    httpService.getJson<DiscrepancyModel[]>(
      oData.bWithoutVolume
        ? API_URL.ANALYSIS.DISCREPANCY_MODEL_WITHOUT_VOLUME
        : API_URL.ANALYSIS.DISCREPANCY_MODEL,
      [
        oData.sProjectId,
        oData.iYear,
        oData.lstModelIds.map(value => value).join(','),
        oData.sCurrencyCode,
        oData.sNextCheckpointId?.toString() || '',
      ],
    );

  static retrieveOverallCostImplication = async (oData: TopDownVsBottomUpRequest) =>
    httpService.getJson<OverallCost[]>(API_URL.ANALYSIS.OVERALL_COST, [
      oData.sProjectId,
      oData.iYear,
      oData.lstModelIds.map(value => value).join(','),
      oData.sCurrencyCode,
      oData.sNextCheckpointId || '',
    ]);

  static retrieveEvolutionChart = async (oData: TopDownVsBottomUpRequest) =>
    httpService.getJson<EvolutionChart[]>(API_URL.ANALYSIS.EVOLUTION_CHART, [
      oData.sProjectId,
      oData.iYear,
      oData.lstModelIds.map(value => value).join(','),
      oData.sCurrencyCode,
      oData.sNextCheckpointId || '',
      oData.sType || '',
    ]);

  static retrieveSTK1EvolutionChart = async (oData: TopDownVsBottomUpRequest) =>
    httpService.getJson<STK1EvolutionChart[]>(API_URL.ANALYSIS.STK1_EVOLUTION_CHART, [
      oData.sProjectId,
      oData.iYear,
      oData.lstModelIds.map(value => value).join(','),
      oData.sCurrencyCode,
      oData.sNextCheckpointId || '',
    ]);

  static retrieveProductLevelCostOVerview = async (oData: TopDownVsBottomUpProductLevelRequest) =>
    httpService.getJson<ProductLevelCostOVerviewChart>(
      API_URL.ANALYSIS.Product_Level_Cost_OVerview,
      [
        oData.sProjectId,
        oData.lstModelIds.map(value => value).join(','),
        oData.sCurrencyCode,
        oData.sNextCheckpointId || '',
      ],
    );

  static retrieveIDCOCostOVerview = async (oData: TopDownVsBottomUpProductLevelRequest) =>
    httpService.getJson<IDCOCostOVerviewChart>(API_URL.ANALYSIS.Idco_Cost_OVerview, [
      oData.sProjectId,
      oData.lstModelIds.map(value => value).join(','),
      oData.sCurrencyCode,
      oData.sNextCheckpointId || '',
    ]);

  static retrieveFunctionCostOVerview = async (oData: TopDownVsBottomUpProductLevelRequest) =>
    httpService.getJson<FunctionCostOVerviewChart>(API_URL.ANALYSIS.FUNCTION_COST_OVERVIEW, [
      oData.sProjectId,
      oData.lstModelIds.map(value => value).join(','),
      oData.sCurrencyCode,
      oData.sNextCheckpointId || '',
    ]);

  static retrieveClusterCostOVerview = async (oData: TopDownVsBottomUpProductLevelRequest) =>
    httpService.getJson<ClusterCostOVerviewChart>(API_URL.ANALYSIS.CLUSTER_COST_OVERVIEW, [
      oData.sProjectId,
      oData.lstModelIds.map(value => value).join(','),
      oData.sCurrencyCode,
      oData.sNextCheckpointId || '',
    ]);

  static retrieveModuleAreaCostOVerview = async (oData: TopDownVsBottomUpProductLevelRequest) =>
    httpService.getJson<ModuleAreaCostOVerviewChart>(API_URL.ANALYSIS.MODULE_AREA_COST_OVERVIEW, [
      oData.sProjectId,
      oData.lstModelIds.map(value => value).join(','),
      oData.sCurrencyCode,
      oData.sNextCheckpointId || '',
    ]);

  static retrieveSafetyMarginEvolutionChart = async (oData: TopDownVsBottomUpRequest) =>
    httpService.getJson<SafetyMarginEvolutionChart[]>(
      API_URL.ANALYSIS.SAFETY_MARGIN_EVOLUTION_CHART,
      [
        oData.sProjectId,
        oData.iYear,
        oData.lstModelIds.map(value => value).join(','),
        oData.sCurrencyCode,
        oData.sNextCheckpointId || '',
      ],
    );

  static retrieveConfidentLevelEvolutionChart = async (oData: TopDownVsBottomUpRequest) =>
    httpService.getJson<ConfidentLevelEvolutionChart[]>(
      API_URL.ANALYSIS.Confident_Level_EVOLUTION_CHART,
      [
        oData.sProjectId,
        oData.iYear,
        oData.lstModelIds.map(value => value).join(','),
        oData.sCurrencyCode,
        oData.sNextCheckpointId || '',
      ],
    );

  static retrieveExchangeRateEvolutionChart = async (oData: TopDownVsBottomUpRequest) =>
    httpService.getJson<ExchangeRateEvolutionChart[]>(
      API_URL.ANALYSIS.EXCHANGE_RATE_EVOLUTION_CHART,
      [
        oData.sProjectId,
        oData.iYear,
        oData.lstModelIds.map(value => value).join(','),
        oData.sCurrencyCode,
        oData.sNextCheckpointId || '',
      ],
    );
}

export default AnalysisService;
