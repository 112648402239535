import { AsyncThunk, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CostedModuleBOMModel, GetCostedModuleBOMRequest } from '../../models/costedBom';
import { ModelService } from '../../services/model';
import { AppStatus } from '../generalTypes';

export interface CostedModuleBomContextState {
  sCostedModuleBomStatus: AppStatus;
  oCostedModuleBomObj: CostedModuleBOMModel;
}

const oInitialState: CostedModuleBomContextState = {
  sCostedModuleBomStatus: 'IDLE',
  oCostedModuleBomObj: {} as CostedModuleBOMModel,
};

const onStateChanging = (
  oState: CostedModuleBomContextState,
  oNewObj: Partial<CostedModuleBomContextState>,
) => ({ ...oState, ...oNewObj } as CostedModuleBomContextState);

const onSuccessCostedModuleBOM = (
  oState: CostedModuleBomContextState,
  oCostedModuleBomObj: CostedModuleBOMModel,
) =>
  onStateChanging(oState, {
    sCostedModuleBomStatus: 'SUCCEEDED',
    oCostedModuleBomObj,
  });

const onStartCostedModuleBOM = (oState: CostedModuleBomContextState) =>
  onStateChanging(oState, { sCostedModuleBomStatus: 'LOADING' });
const onErrorCostedModuleBOM = (oState: CostedModuleBomContextState) =>
  onStateChanging(oState, { sCostedModuleBomStatus: 'FAILED' });

const getCostedModuleBOM: AsyncThunk<CostedModuleBOMModel, GetCostedModuleBOMRequest, {}> =
  createAsyncThunk('getCostedModuleBOM', async (oData: GetCostedModuleBOMRequest) => {
    const response = await ModelService.getCostedModuleBom(oData);
    return response;
  });

export const CostedModuleBomContext = createSlice({
  name: 'costedModuleBomContext',
  initialState: oInitialState,
  reducers: {
    clearCostedModuleBomData: () => oInitialState,
  },
  extraReducers: oBuilder => {
    oBuilder
      // get bom for model view
      .addCase(getCostedModuleBOM.pending, onStartCostedModuleBOM)
      .addCase(getCostedModuleBOM.fulfilled, (oState: CostedModuleBomContextState, oAction) =>
        onSuccessCostedModuleBOM(oState, oAction.payload),
      )
      .addCase(getCostedModuleBOM.rejected, onErrorCostedModuleBOM);
  },
});

export { getCostedModuleBOM };
export const { clearCostedModuleBomData } = CostedModuleBomContext.actions;
export default CostedModuleBomContext.reducer;
