import { useEffect } from 'react';
import { ProjectModel } from '../../models/model';
import { GetVersionsRequest } from '../../models/model/getVersionsRequest';
import { ModelVersion } from '../../models/model/modelVersion';
import { RootState } from '../../store';
import { getModels, getVersions } from '../../store/model';
import { useAppDispatch, useAppSelector } from '../store/customHooks';

export type ProjectModelData = {
  readonly aModels: ProjectModel[];
  readonly bModelLoading: boolean;
  readonly bHasError: boolean;
  readonly refresh: () => void;
};

export function useModels(sProjectId: string, iNextCheckpointId: number): ProjectModelData {
  const dispatch = useAppDispatch();
  const { sModelStatus, aModels } = useAppSelector((state: RootState) => state.modelContext);

  useEffect(() => {
    if (sProjectId) {
      dispatch(getModels([sProjectId, iNextCheckpointId.toString()]));
    }
  }, [sProjectId, iNextCheckpointId]);

  const refresh = () => {
    dispatch(getModels([sProjectId, iNextCheckpointId.toString()]));
  };

  if (sModelStatus === 'LOADING') {
    return { aModels, bHasError: false, bModelLoading: true, refresh };
  }

  if (sModelStatus === 'FAILED') {
    return { aModels, bHasError: true, bModelLoading: false, refresh };
  }

  if (sModelStatus === 'SUCCEEDED') {
    return { aModels, bHasError: false, bModelLoading: false, refresh };
  }

  return { aModels, bHasError: false, bModelLoading: true, refresh };
}

export type ModelVersionsData = {
  readonly aModelVersions: ModelVersion[];
  readonly bVersionLoading: boolean;
  readonly bHasError: boolean;
};

export function useModelVersions(oRequest: GetVersionsRequest): ModelVersionsData {
  const dispatch = useAppDispatch();
  const { sVersionStatus, aModelVersions } = useAppSelector(
    (state: RootState) => state.modelContext,
  );

  useEffect(() => {
    if (oRequest.iModelId && oRequest.bRunDispatch) {
      dispatch(getVersions(oRequest));
    }
  }, [oRequest.iModelId, oRequest.bRunDispatch]);

  if (sVersionStatus === 'LOADING') {
    return { aModelVersions, bHasError: false, bVersionLoading: true };
  }

  if (sVersionStatus === 'FAILED') {
    return { aModelVersions, bHasError: true, bVersionLoading: false };
  }

  if (sVersionStatus === 'SUCCEEDED') {
    return { aModelVersions, bHasError: false, bVersionLoading: false };
  }

  return { aModelVersions, bHasError: false, bVersionLoading: true };
}
