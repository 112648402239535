import { AsyncThunk, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CheckpointModel } from '../../../models/projectOverview';
import { Participant, ProjectUserListModel, ProjectUserModel } from '../../../models/settings';
import { UserService } from '../../../services/user';
import { AppStatus } from '../../generalTypes';

export interface ProjectUserLinkContextState {
  sStatus: AppStatus;
  oProjectUsers: ProjectUserListModel | null;
  oSelectedCheckpoint: CheckpointModel | null;
  sSelectedCurrency: string;
  iSelectedModelId: number;
}

interface DeleteResponse {
  bDone: boolean;
  oModel: ProjectUserModel;
}

const oInitialState: ProjectUserLinkContextState = {
  oProjectUsers: null,
  sStatus: 'IDLE',
  oSelectedCheckpoint: null,
  sSelectedCurrency: '',
  iSelectedModelId: 0,
};

const getParticipants: AsyncThunk<ProjectUserListModel, string, {}> = createAsyncThunk(
  'getParticipants',
  async (sProjectId: string) => {
    const response = await UserService.getParticipants(sProjectId);
    return response;
  },
);

const saveParticipant: AsyncThunk<Participant, ProjectUserModel, {}> = createAsyncThunk(
  'saveParticipant',
  async (oData: ProjectUserModel) => {
    const response = await UserService.saveParticipant(oData);
    return response;
  },
);

const updateParticipant: AsyncThunk<Participant, ProjectUserModel, {}> = createAsyncThunk(
  'updateParticipant',
  async (oData: ProjectUserModel) => {
    const response = await UserService.updateParticipant(oData);
    return response;
  },
);

const deleteParticipant: AsyncThunk<DeleteResponse, ProjectUserModel, {}> = createAsyncThunk(
  'deleteParticipant',
  async (oData: ProjectUserModel) => {
    const response = await UserService.deleteParticipant(oData);
    return { bDone: response, oModel: oData };
  },
);

const onStateChanging = (
  _oState: ProjectUserLinkContextState,
  _oNewObj: Partial<ProjectUserLinkContextState>,
) =>
  ({
    ..._oState,
    ..._oNewObj,
  } as ProjectUserLinkContextState);

const onStart = (oState: ProjectUserLinkContextState) =>
  onStateChanging(oState, { sStatus: 'LOADING' });

const onError = (oState: ProjectUserLinkContextState) =>
  onStateChanging(oState, { sStatus: 'FAILED' });

const onSuccess = (oState: ProjectUserLinkContextState, oValue: ProjectUserListModel) =>
  onStateChanging(oState, { sStatus: 'SUCCEEDED', oProjectUsers: oValue });

const getUpdatedState = (oState: ProjectUserLinkContextState, oModel: Participant) => {
  const oCopy = { ...oState.oProjectUsers! };
  const lstList = [...oCopy.lstParticipants];

  if (oState.oProjectUsers!.lstParticipants.some(oItem => oItem.sUserId === oModel.sUserId)) {
    const iIndex = oCopy.lstParticipants.findIndex(oItem => oItem.sUserId === oModel.sUserId);

    lstList[iIndex] = oModel;
    oCopy.lstParticipants = lstList;
  } else {
    oCopy.lstParticipants = [...oCopy.lstParticipants, oModel];
  }

  return oCopy;
};

const removeUserFromState = (oState: ProjectUserLinkContextState, oResponse: DeleteResponse) => {
  const oCopy = { ...oState.oProjectUsers! };
  const lstList = [...oCopy.lstParticipants];

  if (oResponse.bDone) {
    const iIndex = oCopy.lstParticipants.findIndex(
      oItem => oItem.sUserId === oResponse.oModel.sUserId,
    );

    lstList.splice(iIndex, 1);
    oCopy.lstParticipants = lstList;
  }

  return oCopy;
};

const onSuccessSave = (oState: ProjectUserLinkContextState, oValue: Participant) =>
  onStateChanging(oState, { sStatus: 'SUCCEEDED', oProjectUsers: getUpdatedState(oState, oValue) });

const onSuccessDelete = (oState: ProjectUserLinkContextState, oValue: DeleteResponse) =>
  onStateChanging(oState, {
    sStatus: 'SUCCEEDED',
    oProjectUsers: removeUserFromState(oState, oValue),
  });

export const ProjectUserLinkContext = createSlice({
  name: 'projectUserLinkContext',
  initialState: oInitialState,
  reducers: {
    setSelectedCheckpoint(state, action: PayloadAction<CheckpointModel | null>) {
      const oState = state;
      oState.oSelectedCheckpoint = action.payload;
      oState.sStatus = 'SUCCEEDED';
    },
    setSelectedCurrency(state, action: PayloadAction<string>) {
      const oState = state;
      oState.sSelectedCurrency = action.payload;
    },
    setSelectedModelId(state, action: PayloadAction<number>) {
      const oState = state;
      oState.iSelectedModelId = action.payload;
    },
    resetProjectUserData(state) {
      Object.assign(state, oInitialState);
    },
  },
  extraReducers: _oBuilder => {
    _oBuilder
      // list participants
      .addCase(getParticipants.pending, onStart)
      .addCase(getParticipants.fulfilled, (oState: ProjectUserLinkContextState, oAction) =>
        onSuccess(oState, oAction.payload),
      )
      .addCase(getParticipants.rejected, onError)

      // save participant
      .addCase(saveParticipant.pending, onStart)
      .addCase(saveParticipant.fulfilled, (oState: ProjectUserLinkContextState, oAction) =>
        onSuccessSave(oState, oAction.payload),
      )
      .addCase(saveParticipant.rejected, onError)

      // update participant
      .addCase(updateParticipant.pending, onStart)
      .addCase(updateParticipant.fulfilled, (oState: ProjectUserLinkContextState, oAction) =>
        onSuccessSave(oState, oAction.payload),
      )
      .addCase(updateParticipant.rejected, onError)

      // delete participant
      .addCase(deleteParticipant.pending, onStart)
      .addCase(deleteParticipant.fulfilled, (oState: ProjectUserLinkContextState, oAction) =>
        onSuccessDelete(oState, oAction.payload),
      )
      .addCase(deleteParticipant.rejected, onError);
  },
});

export const {
  setSelectedModelId,
  setSelectedCurrency,
  setSelectedCheckpoint,
  resetProjectUserData,
} = ProjectUserLinkContext.actions;
export { getParticipants, saveParticipant, updateParticipant, deleteParticipant };
export default ProjectUserLinkContext.reducer;
