import { AsyncThunk, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { UserContextData } from '../../models/login';
import { LoginService } from '../../services/login';
import { LocalStorageKey, UserPermissions } from '../../utils';
import { AppStatus } from '../generalTypes';

export interface AuthContextState {
  sStatus: AppStatus;
  // sToken: string | null;
  asPermission: string[];
  bLogout: boolean;
  oUserContext: UserContextData | null;
}

const oInitialState: AuthContextState = {
  // sToken: null,
  sStatus: 'IDLE',
  asPermission: [],
  bLogout: false,
  oUserContext: null,
};

const authorize: AsyncThunk<UserContextData, void, {}> = createAsyncThunk('authorize', async () => {
  const response = await LoginService.authorize();
  return response;
});

const getPermissions: AsyncThunk<string[], void, {}> = createAsyncThunk(
  'getPermissions',
  async () => {
    const response = await LoginService.getPermissions();
    return response;
  },
);

const onStateChanging = (_oState: AuthContextState, _oNewObj: Partial<AuthContextState>) =>
  ({
    ..._oState,
    ..._oNewObj,
  } as AuthContextState);

const onStart = (_oState: AuthContextState) => onStateChanging(_oState, { sStatus: 'LOADING' });

const onError = (_oState: AuthContextState) => onStateChanging(_oState, { sStatus: 'FAILED' });

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const onSuccess = (_oState: AuthContextState, _oValue: UserContextData | null) =>
  onStateChanging(_oState, {
    sStatus: 'SUCCEEDED',
    oUserContext: _oValue ? { ..._oValue } : null,
    asPermission: [UserPermissions.APPLICATION_ACCESS],
  });

const onSuccessPermission = (_oState: AuthContextState, _sPermission: string[]) =>
  onStateChanging(_oState, {
    sStatus: 'SUCCEEDED',
    asPermission: [..._oState.asPermission, ..._sPermission],
  });

export const AuthContext = createSlice({
  name: 'authContext',
  initialState: oInitialState,
  reducers: {
    // loadToken(state, action: PayloadAction<string>) {
    //   const oState = state;
    //   oState.sToken = action.payload;
    //   oState.sStatus = 'SUCCEEDED';
    // },
    resetLogin(state) {
      const oState = state;
      localStorage.removeItem(LocalStorageKey.TOKEN);
      oState.asPermission = oInitialState.asPermission;
      oState.sStatus = oInitialState.sStatus;
      // oState.sToken = oInitialState.sToken;
      oState.bLogout = oInitialState.bLogout;
    },
    resetLoginData(state) {
      Object.assign(state, oInitialState);
    },
  },
  extraReducers: _oBuilder => {
    _oBuilder
      // login section
      .addCase(authorize.pending, onStart)
      .addCase(authorize.fulfilled, (_oState: AuthContextState, _oAction) =>
        onSuccess(_oState, _oAction.payload),
      )
      .addCase(authorize.rejected, onError)

      // permissions section
      .addCase(getPermissions.pending, onStart)
      .addCase(getPermissions.fulfilled, (_oState: AuthContextState, _oAction) =>
        onSuccessPermission(_oState, _oAction.payload),
      )
      .addCase(getPermissions.rejected, onError);
  },
});

export { authorize, getPermissions };
export const { resetLogin, resetLoginData } = AuthContext.actions;
export default AuthContext.reducer;
