import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { MsalProvider } from '@azure/msal-react';
import App from './App';
import { store } from './app/store';
import * as serviceWorker from './serviceWorker';
import httpService from './app/services/httpService';
import './i18n';
import 'antd/dist/antd.less';
import 'rc-steps/assets/index.css';
import './styles/index.css';
import msalInstance from './app/auth/azureAD';

httpService.injectStore(store);
const persistor = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
