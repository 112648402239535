import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import AuthContextReducer from './login/authContextSlice';
import ModelContextReducer from './model/modelSlice';
import ProjectContextReducer from './project/projectSlice';
import GeneralContextReducer from './shared/generalContextSlice';
import ProjectUserReducer from './settings/projects/projectUserLinkSlice';
import UserReducer from './user/userSlice';
import AnalysisReducer from './analysis/analysisSlice';
import TargetSettingReducer from './model/targetSettingSlice';
import ModelViewReducer from './model/modelViewSlice';
import overloadedBomReducer from './bottomUp/overloadedBomSlice';
import ancListBomReducer from './ancList/ancListSlice';
import ConfidentLevelBomReducer from './ancList/confidentLevelSlice';
import safetyMarginReducer from './bottomUp/safetyMarginSlice';
import CostedBomReducer from './model/costedBomSlice';
import CostedModuleBomReducer from './model/costedModuleBomSlice';
import CompareOneToOneModuleBomReducer from './bottomUp/compareOneToOneSlice';
import CompareMultipleModuleBomReducer from './bottomUp/compareMultipleSlice';
import HighLevelMatchingReducer from './projectOverview/highLevelMatchingSlice';
import VolumesReducer from './topDown/volumesSlice';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
};

const reducers = combineReducers({
  authContext: AuthContextReducer,
  userContext: UserReducer,
  projectContext: ProjectContextReducer,
  modelContext: ModelContextReducer,
  generalContext: GeneralContextReducer,
  projectUserContext: ProjectUserReducer,
  analysisContext: AnalysisReducer,
  targetSettingContext: TargetSettingReducer,
  modelViewBOMContext: ModelViewReducer,
  overloadedBOMContext: overloadedBomReducer,
  AncListContext: ancListBomReducer,
  ConfidentLevelContext: ConfidentLevelBomReducer,
  SafetyMarginContext: safetyMarginReducer,
  costedBOMContext: CostedBomReducer,
  costedModuleBomContext: CostedModuleBomReducer,
  compareOneToOneModuleBomContext: CompareOneToOneModuleBomReducer,
  compareMultipleModuleBomContext: CompareMultipleModuleBomReducer,
  highLevelMatchingContext: HighLevelMatchingReducer,
  volumesContext: VolumesReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
