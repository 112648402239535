import { HashRouter, Route, Routes } from 'react-router-dom';
import { RouteItem } from '../../models/route';
import { aRoutes } from '../../route/routes';
import MsalProtectedRoute from '../auth/MsalProtectedRoute';
import MsalRedirectRoute from '../auth/MsalRedirectRoute';

export default function Navigator() {
  function getRouteElement(oRouteItem: RouteItem): JSX.Element {
    const { aSubRoute, Component, sPath, sKey, bProtected } = oRouteItem;
    return (
      <Route
        key={sKey}
        path={sPath}
        element={
          <MsalProtectedRoute bProtected={bProtected}>
            <Component />
          </MsalProtectedRoute>
        }>
        {aSubRoute ? (
          <>
            {aSubRoute.map(subRoute => (
              <Route
                key={subRoute.sKey}
                path={subRoute.sPath}
                element={
                  <MsalProtectedRoute bProtected={bProtected || subRoute.bProtected}>
                    <subRoute.Component />
                  </MsalProtectedRoute>
                }
              />
            ))}
          </>
        ) : null}
      </Route>
    );
  }

  return (
    <HashRouter>
      <MsalRedirectRoute>
        <Routes>{aRoutes.map(oItem => getRouteElement(oItem))}</Routes>
      </MsalRedirectRoute>
    </HashRouter>
  );
}
