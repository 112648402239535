import { Configuration } from '@azure/msal-browser';

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID ?? '',
    authority: process.env.REACT_APP_AZURE_AUTHORITY ?? '',
    redirectUri: process.env.REACT_APP_AZURE_REDIRECT_URI ?? '',
    postLogoutRedirectUri: process.env.REACT_APP_AZURE_POST_LOGOUT_REDIRECT_URI ?? '',
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export const authRequest = {
  scopes: ['openid', 'profile'],
  prompt: 'select_account',
};
