import { useEffect, useState } from 'react';
import { UserModel } from '../../models/users';
import { DropDownKeyCommon, DropDownSelectable } from '../../models/shared';
import { useAppDispatch, useAppSelector } from '../store/customHooks';
import { getUsers } from '../../store/user';
import { RootState } from '../../store';

export type UserListData = {
  readonly aUsers: UserModel[];
  readonly bLoading: boolean;
  readonly bHasError: boolean;
};

export function useUserList(
  oFilters: Partial<Record<DropDownKeyCommon, DropDownSelectable[]>>,
): UserListData {
  const dispatch = useAppDispatch();
  const { aUsers } = useAppSelector((state: RootState) => state.userContext);
  const [aFilteredUsers, setFilteredUsers] = useState<UserModel[]>([]);

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  useEffect(() => {
    let aUserToFilters = aUsers;

    Object.entries(oFilters).forEach(filter => {
      const [key, value] = filter;

      if (key === DropDownKeyCommon.CX_AREA) {
        aUserToFilters = aUserToFilters.filter(user =>
          value.some(item => Number(item.sDropDownID) === user.oCXArea?.iCXAreaId),
        );
      } else if (key === DropDownKeyCommon.BUSINESS_AREA) {
        aUserToFilters = aUserToFilters.filter(user =>
          value.some(item => Number(item.sDropDownID) === user.oBusinessArea?.iBusinessAreaId),
        );
      } else if (key === DropDownKeyCommon.ROLE) {
        aUserToFilters = aUserToFilters.filter(user =>
          value.some(item => Number(item.sDropDownID) === user.oRole?.iRoleId),
        );
      }
    });

    setFilteredUsers(aUserToFilters);
  }, [aUsers, JSON.stringify(oFilters)]);

  return { aUsers: aFilteredUsers, bHasError: false, bLoading: false };
}
