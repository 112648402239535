export const formatContent = (sContent: string, aArgs: string[]): string => {
  const _oRegex: RegExp = /{(\d+)}/g;
  const getReplaceElement = (sText: any, iIndex: number): string =>
    (typeof aArgs[iIndex] !== 'undefined'
      ? encodeURIComponent(aArgs[iIndex])
      : encodeURIComponent(sText)
    )
      .replace('%3D', '=')
      .replace('%26', '&');

  return String(sContent).replace(_oRegex, getReplaceElement);
};

export const numberToLocalString = (iValue: number | string) => Number(iValue).toLocaleString();
