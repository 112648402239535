export enum Path {
  UNMAPPED = '*',
  UNAUTHORIZED = '/unauthorized',
  PROJECTS = '/',
  PROJECT = '/project/:projectId',
  MODEL_MGT = '/project/:projectId/:activeTab',
  MODEL_ModeView_MGT = '/project/:projectId/:activeTab/:activeModel',
  ANALYSIS = '/project/:projectId/analysis/:activeTab',
  SETTINGS = '/settings/:activeTab',
  CURRENCYSETTINGS = '/settings/currency',
  TOPDOWNTABS = '/project/:projectId/top-down/:activeTab',
  BOTTOMUPTABS = '/project/:projectId/bottom-up/:activeTab',
  BOTTOMUPTABSMODELVIEW = '/project/:projectId/bottom-up/:activeTab/:activeModel',
  // BOTTOMUPCOMPAREBYTABS = '/project/:projectId/bottom-up/:activeTab/:activeSubTab',
  ANCLISTTABS = '/project/:projectId/anc-list/:activeTab',
}
