/* eslint no-param-reassign: "error" */

import {
  CompareByModuleAreaBomViewTable,
  CompareByMultipleModuleAreaTableSingleObj,
  ComponentLevel,
  ModuleAreaLevel,
  ModuleLevel,
  TableList,
} from '../../models/bottomUp/compareByMultipleModel';
import { makeid } from '../../utils';

class CompareByMultipleModuleAreaBomService {
  static convertArrayForTreeTable = (aBOMArray: TableList[]) => {
    const sortedArray = [...aBOMArray];

    const finalArray: ModuleAreaLevel[] = [];
    let added = false;

    const getOtherFieldsData = (obj1: any, obj2: any) => {
      // const lObj: any = { ...obj1 };
      const rObj: any = { ...obj2 };

      Object.entries(rObj).forEach(entry => {
        const [key] = entry;

        if (
          key !== 'key' &&
          key !== 'children' &&
          key !== 'sModuleArea' &&
          key !== 'sModule' &&
          key !== 'sComponent'
        ) {
          obj1[key as keyof CompareByMultipleModuleAreaTableSingleObj] =
            obj2[key as keyof CompareByMultipleModuleAreaTableSingleObj];
        }
      });
      // return lObj;
    };

    const clearOtherFieldsData = (obj1: any) => {
      // const lObj: any = { ...obj1 };

      Object.entries(obj1).forEach(entry => {
        const [key] = entry;

        if (
          key !== 'key' &&
          key !== 'children' &&
          key !== 'sModuleArea' &&
          key !== 'sModule' &&
          key !== 'sComponent'
        ) {
          obj1[key as keyof CompareByMultipleModuleAreaTableSingleObj] = '';
        }
      });
      // return lObj;
    };

    const hasOtherField = (moduleArea: any) => {
      let exist: boolean = false;
      Object.entries(moduleArea).forEach(entry => {
        const [key] = entry;

        if (
          key !== 'children' &&
          key !== 'key' &&
          key !== 'sModuleArea' &&
          key !== 'sModule' &&
          key !== 'sComponent'
        ) {
          if (
            moduleArea[key as keyof CompareByMultipleModuleAreaTableSingleObj] ||
            moduleArea[key as keyof CompareByMultipleModuleAreaTableSingleObj] === 0
          ) {
            exist = true;
          }
        }
      });
      return exist;
    };

    for (let int = 0; int < sortedArray.length; ) {
      const bomItem = sortedArray[int];

      for (let x = 0; x < finalArray.length; ) {
        const moduleArea = finalArray[x];

        if (bomItem.sModuleArea === moduleArea.sModuleArea) {
          // incase module area missing
          if (!bomItem.sModule && hasOtherField(moduleArea)) {
            const obj14: ModuleLevel = {
              key: `${bomItem.sModuleArea}${String(x)}-${makeid(4)}-${moduleArea.sModule}_sModule`,
              sModule: moduleArea.sModule || '',
              sComponent: moduleArea.sComponent || '',
              sCluster: moduleArea.sCluster || '',
              children: undefined,
            };

            getOtherFieldsData(obj14, moduleArea);

            clearOtherFieldsData(moduleArea);

            if (!moduleArea.children) moduleArea.children = [];

            moduleArea.children.push(obj14);
            // end

            const obj15: ModuleLevel = {
              key: `${bomItem.sModuleArea}-${bomItem.sModule}-${String(x)}-${makeid(4)}-${
                moduleArea.children.length + 1
              }_sModule`,
              sModule: bomItem.sModule || '',
              sComponent: bomItem.sComponent,
              children: undefined,
            };

            getOtherFieldsData(obj15, bomItem);

            moduleArea.children.push(obj15);
            added = true;
          } else if (!bomItem.sModule) {
            getOtherFieldsData(moduleArea, bomItem);

            if (!moduleArea.children) moduleArea.children = [];

            added = true;
          } else if (bomItem.sModule && !moduleArea.sModule) {
            if (!moduleArea.children) moduleArea.children = [];
          } else if (moduleArea.sModule) {
            const localModelLevel: ModuleLevel = {
              key: `${moduleArea.key}-${moduleArea.sModule}-first`,
              sModule: moduleArea.sModule ? moduleArea.sModule : '',
              sComponent: moduleArea.sComponent,
              children: undefined,
            };

            // if (bomItem.lstModelQuantity)
            //   bomItem.lstModelQuantity.forEach(litem => {
            //     localModelLevel[litem.sModelName as keyof ModuleAreaLevel] = litem.iQuantity;
            //   });
            getOtherFieldsData(localModelLevel, moduleArea);

            moduleArea.children = [];
            moduleArea.children.push(localModelLevel);

            moduleArea.sModule = '';
            moduleArea.sComponent = '';
          }
          if (moduleArea.children && !added) {
            for (let index = 0; index < moduleArea.children.length; ) {
              const modules = moduleArea.children[index];

              if (bomItem.sModule === modules.sModule) {
                // incase component missing
                if (!bomItem.sComponent && hasOtherField(modules)) {
                  // if (modules.sAnc) {
                  // Privous saved data
                  const obj4: ComponentLevel = {
                    key: `${bomItem.sModuleArea}${String(index)}-${makeid(4)}-${
                      modules.sModule
                    }_sGenericComp`,
                    sComponent: modules.sComponent || '',
                    children: undefined,
                  };

                  getOtherFieldsData(obj4, modules);

                  clearOtherFieldsData(modules);

                  if (!modules.children) modules.children = [];

                  modules.children.push(obj4);
                  // end

                  const obj5: ComponentLevel = {
                    key: `${bomItem.sModuleArea}-${bomItem.sModule}-${String(index)}-${makeid(4)}-${
                      moduleArea.children.length + 1
                    }_sGenericComp`,
                    sComponent: bomItem.sComponent || '',
                    children: undefined,
                  };

                  getOtherFieldsData(obj5, bomItem);

                  modules.children.push(obj5);
                  added = true;
                } else if (!bomItem.sComponent) {
                  getOtherFieldsData(modules, bomItem);

                  if (!modules.children) modules.children = [];

                  added = true;
                } else if (bomItem.sComponent && !modules.sComponent) {
                  if (!modules.children) modules.children = [];
                } else if (modules.sComponent || hasOtherField(modules)) {
                  const localGenericLevel: ComponentLevel = {
                    key: bomItem.sModuleArea + bomItem.sModule + modules.sComponent,
                    sComponent: modules.sComponent ? modules.sComponent : '',
                    children: undefined,
                  };

                  getOtherFieldsData(localGenericLevel, modules);

                  if (!modules.children) {
                    modules.children = [];
                  }
                  modules.children.push(localGenericLevel);

                  modules.sComponent = '';
                  clearOtherFieldsData(modules);
                }

                if (modules.children && !added)
                  for (let y = 0; y < modules.children.length; ) {
                    const component = modules.children[y];
                    if (component.sComponent) {
                      if (bomItem.sComponent === component.sComponent) {
                        if (hasOtherField(component)) {
                          const local2GenericLevel: CompareByModuleAreaBomViewTable = {
                            key: `${bomItem.sModuleArea}_${bomItem.sModule}_${
                              bomItem.sComponent
                            }_1_${makeid(4)}`,
                          };

                          //   bomItem.lstModelQuantity.forEach(litem => {
                          //     local2GenericLevel[litem.sModelName as keyof ModuleAreaLevel] =
                          //       component[litem.sModelName as keyof ModuleLevel];
                          //   });

                          getOtherFieldsData(local2GenericLevel, component);
                          clearOtherFieldsData(component);

                          if (!component.children) component.children = [];
                          component.children.push(local2GenericLevel);
                        }
                        const obj3: CompareByModuleAreaBomViewTable = {
                          key: `${component.key}_${
                            component.children ? component.children.length + 1 : makeid(4)
                          }`,
                        };

                        getOtherFieldsData(obj3, bomItem);

                        // bomItem.lstModelQuantity.forEach(item => {
                        //   obj3[item.sModelName as keyof OverloadedBomViewTable] = item.iQuantity;
                        // });

                        if (!component.children) component.children = [];
                        component.children.push(obj3);
                        added = true;
                      }
                    }

                    y += 1;
                  }

                if (!added) {
                  const obj2: ComponentLevel = {
                    key: bomItem.sModuleArea + bomItem.sModule + bomItem.sComponent,
                    sComponent: bomItem.sComponent || '',
                    children: undefined,
                  };

                  //   bomItem.lstModelQuantity.forEach(item => {
                  //     obj2[item.sModelName as keyof ComponentLevel] = item.iQuantity;
                  //   });
                  getOtherFieldsData(obj2, bomItem);

                  if (modules.children) modules.children.push(obj2);

                  added = true;
                }

                break;
              }

              index += 1;
            }
          }

          if (!added) {
            if (bomItem.sModule) {
              if (moduleArea.children) {
                const obj1: ModuleLevel = {
                  key: bomItem.sModuleArea + bomItem.sModule,
                  sModule: bomItem.sModule,
                  sComponent: bomItem.sComponent,
                  children: undefined,
                };

                // if (bomItem.lstModelQuantity)
                //   bomItem.lstModelQuantity.forEach(item => {
                //     obj1[item.sModelName as keyof ModuleLevel] = item.iQuantity;
                //   });
                getOtherFieldsData(obj1, bomItem);

                moduleArea.children.push(obj1);
              }
            }
            added = true;
          }

          break;
        }
        x += 1;
      }

      if (!added) {
        const obj: ModuleAreaLevel = {
          key: bomItem.sModuleArea || '',
          sModuleArea: bomItem.sModuleArea || '',
          sModule: bomItem.sModule,
          sComponent: bomItem.sComponent,
          children: undefined,
        };

        getOtherFieldsData(obj, bomItem);

        finalArray.push(obj);
      }

      added = false;
      int += 1;
    }
    return { finalArray };
  };
}

export default CompareByMultipleModuleAreaBomService;
