import { AsyncThunk, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ConfidentLevelBOMModel, GetConfidentLevelRequest } from '../../models/ancList';
import AncListService from '../../services/ancList/ancListService';
import { AppStatus } from '../generalTypes';

export interface ConfidentLevelContextState {
  sConfidentLevelBomStatus: AppStatus;
  oConfidentLevelBomObj: ConfidentLevelBOMModel[];
}

const oInitialState: ConfidentLevelContextState = {
  sConfidentLevelBomStatus: 'IDLE',
  oConfidentLevelBomObj: {} as ConfidentLevelBOMModel[],
};

const onStateChanging = (
  oState: ConfidentLevelContextState,
  oNewObj: Partial<ConfidentLevelContextState>,
) => ({ ...oState, ...oNewObj } as ConfidentLevelContextState);

const onSuccessConfidentLevelBOM = (
  oState: ConfidentLevelContextState,
  oConfidentLevelBomObj: ConfidentLevelBOMModel[],
) =>
  onStateChanging(oState, {
    sConfidentLevelBomStatus: 'SUCCEEDED',
    oConfidentLevelBomObj,
  });

const onStartConfidentLevelBOM = (oState: ConfidentLevelContextState) =>
  onStateChanging(oState, { sConfidentLevelBomStatus: 'LOADING' });
const onErrorConfidentLevelBOM = (oState: ConfidentLevelContextState) =>
  onStateChanging(oState, { sConfidentLevelBomStatus: 'FAILED' });

const getConfidentLevelBOM: AsyncThunk<ConfidentLevelBOMModel[], GetConfidentLevelRequest, {}> =
  createAsyncThunk('getConfidentLevel', async () => {
    const response = await AncListService.getConidentLevel();
    return response;
  });

export const ConfidentLevelContext = createSlice({
  name: 'confidentLevelContext',
  initialState: oInitialState,
  reducers: {},
  extraReducers: oBuilder => {
    oBuilder
      // get bom for model view
      .addCase(getConfidentLevelBOM.pending, onStartConfidentLevelBOM)
      .addCase(getConfidentLevelBOM.fulfilled, (oState: ConfidentLevelContextState, oAction) =>
        onSuccessConfidentLevelBOM(oState, oAction.payload),
      )
      .addCase(getConfidentLevelBOM.rejected, onErrorConfidentLevelBOM);
  },
});

export { getConfidentLevelBOM };
export default ConfidentLevelContext.reducer;
