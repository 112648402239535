import { GetOverloadedBOMRequest, OverloadedBOMModel } from '../../models/bottomUp';
import API_URL from '../apiUtils';
import httpService from '../httpService';

class OverloadedService {
  static getOverloadedBom = async (aData: GetOverloadedBOMRequest) =>
    httpService.getJson<OverloadedBOMModel>(API_URL.PROJECT.MODEL.OVERLOADED_BOM_V2, [
      aData.sProjectId,
      aData.iNextCheckpointId.toString(),
      aData.lstModelIds,
    ]);
}

export default OverloadedService;
