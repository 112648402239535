import { AsyncThunk, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  ModuleAreaTargetSettingsDetailModel,
  ModuleAreaTargetSettingsRequest,
  TargetSettingResponse,
  TargetSettingsDetailModel,
  TargetSettingsRequest,
  TargetSubmitSettingsRequest,
} from '../../models/targetSettings';
import { AppStatus } from '../generalTypes';
import { TargetSettingService } from '../../services/model';

export interface TargetSettingContextState {
  sModuleTargetSettingStatus: AppStatus;
  sModuleAreaTargetSettingStatus: AppStatus;
  aTargetModels: TargetSettingsDetailModel;
  aModuleAreaTargetModels: ModuleAreaTargetSettingsDetailModel;
  onSubmitTargetSettingStatus: AppStatus;
}

const onStateChanging = (
  oState: TargetSettingContextState,
  oNewObj: Partial<TargetSettingContextState>,
) => ({ ...oState, ...oNewObj } as TargetSettingContextState);

const onStartTargetSetting = (oState: TargetSettingContextState) =>
  onStateChanging(oState, { sModuleTargetSettingStatus: 'LOADING' });
const onErrorTargetSetting = (oState: TargetSettingContextState) =>
  onStateChanging(oState, { sModuleTargetSettingStatus: 'FAILED' });

const onStartModuleAreaTargetSetting = (oState: TargetSettingContextState) =>
  onStateChanging(oState, { sModuleAreaTargetSettingStatus: 'LOADING' });
const onErrorModuleAreaTargetSetting = (oState: TargetSettingContextState) =>
  onStateChanging(oState, { sModuleAreaTargetSettingStatus: 'FAILED' });

const oInitialState: TargetSettingContextState = {
  sModuleTargetSettingStatus: 'IDLE',
  sModuleAreaTargetSettingStatus: 'IDLE',
  onSubmitTargetSettingStatus: 'IDLE',
  aTargetModels: {} as TargetSettingsDetailModel,
  aModuleAreaTargetModels: {} as ModuleAreaTargetSettingsDetailModel,
};

const getTargetSetting: AsyncThunk<TargetSettingsDetailModel, string[], {}> = createAsyncThunk(
  'getTargetSetting',
  async (oData: string[]) => {
    const response = await TargetSettingService.getTargetSettings(oData);
    return response;
  },
);

const getModuleAreaTargetSetting: AsyncThunk<ModuleAreaTargetSettingsDetailModel, string[], {}> =
  createAsyncThunk('getModuleAreaTargetSetting', async (oData: string[]) => {
    const response = await TargetSettingService.getModuleAreaTargetSettings(oData);
    return response;
  });

const onSuccessTargetSetting = (
  oState: TargetSettingContextState,
  aTargetModels: TargetSettingsDetailModel,
) =>
  onStateChanging(oState, {
    sModuleTargetSettingStatus: 'SUCCEEDED',
    aTargetModels,
  });

const onSuccessModuleAreaTargetSetting = (
  oState: TargetSettingContextState,
  aModuleAreaTargetModels: ModuleAreaTargetSettingsDetailModel,
) =>
  onStateChanging(oState, {
    sModuleAreaTargetSettingStatus: 'SUCCEEDED',
    aModuleAreaTargetModels,
  });

const onSaveSuccessModuleAreaTargetSetting = (oState: TargetSettingContextState) =>
  onStateChanging(oState, {
    sModuleAreaTargetSettingStatus: 'SUCCEEDED',
  });

const saveTargetSetting: AsyncThunk<TargetSettingsDetailModel, TargetSettingsRequest, {}> =
  createAsyncThunk('saveTargetSettings', async (oData: TargetSettingsRequest) => {
    const response = await TargetSettingService.saveTargetSetting(oData);
    return response;
  });

const submitTargetSetting: AsyncThunk<TargetSettingResponse, TargetSubmitSettingsRequest, {}> =
  createAsyncThunk('submitTargetSettings', async (oData: TargetSubmitSettingsRequest) => {
    const response = await TargetSettingService.submitTargetSetting(oData);
    return response;
  });

const onSuccessSubmitTargetSetting = (oState: TargetSettingContextState) =>
  onStateChanging(oState, {
    onSubmitTargetSettingStatus: 'SUCCEEDED',
  });

const onStartSubmitTargetSetting = (oState: TargetSettingContextState) =>
  onStateChanging(oState, { onSubmitTargetSettingStatus: 'LOADING' });
const onErrorSubmitTargetSetting = (oState: TargetSettingContextState) =>
  onStateChanging(oState, { onSubmitTargetSettingStatus: 'FAILED' });

const saveModuleAreaTargetSetting: AsyncThunk<
  ModuleAreaTargetSettingsDetailModel,
  ModuleAreaTargetSettingsRequest,
  {}
> = createAsyncThunk(
  'saveModuleAreaTargetSettings',
  async (oData: ModuleAreaTargetSettingsRequest) => {
    const response = await TargetSettingService.saveModuleAreaTargetSetting(oData);
    return response;
  },
);

export const TargetSettingContext = createSlice({
  name: 'targetSettingContext',
  initialState: oInitialState,
  reducers: {
    resetTargetSettingData(state) {
      Object.assign(state, oInitialState);
    },
  },
  extraReducers: oBuilder => {
    oBuilder
      // target settings section
      .addCase(getTargetSetting.pending, onStartTargetSetting)
      .addCase(getTargetSetting.fulfilled, (oState: TargetSettingContextState, oAction) =>
        onSuccessTargetSetting(oState, oAction.payload),
      )
      .addCase(getTargetSetting.rejected, onErrorTargetSetting)

      // module area settings section
      .addCase(getModuleAreaTargetSetting.pending, onStartModuleAreaTargetSetting)
      .addCase(getModuleAreaTargetSetting.fulfilled, (oState: TargetSettingContextState, oAction) =>
        onSuccessModuleAreaTargetSetting(oState, oAction.payload),
      )
      .addCase(getModuleAreaTargetSetting.rejected, onErrorModuleAreaTargetSetting)

      // create target save section
      .addCase(saveTargetSetting.pending, onStartTargetSetting)
      .addCase(saveTargetSetting.fulfilled, (oState: TargetSettingContextState, oAction) =>
        onSuccessTargetSetting(oState, oAction.payload),
      )
      .addCase(saveTargetSetting.rejected, onErrorTargetSetting)

      // create module area target save section
      .addCase(saveModuleAreaTargetSetting.pending, onStartModuleAreaTargetSetting)
      .addCase(saveModuleAreaTargetSetting.fulfilled, (oState: TargetSettingContextState) =>
        onSaveSuccessModuleAreaTargetSetting(oState),
      )
      .addCase(saveModuleAreaTargetSetting.rejected, onErrorModuleAreaTargetSetting)

      // submit target save section
      .addCase(submitTargetSetting.pending, onStartSubmitTargetSetting)
      .addCase(submitTargetSetting.fulfilled, (oState: TargetSettingContextState) =>
        onSuccessSubmitTargetSetting(oState),
      )
      .addCase(submitTargetSetting.rejected, onErrorSubmitTargetSetting);
  },
});

export {
  getTargetSetting,
  getModuleAreaTargetSetting,
  saveTargetSetting,
  saveModuleAreaTargetSetting,
  submitTargetSetting,
};
export const { resetTargetSettingData } = TargetSettingContext.actions;
export default TargetSettingContext.reducer;
