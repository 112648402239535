import { Participant, ProjectUserListModel, ProjectUserModel } from '../../models/settings';
import { SaveUserRequest, UserModelList } from '../../models/users';
import { DeleteUserRequest } from '../../models/users/deleteUserRequest';
import API_URL from '../apiUtils';
import HttpService, { API_METHOD } from '../httpService';

class UserService {
  static getParticipants = async (sProjectId: string) =>
    HttpService.getJson<ProjectUserListModel>(API_URL.USER.PROJECT.PARTICIPANTS, [sProjectId]);

  static searchUsers = async (sProjectId: string, sSearchText: string) =>
    HttpService.getJson<UserModelList>(API_URL.USER.SEARCH_USER, [sProjectId, sSearchText]);

  static saveParticipant = async (oData: ProjectUserModel) =>
    HttpService.sendJson<Participant>(API_URL.USER.PROJECT.PARTICIPANTS, oData, [oData.sProjectId]);

  static updateParticipant = async (oData: ProjectUserModel) =>
    HttpService.sendJson<Participant>(API_URL.USER.PROJECT.EDIT_PARTECIPANT, oData, [
      oData.sProjectId,
      oData.sUserId,
    ]);

  static deleteParticipant = async (oData: ProjectUserModel) =>
    HttpService.sendJson<boolean>(
      API_URL.USER.PROJECT.EDIT_PARTECIPANT,
      undefined,
      [oData.sProjectId, oData.sUserId],
      true,
      API_METHOD.DELETE,
    );

  static getUsers = async () => HttpService.getJson<UserModelList>(API_URL.USER.LIST);

  static saveUser = async <T>(oData: SaveUserRequest | DeleteUserRequest) =>
    HttpService.sendJson<T>(API_URL.USER.LIST, oData);
}

export default UserService;
