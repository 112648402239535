import { useEffect } from 'react';
import { ProjectDetailModel } from '../../models/projectOverview/projectDetailModel';
import { getProjectDetail } from '../../store/project';
import { useAppDispatch, useAppSelector } from '../store/customHooks';
import { RootState } from '../../store';
import { setSelectedCurrency } from '../../store/settings/projects';

export type ProjectDetailData = {
  readonly oProjectDetail: ProjectDetailModel | null;
  readonly bDetailLoading: boolean;
  readonly bHasError: boolean;
};

export function useProjectDetail(sProjectId: string): ProjectDetailData {
  const dispatch = useAppDispatch();
  const { oProjectDetail, sStatus } = useAppSelector((state: RootState) => state.projectContext);
  const { sSelectedCurrency } = useAppSelector(state => state.projectUserContext);
  useEffect(() => {
    if (sProjectId) dispatch(getProjectDetail(sProjectId));
  }, []);

  useEffect(() => {
    if (oProjectDetail?.sCurrency) {
      const oDropdowns = [
        {
          sDropDownID: 'EUR',
          sDropDrownName: 'EUR',
          bSelected: !sSelectedCurrency
            ? oProjectDetail?.sCurrency === 'EUR'
            : sSelectedCurrency === 'EUR',
        },
        {
          sDropDownID: 'SEK',
          sDropDrownName: 'SEK',
          bSelected: !sSelectedCurrency
            ? oProjectDetail?.sCurrency === 'SEK'
            : sSelectedCurrency === 'SEK',
        },
        {
          sDropDownID: 'USD',
          sDropDrownName: 'USD',
          bSelected: !sSelectedCurrency
            ? oProjectDetail?.sCurrency === 'USD'
            : sSelectedCurrency === 'USD',
        },
      ];

      oDropdowns.forEach(item => {
        if (item.bSelected) {
          dispatch(setSelectedCurrency(item.sDropDownID));
        }
      });
    }
  }, [JSON.stringify(oProjectDetail)]);

  if (sStatus === 'LOADING') {
    return { oProjectDetail, bHasError: false, bDetailLoading: true };
  }

  if (sStatus === 'FAILED') {
    return { oProjectDetail, bHasError: true, bDetailLoading: false };
  }

  if (sStatus === 'SUCCEEDED') {
    return { oProjectDetail, bHasError: false, bDetailLoading: false };
  }

  return { oProjectDetail, bHasError: false, bDetailLoading: true };
}
