import { ChartModelRequest } from '../../models/analysis/chartModelRequest';
import { ChartRequest } from '../../models/analysis/chartRequest';
import { ModuleAreaCostImplication } from '../../models/analysis/moduleAreaCostImplication';
import { DeviationsByModuleArea } from '../../models/projectManagement/deviationsByModuleArea';
import {
  CostImplicationByModel,
  ProjectCheckpointsModel,
  TopDownVsBottomUpRequest,
} from '../../models/projectOverview';
import { DeviationsByModel } from '../../models/projectOverview/deviationByModel';
import { DeviationsModuleArea } from '../../models/projectOverview/largestDeviationsModuleArea';
import { ProjectDetailModel } from '../../models/projectOverview/projectDetailModel';
import { YearlyVolume } from '../../models/projectOverview/yearlyVolume';
import { ProjectList, ProjectRefresh } from '../../models/projects';
import API_URL from '../apiUtils';
import HttpService from '../httpService';

class ProjectService {
  static getProjects = async () => HttpService.getJson<ProjectList>(API_URL.PROJECT.LIST);

  static refreshUpdateTime = async (sProjectId: string) =>
    HttpService.sendJson<ProjectRefresh>(
      API_URL.PROJECT.REFRESH_UPDATE_TIME,
      {
        sProjectId,
      },
      [sProjectId],
    );

  static getWorkflowDetail = async (sData: string) =>
    HttpService.getJson<ProjectCheckpointsModel>(API_URL.PROJECT.CHECKPOINTS, [sData]);

  static getProjectDetail = async (sData: string) =>
    HttpService.getJson<ProjectDetailModel>(API_URL.PROJECT.DETAIL, [sData]);

  static getEditableProjects = async () =>
    HttpService.getJson<ProjectList>(API_URL.PROJECT.EDITABLE_LIST);

  static retrieveLargestDeviationsByModuleArea = async (oData: ChartRequest) =>
    HttpService.getJson<DeviationsModuleArea[]>(API_URL.PROJECT.DEVIATIONS_MODULE_AREA, [
      oData.iProjectId,
      oData.iNextCheckpointId.toString(),
      oData.sCurrencyCode,
    ]);

  static retrieveStk1DeviationByModel = async (oData: ChartRequest) =>
    HttpService.getJson<DeviationsByModel[]>(API_URL.PROJECT.STK1_DEVIATION_BY_MODEL, [
      oData.iProjectId,
      oData.iNextCheckpointId.toString(),
      oData.sCurrencyCode,
    ]);

  static retrieveStk1DeviationByModuleArea = async (oData: ChartModelRequest) =>
    HttpService.getJson<DeviationsByModuleArea[]>(API_URL.PROJECT.STK1_DEVIATION_BY_MODULE_AREA, [
      oData.iProjectId,
      oData.iNextCheckpointId.toString(),
      oData.iModelId.toString(),
      oData.sCurrencyCode,
    ]);

  static retrieveModuleAreaCostImplication = async (oData: ChartModelRequest) =>
    HttpService.getJson<ModuleAreaCostImplication[]>(API_URL.PROJECT.MODULE_AREA_COST_IMPLICATION, [
      oData.iProjectId,
      oData.iModelId.toString(),
      oData.iNextCheckpointId.toString(),
      oData.sCurrencyCode,
    ]);

  static retrieveYearlyVolume = async (oData: TopDownVsBottomUpRequest) =>
    HttpService.getJson<YearlyVolume[]>(API_URL.PROJECT.YEARLY_VOLUME, [
      oData.sProjectId,
      oData.iYear,
      oData.lstModelIds.map(value => value).join(','),
      oData.sCurrencyCode,
      oData.sNextCheckpointId || '',
    ]);

  static retrieveTotalCostImplicationByTrackModel = async (oData: TopDownVsBottomUpRequest) =>
    HttpService.getJson<CostImplicationByModel[]>(API_URL.PROJECT.COST_IMPLICATION_BY_MODEL, [
      oData.sProjectId,
      oData.iYear,
      oData.lstModelIds.map(value => value).join(','),
      oData.sCurrencyCode,
      oData.sNextCheckpointId || '',
    ]);
}

export default ProjectService;
