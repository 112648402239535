import { useEffect, useState } from 'react';
import { ProjectListItem } from '../../models/projects';
import { DropDownKeyCommon, DropDownSelectable } from '../../models/shared';
import { RootState } from '../../store';
import { getEditableProjects, getProjects } from '../../store/project';
import { formatDateInMonth } from '../../utils';
import { useAppDispatch, useAppSelector } from '../store/customHooks';

export type ProjectListData = {
  readonly aProjects: ProjectListItem[];
  readonly bLoading: boolean;
  readonly bHasError: boolean;
};

export function useProjectList(
  sType: 'MINE' | 'ALL',
  oFilters: Partial<Record<DropDownKeyCommon, DropDownSelectable[]>>,
  bDispatchApi?: boolean,
): ProjectListData {
  const dispatch = useAppDispatch();
  const { aProjects, sStatus } = useAppSelector((state: RootState) => state.projectContext);
  const [aFilteredProjects, setFilteredProjects] = useState<ProjectListItem[]>([]);

  useEffect(() => {
    if (bDispatchApi) {
      dispatch(getProjects());
    }
  }, []);

  useEffect(() => {
    let aProjectToFilters = aProjects;

    if (sType === 'MINE') {
      aProjectToFilters = aProjects.filter(project => project.bEditable);
    }

    Object.entries(oFilters).forEach(filter => {
      const [key, value] = filter;
      if (key === DropDownKeyCommon.CX_AREA) {
        aProjectToFilters = aProjectToFilters.filter(project =>
          value.some(item => Number(item.sDropDownID) === project.oCXArea.iCXAreaId),
        );
      } else if (key === DropDownKeyCommon.BUSINESS_AREA) {
        aProjectToFilters = aProjectToFilters.filter(project =>
          value.some(item => Number(item.sDropDownID) === project.oBusinessArea.iBusinessAreaId),
        );
      } else if (key === DropDownKeyCommon.CHECKPOINT_NAME) {
        aProjectToFilters = aProjectToFilters.filter(project =>
          value.some(item => item.sDropDownID === project.sNextCheckPoint),
        );
      } else if (key === DropDownKeyCommon.CHECKPOINT_DATE) {
        aProjectToFilters = aProjectToFilters.filter(project =>
          value.some(
            item => item.sDropDownID === formatDateInMonth(new Date(project.sCheckpointDate))[0],
          ),
        );
      } else if (key === DropDownKeyCommon.STATUS) {
        aProjectToFilters = aProjectToFilters.filter(project =>
          value.some(item => Number(item.sDropDownID) === project.oStatus.iStatusId),
        );
      } else if (key === DropDownKeyCommon.PROJECT_CONDITION) {
        aProjectToFilters = aProjectToFilters.filter(project =>
          value.some(item => item.sDropDownID === project.iCondition.toString()),
        );
      } else if (key === DropDownKeyCommon.COST_MANAGER_LEAD) {
        aProjectToFilters = aProjectToFilters.filter(project =>
          value.some(item => item.sDropDownID === project.oCostManagerLead.sUserId),
        );
      }
    });

    setFilteredProjects(aProjectToFilters);
  }, [aProjects, JSON.stringify(oFilters)]);

  if (sStatus === 'LOADING') {
    return { aProjects: aFilteredProjects, bHasError: false, bLoading: true };
  }

  if (sStatus === 'FAILED') {
    return { aProjects: aFilteredProjects, bHasError: true, bLoading: false };
  }

  return { aProjects: aFilteredProjects, bHasError: false, bLoading: false };
}

export function useCurrentProjectCheckpointView(): boolean {
  const { oSelectedCheckpoint } = useAppSelector((state: RootState) => state.projectUserContext);
  const sCurrentCheckpointId = useAppSelector(
    (state: RootState) => state.projectContext.oProjectDetail?.sNextCheckPointId,
  );

  const currentCheckpointSelected =
    parseInt(String(sCurrentCheckpointId), 10) === oSelectedCheckpoint?.iCheckpointId;
  return currentCheckpointSelected;
}

export function useCheckpointBetweenDSandCPPF(): boolean {
  const sNextCheckpointId = useAppSelector(
    (state: RootState) => state.projectContext.oProjectDetail!.sNextCheckPointId,
  );
  const { oProjectCheckpoints } = useAppSelector((state: RootState) => state.projectContext);
  const [bCheckpointBetweenDSandCPPF, setCheckpointBetweenDSandCPPF] = useState<boolean>(false);
  const oCurrentCheckpoint = oProjectCheckpoints?.lstCheckpoints.find(
    checkpoint => checkpoint.iCheckpointId === parseInt(sNextCheckpointId, 10) - 1,
  );
  const oNextCheckpoint = oProjectCheckpoints?.lstCheckpoints.find(
    checkpoint => checkpoint.iCheckpointId === parseInt(sNextCheckpointId, 10),
  );

  useEffect(() => {
    setCheckpointBetweenDSandCPPF(
      oCurrentCheckpoint?.sCheckpointName === 'DS' && oNextCheckpoint?.sCheckpointName === 'CPPF',
    );
  }, [oProjectCheckpoints?.iProjectId]);

  return bCheckpointBetweenDSandCPPF;
}

export function isProjectBeforeCPPF(): boolean {
  const sNextCheckpointId = useAppSelector(
    (state: RootState) => state.projectContext.oProjectDetail!.sNextCheckPointId,
  );
  const { oProjectCheckpoints } = useAppSelector((state: RootState) => state.projectContext);
  const [bBefore, setBefore] = useState<boolean>(false);
  const iCPPFId = oProjectCheckpoints?.lstCheckpoints.find(
    checkpoint => checkpoint.sCheckpointName === 'CPPF',
  )?.iCheckpointId;

  useEffect(() => {
    if (iCPPFId) {
      setBefore(parseInt(sNextCheckpointId, 10) < iCPPFId);
    }
  }, [sNextCheckpointId]);

  return bBefore;
}

export function isProjectAfterCP00(): boolean {
  const sNextCheckpointId = useAppSelector(
    (state: RootState) => state.projectContext.oProjectDetail!.sNextCheckPointId,
  );
  const { oProjectCheckpoints } = useAppSelector((state: RootState) => state.projectContext);
  const [bAfter, setAfter] = useState<boolean>(false);
  const iCPPFId = oProjectCheckpoints?.lstCheckpoints.find(
    checkpoint => checkpoint.sCheckpointName === 'CP00',
  )?.iCheckpointId;

  useEffect(() => {
    if (iCPPFId) {
      setAfter(parseInt(sNextCheckpointId, 10) > iCPPFId);
    }
  }, [sNextCheckpointId]);

  return bAfter;
}

export function useAdminProjects(
  oFilters: Partial<Record<DropDownKeyCommon, DropDownSelectable[]>>,
): ProjectListData {
  const dispatch = useAppDispatch();
  const { aEditableProjects, sStatus } = useAppSelector((state: RootState) => state.projectContext);
  const [aFilteredProjects, setFilteredProjects] = useState<ProjectListItem[]>([]);

  useEffect(() => {
    dispatch(getEditableProjects());
  }, []);

  useEffect(() => {
    let aProjectToFilters = aEditableProjects;

    Object.entries(oFilters).forEach(filter => {
      const [key, value] = filter;
      if (key === DropDownKeyCommon.CX_AREA) {
        aProjectToFilters = aProjectToFilters.filter(project =>
          value.some(item => Number(item.sDropDownID) === project.oCXArea.iCXAreaId),
        );
      } else if (key === DropDownKeyCommon.BUSINESS_AREA) {
        aProjectToFilters = aProjectToFilters.filter(project =>
          value.some(item => Number(item.sDropDownID) === project.oBusinessArea.iBusinessAreaId),
        );
      } else if (key === DropDownKeyCommon.CHECKPOINT_NAME) {
        aProjectToFilters = aProjectToFilters.filter(project =>
          value.some(item => item.sDropDownID === project.sNextCheckPoint),
        );
      } else if (key === DropDownKeyCommon.COST_MANAGER_LEAD) {
        aProjectToFilters = aProjectToFilters.filter(project =>
          value.some(item => item.sDropDownID === project.oCostManagerLead.sUserId),
        );
      } else if (key === DropDownKeyCommon.CHECKPOINT_DATE) {
        aProjectToFilters = aProjectToFilters.filter(project =>
          value.some(
            item => item.sDropDownID === formatDateInMonth(new Date(project.sCheckpointDate))[0],
          ),
        );
      }
    });

    setFilteredProjects(aProjectToFilters);
  }, [aEditableProjects, JSON.stringify(oFilters)]);

  if (sStatus === 'LOADING') {
    return { aProjects: aFilteredProjects, bHasError: false, bLoading: true };
  }

  if (sStatus === 'FAILED') {
    return { aProjects: aFilteredProjects, bHasError: true, bLoading: false };
  }

  return { aProjects: aFilteredProjects, bHasError: false, bLoading: false };
}
