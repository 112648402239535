import { AsyncThunk, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  CompareOneToOneBOMModel,
  CompareOneToOneRequest,
} from '../../models/bottomUp/compareOneToOneModel';
import CompareService from '../../services/bottomUp/compareService';
import { AppStatus } from '../generalTypes';

export interface CompareOneToOneContextState {
  sCompareOneToOneStatus: AppStatus;
  oCompareOneToOneObj: CompareOneToOneBOMModel;
}

const oInitialState: CompareOneToOneContextState = {
  sCompareOneToOneStatus: 'IDLE',
  oCompareOneToOneObj: {} as CompareOneToOneBOMModel,
};

const onStateChanging = (
  oState: CompareOneToOneContextState,
  oNewObj: Partial<CompareOneToOneContextState>,
) => ({ ...oState, ...oNewObj } as CompareOneToOneContextState);

const onSuccessCompareOneToOneBOM = (
  oState: CompareOneToOneContextState,
  oCompareOneToOneObj: CompareOneToOneBOMModel,
) =>
  onStateChanging(oState, {
    sCompareOneToOneStatus: 'SUCCEEDED',
    oCompareOneToOneObj,
  });

const onStartCompareOneToOneBOM = (oState: CompareOneToOneContextState) =>
  onStateChanging(oState, { sCompareOneToOneStatus: 'LOADING' });
const onErrorCompareOneToOneBOM = (oState: CompareOneToOneContextState) =>
  onStateChanging(oState, { sCompareOneToOneStatus: 'FAILED' });

const getCompareOneToOneBOM: AsyncThunk<CompareOneToOneBOMModel, CompareOneToOneRequest, {}> =
  createAsyncThunk('getCompareOneByOneBOM', async (oData: CompareOneToOneRequest) => {
    const response = await CompareService.getCompareOneToOneBom(oData);
    return response;
  });

export const CompareOneToOneContext = createSlice({
  name: 'compareOneByOneContext',
  initialState: oInitialState,
  reducers: {
    clearCompareOneToOneData: () => oInitialState,
  },
  extraReducers: oBuilder => {
    oBuilder
      // get bom for model view
      .addCase(getCompareOneToOneBOM.pending, onStartCompareOneToOneBOM)
      .addCase(getCompareOneToOneBOM.fulfilled, (oState: CompareOneToOneContextState, oAction) =>
        onSuccessCompareOneToOneBOM(oState, oAction.payload),
      )
      .addCase(getCompareOneToOneBOM.rejected, onErrorCompareOneToOneBOM);
  },
});

export { getCompareOneToOneBOM };
export const { clearCompareOneToOneData } = CompareOneToOneContext.actions;
export default CompareOneToOneContext.reducer;
