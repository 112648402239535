import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';

type MsalProtectedRouteProps = {
  children: JSX.Element;
  bProtected: boolean;
};

export default function MsalProtectedRoute({ children, bProtected }: MsalProtectedRouteProps) {
  const Component = bProtected ? AuthenticatedTemplate : UnauthenticatedTemplate;
  return <Component>{children}</Component>;
}
