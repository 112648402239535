import { EventMessage, EventType, InteractionType } from '@azure/msal-browser';
import { useMsal, useMsalAuthentication } from '@azure/msal-react';
import { useEffect } from 'react';
import { useAppDispatch } from '../../hooks';

import { authorize, resetLogin } from '../../store/login/authContextSlice';
import { clearProjectDetailStore } from '../../store/project';
import { resetUserData } from '../../store/user';
import { UserPermissions } from '../../utils';
import WithPermissionAndFallback from '../shared/WithPermissionAndFallback';
import { MsalError } from './MsalError';

type MsalRedirectRouteProps = {
  children: JSX.Element;
};

export default function MsalRedirectRoute({ children }: MsalRedirectRouteProps) {
  const msal = useMsal();
  const dispatch = useAppDispatch();
  const { error } = useMsalAuthentication(InteractionType.Redirect);

  function dispatchAuthorization() {
    dispatch(authorize())
      .unwrap()
      .then(x => console.log('PROFILE DONE', x));
  }

  useEffect(() => {
    const callbackId = msal.instance.addEventCallback((message: EventMessage) => {
      // Update UI or interact with EventMessage here
      if (
        message.eventType === EventType.LOGIN_FAILURE ||
        message.eventType === EventType.LOGIN_START ||
        message.eventType === EventType.LOGOUT_END ||
        message.eventType === EventType.LOGOUT_SUCCESS
      ) {
        dispatch(resetLogin());
        dispatch(clearProjectDetailStore());
        dispatch(resetUserData());
      }

      if (
        message.eventType === EventType.LOGIN_SUCCESS ||
        message.eventType === EventType.HANDLE_REDIRECT_END
      ) {
        dispatchAuthorization();
      }
    });

    return () => {
      msal.instance.removeEventCallback(callbackId!);
    };
  }, []);

  if (error) {
    return <MsalError error={error} />;
  }

  return (
    <WithPermissionAndFallback
      lstPermission={[UserPermissions.APPLICATION_ACCESS]}
      fallback={<MsalError />}>
      {children}
    </WithPermissionAndFallback>
  );
}
