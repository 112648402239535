import { lazy } from 'react';
import { RouteItem } from '../models/route';
import { Path } from './localPath';

export const aRoutes: RouteItem[] = [
  {
    sKey: 'Route_Unmapped',
    sPath: Path.UNMAPPED,
    bProtected: false,
    Component: lazy(() => import('../container/pageNotExist/PageNotExist')),
  },
  {
    sKey: 'Route_Projects_List',
    sPath: Path.PROJECTS,
    bProtected: true,
    Component: lazy(() => import('../container/projects/ProjectsListContainer')),
  },
  {
    sKey: 'Route_Project_Overview',
    sPath: Path.PROJECT,
    bProtected: true,
    Component: lazy(() => import('../container/projectOverview/ProjectOverviewContainer')),
  },
  {
    sKey: 'Route_Settings',
    sPath: Path.SETTINGS,
    bProtected: true,
    Component: lazy(() => import('../container/settings/SettingsContainer')),
  },
  {
    sKey: 'Route_Settings_Currency',
    sPath: Path.CURRENCYSETTINGS,
    bProtected: true,
    Component: lazy(() => import('../container/settings/CurrencyContainer')),
  },
  {
    sKey: 'Route_Top_Down_Tabs',
    sPath: Path.TOPDOWNTABS,
    bProtected: true,
    Component: lazy(() => import('../container/topDown/TopDownContainer')),
  },
  {
    sKey: 'Route_Bottom_Up_Tabs',
    sPath: Path.BOTTOMUPTABS,
    bProtected: true,
    Component: lazy(() => import('../container/bottomUp/BottomUpContainer')),
  },
  {
    sKey: 'Route_Bottom_Up_Model_View',
    sPath: Path.BOTTOMUPTABSMODELVIEW,
    bProtected: true,
    Component: lazy(() => import('../container/bottomUp/BottomUpContainer')),
  },
  {
    sKey: 'Route_Anc_List_Tabs',
    sPath: Path.ANCLISTTABS,
    bProtected: true,
    Component: lazy(() => import('../container/ancList/AncListContainer')),
  },

  {
    sKey: 'Route_Analysis',
    sPath: Path.ANALYSIS,
    bProtected: true,
    Component: lazy(() => import('../container/analysis/AnalysisContainer')),
  },
];
