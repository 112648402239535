export enum DropDownKeyCommon {
  CX_AREA = 'CXArea',
  BUSINESS_AREA = 'BusinessArea',
  CHECKPOINT_NAME = 'CheckPointName',
  CHECKPOINT_DATE = 'CheckPointDate',
  STATUS = 'Status',
  PROJECT_CONDITION = 'PrjCondition',
  COST_MANAGER_LEAD = 'CostManagerLead',
  ROLE = 'Role',
  PLANT = 'Plant',
  LOCAL = 'Local',
  UNIT_OF_MEASURE = 'UnitOfMeasure',
  COST_TYPE = 'CostType',
}

export enum DropDownKeyProject {
  PNC = 'PNC',
  CURRENCY = 'Currency',
  APPROACH = 'Approach',
  MATERIAL_TYPE = 'MaterialType',
  R_AND_D = 'RandD',
  PURCHASING = 'Purchasing',
  MODELING = 'Modeling',
}

export enum DropDownKeyModel {
  SUPPLIER = 'Supplier',
  IDCO = 'Idco',
  MODULE_AREA = 'ModuleArea',
  MODULE = 'Module',
  COMPONENT = 'Component',
}

export type DropDownKey = DropDownKeyCommon | DropDownKeyProject | DropDownKeyModel;
export type DropDownKeyMultiple = DropDownKeyCommon[] | DropDownKeyProject[] | DropDownKeyModel[];

export enum DropDownDataType {
  COMMON,
  PROJECT,
  MODEL,
  MULTI_MODEL,
  LOCAL,
  ANCLIST,
}

export interface ModelViewDropDown {
  dropDown: DropDownKeyMultiple;
  _sPrjKey: string;
}

export interface ProjectModelInfo {
  iProjectId: Number;
  sNextCheckPointId: string;
  iModelId: number;
  iVersionId: number;
  sTypeVers: string;
}

export interface DefaultOptionItem {
  iDdropDownItem: number;
  sPostfixString: string;
  sDropDownType: string;
}

export interface DropDown {
  sDropDownID: string;
  sDropDrownName: string;
  sPostFix?: DefaultOptionItem;
}

export interface DropDownSelectable extends DropDown {
  bSelected: boolean;
}

export interface DefaultDropDownSelectable extends DropDown {
  bSelected: boolean;
  sKey: DropDownKeyCommon | DropDownKeyProject | DropDownKeyModel;
  bDefaultOpion?: DefaultOptionItem;
}

export interface DropDownLocalList {
  key: string;
  value: string;
}
