import moment from 'moment';
import { ModelVersion } from '../models/model';

export const convertToDateTime = (datetime: string) => {
  const date = moment(datetime);
  return date.format('L LT');
};

export const convertToDate = (datetime: string) => {
  const date = moment(datetime);
  return date.format('L');
};

export const compareVersionDates = (prev: ModelVersion, next: ModelVersion) => {
  if (prev.oUploadDate < next.oUploadDate) {
    return -1;
  }

  if (prev.oUploadDate > next.oUploadDate) {
    return 1;
  }

  return 0;
};

export const formatDateInMonth = (date: Date): [string, string] => {
  const key =
    (String(date.getMonth() + 1).length === 1
      ? String('0') + String(date.getMonth() + 1)
      : String(date.getMonth() + 1)) +
    String('_') +
    String(date.getFullYear()); // +1 as the getMonth() start from the index 0

  const value = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'long' }).format(date);
  return [key, value];
};
