import { Suspense } from 'react';
import { Navigator } from './app/components/route';

function App() {
  return (
    <Suspense fallback="loading in progress..">
      <Navigator />
    </Suspense>
  );
}

export default App;
