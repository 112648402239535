import { AsyncThunk, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { UserModelList, UserModel, SaveUserRequest } from '../../models/users';
import { DeleteUserRequest } from '../../models/users/deleteUserRequest';
import { UserService } from '../../services/user';
import { AppStatus } from '../generalTypes';

export interface UserContextState {
  aUsers: UserModel[];
  iTotalCount: number;
  sStatus: AppStatus;
  iSelectedModelId: string[];
  iVolumeYear: string;
}

const oInitialState: UserContextState = {
  aUsers: [],
  iTotalCount: 0,
  sStatus: 'IDLE',
  iSelectedModelId: [],
  iVolumeYear: '',
};

const getUsers: AsyncThunk<UserModelList, void, {}> = createAsyncThunk(
  'userContext/users',
  async () => {
    const response = await UserService.getUsers();
    return response;
  },
);

const saveUser: AsyncThunk<UserModel, SaveUserRequest, {}> = createAsyncThunk(
  'saveUser',
  async (oData: SaveUserRequest) => {
    const response = await UserService.saveUser<UserModel>(oData);
    return response;
  },
);

const deleteUser: AsyncThunk<boolean, DeleteUserRequest, {}> = createAsyncThunk(
  'deleteUser',
  async (oData: DeleteUserRequest) => {
    const response = await UserService.saveUser<boolean>(oData);
    return response;
  },
);

const getUserList = (oState: UserContextState, oUser: UserModel) => {
  if (oState.aUsers.some(oModel => oModel.sUserId === oUser.sUserId)) {
    const lstModels = [...oState.aUsers];
    const iIndex = oState.aUsers.findIndex(oModel => oModel.sUserId === oUser.sUserId);

    lstModels[iIndex] = oUser;
    return lstModels;
  }
  return [...oState.aUsers, oUser];
};

const removeDeletedUser = (oState: UserContextState, sUserId: string) => {
  const lstModels = [...oState.aUsers];
  const iIndex = oState.aUsers.findIndex(oModel => oModel.sUserId === sUserId);
  lstModels.splice(iIndex, 1);

  return lstModels;
};

const onStateChanging = (oState: UserContextState, oNewObj: Partial<UserContextState>) =>
  ({ ...oState, ...oNewObj } as UserContextState);

const onStart = (oState: UserContextState) => onStateChanging(oState, { sStatus: 'LOADING' });

const onError = (oState: UserContextState) => onStateChanging(oState, { sStatus: 'FAILED' });

const onSuccessSave = (oState: UserContextState, oUser: UserModel) =>
  onStateChanging(oState, {
    sStatus: 'SUCCEEDED',
    iTotalCount: oState.aUsers.some(oModel => oModel.sUserId === oUser.sUserId)
      ? oState.iTotalCount
      : oState.iTotalCount + 1,
    aUsers: getUserList(oState, oUser),
  });

const onSuccessDelete = (oState: UserContextState, oUser: DeleteUserRequest, oResponse: boolean) =>
  onStateChanging(oState, {
    sStatus: 'SUCCEEDED',
    iTotalCount: oResponse ? oState.iTotalCount - 1 : oState.iTotalCount,
    aUsers: oResponse ? removeDeletedUser(oState, oUser.sUserId) : oState.aUsers,
  });

export const UserContext = createSlice({
  name: 'userContext',
  initialState: oInitialState,
  reducers: {
    resetUserData(state) {
      Object.assign(state, oInitialState);
    },
    setSelectedModelId(state, param) {
      const oState = state;
      oState.iSelectedModelId = param.payload;
    },
    setSelectedVolumeYearID(state, param) {
      const oState = state;
      oState.iVolumeYear = param.payload;
    },
  },
  extraReducers: oBuilder => {
    oBuilder
      // projects section
      .addCase(getUsers.pending, (oState: UserContextState) => {
        const oNewState = oState;
        oNewState.sStatus = 'LOADING';
      })
      .addCase(getUsers.fulfilled, (oState: UserContextState, oAction) => {
        const { iTotalCount, lstUser } = oAction.payload;
        const oNewState = oState;
        oNewState.sStatus = 'SUCCEEDED';
        oNewState.iTotalCount = iTotalCount;
        oNewState.aUsers = lstUser;
      })
      .addCase(getUsers.rejected, (oState: UserContextState) => {
        const oNewState = oState;
        oNewState.sStatus = 'FAILED';
      })
      // save user section
      .addCase(saveUser.pending, onStart)
      .addCase(saveUser.fulfilled, (oState: UserContextState, oAction) =>
        onSuccessSave(oState, oAction.payload as UserModel),
      )
      .addCase(saveUser.rejected, onError)
      // delete user section
      .addCase(deleteUser.pending, onStart)
      .addCase(deleteUser.fulfilled, (oState: UserContextState, oAction) =>
        onSuccessDelete(oState, oAction.meta.arg, oAction.payload as boolean),
      )
      .addCase(deleteUser.rejected, onError);
  },
});

export { getUsers, saveUser, deleteUser };
export const { resetUserData, setSelectedModelId, setSelectedVolumeYearID } = UserContext.actions;
export default UserContext.reducer;
