import { AsyncThunk, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  UploadVolumeBOMRequest,
  VolumePostRequest,
  VolumeResponse,
  VolumesRequest,
} from '../../models/topDown/volumes';
import { AppStatus } from '../generalTypes';
import VolumesService from '../../services/topDown/volumesService';

export interface VolumesContextState {
  sVolumesStatus: AppStatus;
  oVolumesObj: VolumeResponse;
}

const oInitialState: VolumesContextState = {
  sVolumesStatus: 'IDLE',
  oVolumesObj: {} as VolumeResponse,
};

const onStateChanging = (oState: VolumesContextState, oNewObj: Partial<VolumesContextState>) =>
  ({ ...oState, ...oNewObj } as VolumesContextState);

const onSuccessVolumes = (oState: VolumesContextState, oVolumesObj: VolumeResponse) =>
  onStateChanging(oState, {
    sVolumesStatus: 'SUCCEEDED',
    oVolumesObj,
  });

const onStartVolumes = (oState: VolumesContextState) =>
  onStateChanging(oState, { sVolumesStatus: 'LOADING' });
const onErrorVolumes = (oState: VolumesContextState) =>
  onStateChanging(oState, { sVolumesStatus: 'FAILED' });

const getVolumes: AsyncThunk<VolumeResponse, VolumesRequest, {}> = createAsyncThunk(
  'getVolumes',
  async (oData: VolumesRequest) => {
    const response = await VolumesService.getVolumes(oData);
    return response;
  },
);

const uploadVolumesBOM: AsyncThunk<any, UploadVolumeBOMRequest, {}> = createAsyncThunk(
  'uploadVolumesBOM',
  async (oData: UploadVolumeBOMRequest) => {
    const response = await VolumesService.uploadVolumesBOM(oData);
    return response;
  },
);

const updateVolumes: AsyncThunk<{}, VolumePostRequest, {}> = createAsyncThunk(
  'updateVolumes',
  async (oData: VolumePostRequest) => {
    const response = await VolumesService.updateVolumes(oData);
    return response;
  },
);

const onVolumeSuccessSave = (oState: VolumesContextState) =>
  onStateChanging(oState, { sVolumesStatus: 'SUCCEEDED' });

const onSuccessUpload = (oState: VolumesContextState) =>
  onStateChanging(oState, {
    sVolumesStatus: 'SUCCEEDED',
  });

export const VolumesContext = createSlice({
  name: 'volumesContext',
  initialState: oInitialState,
  reducers: {},
  extraReducers: oBuilder => {
    oBuilder
      // get bom for model view
      .addCase(getVolumes.pending, onStartVolumes)
      .addCase(getVolumes.fulfilled, (oState: VolumesContextState, oAction) =>
        onSuccessVolumes(oState, oAction.payload),
      )
      .addCase(getVolumes.rejected, onErrorVolumes)
      // update volumes
      .addCase(updateVolumes.pending, onStartVolumes)
      .addCase(updateVolumes.fulfilled, (oState: VolumesContextState) =>
        onVolumeSuccessSave(oState),
      )
      .addCase(updateVolumes.rejected, onErrorVolumes)

      // upload volumes BOM
      .addCase(uploadVolumesBOM.pending, onStartVolumes)
      .addCase(uploadVolumesBOM.fulfilled, (oState: VolumesContextState) => onSuccessUpload(oState))
      .addCase(uploadVolumesBOM.rejected, onErrorVolumes);
  },
});

export { getVolumes, updateVolumes, uploadVolumesBOM };
export default VolumesContext.reducer;
