export const validEmail = (sContent: string): boolean => /\S+@\S+\.\S+/.test(sContent);

export const similarity = (path1: string, path2: string): number => {
  // Split the paths into segments and remove any empty segments
  const pathSegments1 = path1.split('/').filter(segment => segment.length > 0);
  const pathSegments2 = path2.split('/').filter(segment => segment.length > 0);

  // Find the minimum length between the two paths
  const minLength = Math.min(pathSegments1.length, pathSegments2.length);

  // Initialize a counter to keep track of the number of similar segments
  let similarityCounter = 0;

  // Compare the segments of the paths
  for (let Loop = 0; Loop < minLength; Loop += 1) {
    if (pathSegments1[Loop] === pathSegments2[Loop]) {
      similarityCounter += 1;
    } else {
      // If a segment doesn't match, break out of the loop
      break;
    }
  }

  // Calculate the similarity as a percentage of the maximum path length
  return (similarityCounter / Math.max(pathSegments1.length, pathSegments2.length)) * 100;
};

export const makeid = (length: number) => {
  let result: string = '';
  const characters: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength: number = characters.length;
  for (let counter = 0; counter < length; ) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

export const onlyUnique = (value: any, index: any, array: any[]) => array.indexOf(value) === index;

export const checkJsonValue = (jsonStr: string, searchValue: string): boolean => {
  const jsonObj = JSON.parse(jsonStr);
  return Object.values(jsonObj).some(value => {
    let result: boolean = false;
    if (typeof value === 'object') {
      result = checkJsonValue(JSON.stringify(value), searchValue);
    } else if (
      typeof value === 'string' &&
      value.toLowerCase().includes(searchValue.toLowerCase())
    ) {
      result = true;
    } else if (Array.isArray(value)) {
      result = value.some(item => checkJsonValue(JSON.stringify(item), searchValue));
    }
    return result;
  });
};
