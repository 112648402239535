/* eslint-disable no-plusplus */

import {
  BOMStructure,
  CostedBomTable,
  CostedBomTableList,
} from '../../models/costedBom/costedBomModel';
import { formatNumber } from '../../utils';

class CostedBomGeneralService {
  static convertArrayForTreeTable = (aBOMArray: BOMStructure[]) => {
    const sortedArray = [...aBOMArray];
    // sortedArray.sort(
    //   (prev, next) =>
    //     prev.iLevel - next.iLevel
    // );

    const finalArray: CostedBomTable[] = [];
    const filterList: CostedBomTableList = {
      iLevel: [],
      sModuleArea: [],
      sModule: [],
      sComponent: [],
      sANC: [],
      sANCDescription: [],
      deDirectMaterial: [],
      deDirectLabour: [],
      iQuantity: [],
      aFeatures: [],
      deFOV: [],
      deStk1: [],
      iConfidentLevel: [],
    };

    const isNextLevelSimillar = (
      index: number,
      lArray: CostedBomTable[] | undefined,
      level: number,
    ) => {
      if (!lArray) {
        return false;
      }

      if (index + 1 >= lArray.length) {
        return false;
      }

      if (lArray[index + 1].iLevel > level) {
        return false;
      }

      return true;
    };

    const getChildren = (
      iLevel: number,
      lArray: CostedBomTable[] | undefined,
      fVal: CostedBomTable,
    ) => {
      const fArray: CostedBomTable[] | undefined = lArray ? [...lArray] : undefined;
      let returnValue: CostedBomTable[] = [];
      if (fArray?.length === 0 || !fArray) {
        returnValue = [fVal];
      } else if (fArray) {
        for (let nmt = 0; nmt < fArray.length; nmt++) {
          if (iLevel > fArray[nmt].iLevel && !isNextLevelSimillar(nmt, lArray, iLevel)) {
            fArray[nmt].children = getChildren(
              iLevel,
              fArray[nmt].children,
              fVal,
            ) as CostedBomTable[];

            break;
          } else if (iLevel === fArray[nmt].iLevel) {
            fArray.push(fVal);
            break;
          }
        }
        returnValue = fArray;
      }

      return returnValue;
    };

    for (let int = 0; int < sortedArray.length; int++) {
      const bomItem = sortedArray[int];

      Object.keys(filterList).forEach(key => {
        if (bomItem[key as keyof BOMStructure] || bomItem[key as keyof BOMStructure] === 0) {
          if (key === 'aFeatures') {
            const tFeatures = bomItem.aFeatures.map(item => item.sDescription).join(', ');
            if (tFeatures) {
              filterList[key as keyof CostedBomTableList].push({
                text: String(tFeatures),
                value: String(tFeatures).toLowerCase(),
                column: key,
              });
            }
          } else {
            filterList[key as keyof CostedBomTableList].push({
              text: String(bomItem[key as keyof BOMStructure]),
              value: String(bomItem[key as keyof BOMStructure]).toLowerCase(),
              column: key,
            });
          }
        }
      });

      const fValue: CostedBomTable = {
        key: `${String(bomItem.iBomRow)}Level${String(bomItem.iLevel)}`,
        sModuleArea: bomItem.sModuleArea,
        sModule: bomItem.sModule,
        sComponent: bomItem.sComponent,
        iBomRow: bomItem.iBomRow,
        sANC: bomItem.sANC,
        sANCDescription: bomItem.sANCDescription,
        deDirectMaterial: formatNumber(bomItem.deDirectMaterial, 2),
        deDirectLabour: formatNumber(bomItem.deDirectLabour, 2),
        iQuantity: formatNumber(bomItem.iQuantity, 2),
        aFeatures: bomItem.aFeatures.map(item => item.sDescription).join(', '),
        deFOV: formatNumber(bomItem.deFOV, 2),
        deStk1: formatNumber(bomItem.deStk1, 2),
        iConfidentLevel: bomItem.iConfidentLevel,
        iLevel: bomItem.iLevel,
      };

      if (finalArray.length === 0) {
        finalArray.push(fValue);
      } else {
        let newRecord: boolean = false;
        for (let nmt = 0; nmt < finalArray.length; nmt++) {
          if (
            Number(bomItem.iLevel) > finalArray[nmt].iLevel &&
            !isNextLevelSimillar(nmt, finalArray, Number(bomItem.iLevel))
          ) {
            finalArray[nmt].children = getChildren(
              Number(bomItem.iLevel),
              finalArray[nmt].children,
              fValue,
            ) as CostedBomTable[];
          } else if (Number(bomItem.iLevel) === finalArray[nmt].iLevel) {
            newRecord = true;
            break;
          }
        }

        if (newRecord) {
          finalArray.push(fValue);
        }
      }
    }

    return { finalArray, filterList };
  };
}

export default CostedBomGeneralService;
