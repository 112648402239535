import { AsyncThunk, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ChartModelRequest } from '../../models/analysis/chartModelRequest';
import { ChartRequest } from '../../models/analysis/chartRequest';
import { DeviationsByModuleArea } from '../../models/projectManagement/deviationsByModuleArea';
import {
  CostImplicationByModel,
  ProjectCheckpointsModel,
  TopDownVsBottomUpRequest,
} from '../../models/projectOverview';
import { DeviationsByModel } from '../../models/projectOverview/deviationByModel';
import { DeviationsModuleArea } from '../../models/projectOverview/largestDeviationsModuleArea';
import { ProjectDetailModel } from '../../models/projectOverview/projectDetailModel';
import { YearlyVolume } from '../../models/projectOverview/yearlyVolume';
import { ProjectList, ProjectListItem, ProjectRefresh } from '../../models/projects';
import { ProjectService } from '../../services/project';
import { AppStatus } from '../generalTypes';

export interface ProjectContextState {
  aProjects: ProjectListItem[];
  aEditableProjects: ProjectListItem[];
  iTotal: number;
  iTotalEditable: number;
  sStatus: AppStatus;
  oProjectCheckpoints: ProjectCheckpointsModel | null;
  oProjectDetail: ProjectDetailModel | null;
  sDeviationsModuleAreaStatus: AppStatus;
  aDeviationsModuleArea: DeviationsModuleArea[];
  sStatusByModelStatus: AppStatus;
  aStatusByModel: DeviationsByModel[];
  sDeviationsByModuleAreaStatus: AppStatus;
  aDeviationsByModuleArea: DeviationsByModuleArea[];
  sYearlyVolumeStatus: AppStatus;
  aYearlyVolume: YearlyVolume[];
  sCostImplicationByModelStatus: AppStatus;
  aCostImplicationByModel: CostImplicationByModel[];
}

const oInitialState: ProjectContextState = {
  aProjects: [],
  aEditableProjects: [],
  iTotal: 0,
  iTotalEditable: 0,
  sStatus: 'IDLE',
  oProjectCheckpoints: null,
  oProjectDetail: null,
  sDeviationsModuleAreaStatus: 'IDLE',
  aDeviationsModuleArea: [],
  sStatusByModelStatus: 'IDLE',
  aStatusByModel: [],
  sDeviationsByModuleAreaStatus: 'IDLE',
  aDeviationsByModuleArea: [],
  sYearlyVolumeStatus: 'IDLE',
  aYearlyVolume: [],
  sCostImplicationByModelStatus: 'IDLE',
  aCostImplicationByModel: [],
};

const getProjects: AsyncThunk<ProjectList, void, {}> = createAsyncThunk(
  'projectContext/projects',
  async () => {
    const response = await ProjectService.getProjects();
    return response;
  },
);

const updateLastUpdateTime: AsyncThunk<ProjectRefresh, string, {}> = createAsyncThunk(
  'projectContext/refresh-last-edit-time-projects',
  async (sProjectId: string) => {
    const response = await ProjectService.refreshUpdateTime(sProjectId);
    return response;
  },
);

const getEditableProjects: AsyncThunk<ProjectList, void, {}> = createAsyncThunk(
  'projectContext/editable-projects',
  async () => {
    const response = await ProjectService.getEditableProjects();
    return response;
  },
);

const getWorkflowDetail: AsyncThunk<ProjectCheckpointsModel, string, {}> = createAsyncThunk(
  'getWorkflowDetail',
  async (sProjectId: string) => {
    const response = await ProjectService.getWorkflowDetail(sProjectId);
    return response;
  },
);

const getProjectDetail: AsyncThunk<ProjectDetailModel, string, {}> = createAsyncThunk(
  'getProjectDetail',
  async (sProjectId: string) => {
    const response = await ProjectService.getProjectDetail(sProjectId);
    return response;
  },
);

const retrieveLargestDeviationsByModuleArea: AsyncThunk<DeviationsModuleArea[], ChartRequest, {}> =
  createAsyncThunk('retrieveLargestDeviationsByModuleArea', async (oData: ChartRequest) => {
    const response = await ProjectService.retrieveLargestDeviationsByModuleArea(oData);
    return response;
  });

const retrieveStk1DeviationByModel: AsyncThunk<DeviationsByModel[], ChartRequest, {}> =
  createAsyncThunk('retrieveStk1DeviationByModel', async (oData: ChartRequest) => {
    const response = await ProjectService.retrieveStk1DeviationByModel(oData);
    return response;
  });

const retrieveModuleAreaCostImplication: AsyncThunk<
  DeviationsByModuleArea[],
  ChartModelRequest,
  {}
> = createAsyncThunk('retrieveModuleAreaCostImplication', async (oData: ChartModelRequest) => {
  const response = await ProjectService.retrieveStk1DeviationByModuleArea(oData);
  return response;
});

const retrieveYearlyVolume: AsyncThunk<YearlyVolume[], TopDownVsBottomUpRequest, {}> =
  createAsyncThunk('retrieveYearlyVolume', async (oData: TopDownVsBottomUpRequest) => {
    const response = await ProjectService.retrieveYearlyVolume(oData);
    return response;
  });

const retrieveTotalCostImplicationByTrackModel: AsyncThunk<
  CostImplicationByModel[],
  TopDownVsBottomUpRequest,
  {}
> = createAsyncThunk(
  'retrieveTotalCostImplicationByTrackModel',
  async (oData: TopDownVsBottomUpRequest) => {
    const response = await ProjectService.retrieveTotalCostImplicationByTrackModel(oData);
    return response;
  },
);

const onStateChanging = (oState: ProjectContextState, oNewObj: Partial<ProjectContextState>) =>
  ({ ...oState, ...oNewObj } as ProjectContextState);

const onStart = (oState: ProjectContextState) => onStateChanging(oState, { sStatus: 'LOADING' });

const onError = (oState: ProjectContextState) => onStateChanging(oState, { sStatus: 'FAILED' });

const onSuccessWorkflow = (
  oState: ProjectContextState,
  oProjectCheckpoints: ProjectCheckpointsModel,
) => onStateChanging(oState, { sStatus: 'SUCCEEDED', oProjectCheckpoints });

const onSuccessBasicDetail = (oState: ProjectContextState, oProjectDetail: ProjectDetailModel) =>
  onStateChanging(oState, { sStatus: 'SUCCEEDED', oProjectDetail });

const onStartDeviations = (oState: ProjectContextState) =>
  onStateChanging(oState, { sDeviationsModuleAreaStatus: 'LOADING' });
const onErrorDeviations = (oState: ProjectContextState) =>
  onStateChanging(oState, { sDeviationsModuleAreaStatus: 'FAILED' });
const onSuccessDeviations = (
  oState: ProjectContextState,
  aDeviationsModuleArea: DeviationsModuleArea[],
) =>
  onStateChanging(oState, {
    sDeviationsModuleAreaStatus: 'SUCCEEDED',
    aDeviationsModuleArea,
  });

const onStartStatus = (oState: ProjectContextState) =>
  onStateChanging(oState, { sStatusByModelStatus: 'LOADING' });
const onErrorStatus = (oState: ProjectContextState) =>
  onStateChanging(oState, { sStatusByModelStatus: 'FAILED' });
const onSuccessStatus = (oState: ProjectContextState, aStatusByModel: DeviationsByModel[]) =>
  onStateChanging(oState, { sStatusByModelStatus: 'SUCCEEDED', aStatusByModel });

const onStartDeviationsModuleArea = (oState: ProjectContextState) =>
  onStateChanging(oState, { sDeviationsByModuleAreaStatus: 'LOADING' });
const onErrorDeviationsModuleArea = (oState: ProjectContextState) =>
  onStateChanging(oState, { sDeviationsByModuleAreaStatus: 'FAILED' });
const onSuccessDeviationsModuleArea = (
  oState: ProjectContextState,
  aDeviationsByModuleArea: DeviationsByModuleArea[],
) =>
  onStateChanging(oState, {
    sDeviationsByModuleAreaStatus: 'SUCCEEDED',
    aDeviationsByModuleArea,
  });

const onStartYearlyVolume = (oState: ProjectContextState) =>
  onStateChanging(oState, { sYearlyVolumeStatus: 'LOADING' });
const onErrorYearlyVolume = (oState: ProjectContextState) =>
  onStateChanging(oState, { sYearlyVolumeStatus: 'FAILED' });
const onSuccessYearlyVolume = (oState: ProjectContextState, aYearlyVolume: YearlyVolume[]) =>
  onStateChanging(oState, { sYearlyVolumeStatus: 'SUCCEEDED', aYearlyVolume });

const onStartCostImplication = (oState: ProjectContextState) =>
  onStateChanging(oState, { sCostImplicationByModelStatus: 'LOADING' });
const onErrorCostImplication = (oState: ProjectContextState) =>
  onStateChanging(oState, { sCostImplicationByModelStatus: 'FAILED' });
const onSuccessCostImplication = (
  oState: ProjectContextState,
  aCostImplicationByModel: CostImplicationByModel[],
) =>
  onStateChanging(oState, { sCostImplicationByModelStatus: 'SUCCEEDED', aCostImplicationByModel });

export const ProjectContext = createSlice({
  name: 'projectContext',
  initialState: oInitialState,
  reducers: {
    clearProjectDetailStore(state) {
      const oState = state;
      oState.oProjectCheckpoints = oInitialState.oProjectCheckpoints;
      oState.oProjectDetail = oInitialState.oProjectDetail;
    },
    clearProjectsStore(state) {
      const oState = state;
      oState.aProjects = oInitialState.aProjects;
      oState.aEditableProjects = oInitialState.aEditableProjects;
      oState.iTotal = oInitialState.iTotal;
      oState.iTotalEditable = oInitialState.iTotalEditable;
    },
  },
  extraReducers: oBuilder => {
    oBuilder
      // projects section
      .addCase(getProjects.pending, (oState: ProjectContextState) => {
        const oNewState = oState;
        oNewState.sStatus = 'LOADING';
      })
      .addCase(getProjects.fulfilled, (oState: ProjectContextState, oAction) => {
        const { iTotalCount, lstProjects } = oAction.payload;
        const oNewState = oState;
        oNewState.sStatus = 'SUCCEEDED';
        oNewState.iTotal = iTotalCount;
        oNewState.aProjects = lstProjects;
      })
      .addCase(getProjects.rejected, (oState: ProjectContextState) => {
        const oNewState = oState;
        oNewState.sStatus = 'FAILED';
      })

      // workflow section
      .addCase(getWorkflowDetail.pending, onStart)
      .addCase(getWorkflowDetail.fulfilled, (oState: ProjectContextState, oAction) =>
        onSuccessWorkflow(oState, oAction.payload),
      )
      .addCase(getWorkflowDetail.rejected, onError)

      // project detail section
      .addCase(getProjectDetail.pending, onStart)
      .addCase(getProjectDetail.fulfilled, (oState: ProjectContextState, oAction) =>
        onSuccessBasicDetail(oState, oAction.payload),
      )
      .addCase(getProjectDetail.rejected, onError)

      // editable project list
      .addCase(getEditableProjects.pending, onStart)
      .addCase(getEditableProjects.fulfilled, (oState: ProjectContextState, oAction) => {
        const { iTotalCount, lstProjects } = oAction.payload;
        const oNewState = oState;
        oNewState.sStatus = 'SUCCEEDED';
        oNewState.iTotalEditable = iTotalCount;
        oNewState.aEditableProjects = lstProjects;
      })
      .addCase(getEditableProjects.rejected, onError)

      // update last date time
      .addCase(updateLastUpdateTime.fulfilled, (oState: ProjectContextState, oAction) => {
        const { iCondition, sConditionLastUpdate } = oAction.payload;
        const pLists: ProjectListItem[] = oState.aProjects.map(item => {
          if (item.sProjectId === String(oAction.meta.arg)) {
            return { ...item, iCondition, sConditionLastUpdate };
          }
          return item;
        });

        const oNewState = oState;
        oNewState.aProjects = pLists;
      })

      // deviations by module area section
      .addCase(retrieveLargestDeviationsByModuleArea.pending, onStartDeviations)
      .addCase(
        retrieveLargestDeviationsByModuleArea.fulfilled,
        (oState: ProjectContextState, oAction) => onSuccessDeviations(oState, oAction.payload),
      )
      .addCase(retrieveLargestDeviationsByModuleArea.rejected, onErrorDeviations)

      // status by model section
      .addCase(retrieveStk1DeviationByModel.pending, onStartStatus)
      .addCase(retrieveStk1DeviationByModel.fulfilled, (oState: ProjectContextState, oAction) =>
        onSuccessStatus(oState, oAction.payload),
      )
      .addCase(retrieveStk1DeviationByModel.rejected, onErrorStatus)

      // module area cost implication section
      .addCase(retrieveModuleAreaCostImplication.pending, onStartDeviationsModuleArea)
      .addCase(
        retrieveModuleAreaCostImplication.fulfilled,
        (oState: ProjectContextState, oAction) =>
          onSuccessDeviationsModuleArea(oState, oAction.payload),
      )
      .addCase(retrieveModuleAreaCostImplication.rejected, onErrorDeviationsModuleArea)

      // yearly volume section
      .addCase(retrieveYearlyVolume.pending, onStartYearlyVolume)
      .addCase(retrieveYearlyVolume.fulfilled, (oState: ProjectContextState, oAction) =>
        onSuccessYearlyVolume(oState, oAction.payload),
      )
      .addCase(retrieveYearlyVolume.rejected, onErrorYearlyVolume)

      // cost implication by model section
      .addCase(retrieveTotalCostImplicationByTrackModel.pending, onStartCostImplication)
      .addCase(
        retrieveTotalCostImplicationByTrackModel.fulfilled,
        (oState: ProjectContextState, oAction) => onSuccessCostImplication(oState, oAction.payload),
      )
      .addCase(retrieveTotalCostImplicationByTrackModel.rejected, onErrorCostImplication);
  },
});

export {
  getProjects,
  getWorkflowDetail,
  getProjectDetail,
  getEditableProjects,
  retrieveLargestDeviationsByModuleArea,
  retrieveStk1DeviationByModel,
  retrieveModuleAreaCostImplication,
  retrieveYearlyVolume,
  retrieveTotalCostImplicationByTrackModel,
  updateLastUpdateTime,
};
export const { clearProjectDetailStore, clearProjectsStore } = ProjectContext.actions;
export default ProjectContext.reducer;
