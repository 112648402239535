/* eslint-disable no-plusplus */
import {
  CompareOneToOneBOMStructure,
  CompareOneToOneBomTableList,
  ModuleAreaLevel,
} from '../../models/bottomUp/compareOneToOneModel';
import { makeid } from '../../utils';

class CompareOneToOneBomGeneralService {
  static convertArrayForTreeTable = (aBOMArray: CompareOneToOneBOMStructure[]) => {
    const sortedArray = [...aBOMArray];
    const filterList: CompareOneToOneBomTableList = {
      iLevel: [],
      sModuleArea: [],
      sModule: [],
      sComponent: [],
      sANC: [],
      sANCDescription: [],
      sIDCO: [],
      deStk1: [],
      deQuantitySource: [],
      deCostSource: [],
      deQuantityDestination: [],
      deCostDestination: [],
      deQuantityGap: [],
      deCostGap: [],
    };

    const finalArray: ModuleAreaLevel[] = [];

    for (let int = 0; int < sortedArray.length; int++) {
      const bomItem = sortedArray[int];

      Object.keys(filterList).forEach(key => {
        if (
          bomItem[key as keyof CompareOneToOneBOMStructure] ||
          bomItem[key as keyof CompareOneToOneBOMStructure] === 0
        ) {
          filterList[key as keyof CompareOneToOneBomTableList].push({
            text: String(bomItem[key as keyof CompareOneToOneBOMStructure]),
            value: String(bomItem[key as keyof CompareOneToOneBOMStructure]),
            column: key,
          });
        }
      });

      const obj: ModuleAreaLevel = {
        key: `${bomItem.sModuleArea}-${makeid(4)}`,
        sModuleArea: bomItem.sModuleArea,
        sModule: bomItem.sModule,
        sComponent: bomItem.sComponent,
        iLevel: bomItem.iLevel,
        sANCDescription: bomItem.sANCDescription,
        sANC: bomItem.sANC,
        sIDCO: bomItem.sIDCO,
        deStk1: bomItem.deStk1,
        deQuantitySource: bomItem.deQuantitySource,
        deCostSource: bomItem.deCostSource,
        deQuantityDestination: bomItem.deQuantityDestination,
        deCostDestination: bomItem.deCostDestination,
        deQuantityGap: bomItem.deQuantityGap,
        deCostGap: bomItem.deCostGap,
        children: undefined,
      };

      finalArray.push(obj);
    }
    return { finalArray, filterList };
  };
}

export default CompareOneToOneBomGeneralService;
