import { useEffect, useState } from 'react';
import {
  DefaultDropDownSelectable,
  DropDown,
  DropDownDataType,
  DropDownKey,
  DropDownKeyCommon,
  DropDownKeyModel,
  DropDownKeyProject,
  DropDownSelectable,
  ProjectModelInfo,
} from '../../models/shared';
import { RootState } from '../../store';
import {
  getDropdowns,
  loadLocalDropDown,
  getProjectDropdowns,
  clearCompareByDropdownData,
} from '../../store/shared';
import { formatDateInMonth } from '../../utils';
import { UserRole } from '../../utils/UserRoles';
import { useUserContextData } from '../login/customLoginHooks';
import { useAppDispatch, useAppSelector } from '../store/customHooks';

type DropdownListCommonData = {
  readonly oDropdowns: Record<DropDownKeyCommon, DropDownSelectable[]>;
  toggleSelection: (
    sKey: DropDownKeyCommon,
    oOption: DropDownSelectable,
    multiple?: boolean,
  ) => void;
  readonly bLoading: boolean;
  readonly bHasError: boolean;
  dataRefresh: () => void;
};

type DropdownListProjectData = {
  readonly oDropdowns: Record<DropDownKeyProject, DropDownSelectable[]>;
  toggleSelection: (
    sKey: DropDownKeyProject,
    oOption: DropDownSelectable,
    multiple?: boolean,
  ) => void;
  readonly bLoading: boolean;
  readonly bHasError: boolean;
  dataRefresh: () => void;
};

type DropdownListModelData = {
  readonly oDropdowns: Record<DropDownKeyModel, DropDownSelectable[]>;
  toggleSelection: (
    sKey: DropDownKeyModel,
    oOption: DropDownSelectable,
    multiple?: boolean,
  ) => void;
  readonly bLoading: boolean;
  readonly bHasError: boolean;
  dataRefresh: () => void;
};
// you can load data in two ways, with redux or without redux. only manula data passing will work with the local data
// loadLocalReference need to set true to load local data
export function useDropdownListCommon(
  aData: DropDownKeyCommon[],
  aType?: DropDownDataType,
  lData?: any,
  aDefaultSelected?: DefaultDropDownSelectable[],
  loadLocalReference?: boolean,
): DropdownListCommonData {
  const dispatch = useAppDispatch();
  const { oDropdowns, sStatus } = useAppSelector((state: RootState) => state.generalContext);
  const [oSelectableDropdown, setSelectableDropdown] = useState<
    Record<DropDownKeyCommon, DropDownSelectable[]>
  >({} as Record<DropDownKeyCommon, DropDownSelectable[]>);
  const { oUserContext } = useUserContextData();
  const [localODropdowns, setLocalODropdowns] = useState<Record<DropDownKey, DropDown[]>>(
    {} as Record<DropDownKey, DropDown[]>,
  );

  useEffect(() => {
    if (aType === DropDownDataType.LOCAL) {
      if (lData) {
        if (loadLocalReference) {
          setLocalODropdowns(lData.oDropdowns as Record<DropDownKey, DropDown[]>);
        } else {
          dispatch(loadLocalDropDown(lData));
        }
      }
      //
    } else {
      dispatch(getDropdowns({ oData: aData, oType: aType || DropDownDataType.COMMON }));
    }
  }, [JSON.stringify(aData), JSON.stringify(lData)]);

  useEffect(() => {
    const oValidDropdowns: Record<DropDownKeyCommon, DropDownSelectable[]> = <
      Record<DropDownKeyCommon, DropDownSelectable[]>
    >{};

    let oDropDownLocal: Record<DropDownKey, DropDown[]> = oDropdowns;

    if (loadLocalReference) {
      oDropDownLocal = localODropdowns;
    }

    Object.entries(oDropDownLocal).forEach(dropdown => {
      const [key, value] = dropdown;

      if (aData.includes(key as DropDownKeyCommon)) {
        let aDropdownSelectable: DropDownSelectable[] | null = [];

        if (
          key === DropDownKeyCommon.CX_AREA &&
          oUserContext?.iRoleId !== UserRole.HEAD_OF_COSTING
        ) {
          aDropdownSelectable = value.map((item: DropDown) => ({
            bSelected: item.sDropDownID === oUserContext?.iCxAreaID.toString(),
            sDropDownID: item.sDropDownID,
            sDropDrownName: item.sDropDrownName,
            sPostFix: item.sPostFix,
          }));
        } else {
          aDropdownSelectable = value?.map((item: DropDown) => {
            let sKey = item.sDropDownID;
            let sValue = item.sDropDrownName;

            if (key === DropDownKeyCommon.CHECKPOINT_DATE) {
              const obj = formatDateInMonth(new Date(item.sDropDrownName));
              sKey = obj[0];
              sValue = obj[1];
            }

            let selected: boolean = false;

            if (aDefaultSelected) {
              aDefaultSelected.forEach(lItem => {
                if (
                  lItem.sDropDownID === item.sDropDownID &&
                  lItem.bSelected &&
                  lItem.sKey === key
                ) {
                  selected = true;
                }
              });
            }

            return {
              bSelected: selected,
              sDropDownID: sKey,
              sDropDrownName: sValue,
              sPostFix: item.sPostFix,
            };
          });

          // logic to return unique results
          if (key === DropDownKeyCommon.CHECKPOINT_DATE) {
            aDropdownSelectable = aDropdownSelectable
              .filter((thing, index, arr) => {
                const result = arr.find(item => item?.sDropDrownName === thing?.sDropDrownName);

                if (result !== undefined) {
                  return arr.indexOf(result) === index;
                }
                return arr;
              })
              .sort((prev, next) => {
                const firstPart = prev.sDropDownID.split('_');
                const secondPart = next.sDropDownID.split('_');
                const firstItem = Number(firstPart[1] + firstPart[0]);
                const secondItem = Number(secondPart[1] + secondPart[0]);
                return firstItem - secondItem;
              });
          }
        }

        oValidDropdowns[key as DropDownKeyCommon] = aDropdownSelectable;
      }
    });

    const newlists: Record<DropDownKeyCommon, DropDownSelectable[]> = {} as Record<
      DropDownKeyCommon,
      DropDownSelectable[]
    >;
    aData.map((element: DropDownKeyCommon) => {
      if (oValidDropdowns[element as DropDownKeyCommon]) {
        newlists[element] = oValidDropdowns[element as DropDownKeyCommon];
      } else {
        newlists[element] = [];
      }

      return true;
    });

    setSelectableDropdown(newlists);
  }, [
    JSON.stringify(oDropdowns),
    JSON.stringify(localODropdowns),
    JSON.stringify(aDefaultSelected),
  ]);

  const toggleSelection = (sKey: DropDownKey, oOption: DropDownSelectable, multiple?: boolean) => {
    const oValidDropdowns: Record<DropDownKey, DropDownSelectable[]> = <
      Record<DropDownKey, DropDownSelectable[]>
    >{};

    Object.entries(oSelectableDropdown).forEach(dropdown => {
      const [key, value] = dropdown;

      if (key === sKey) {
        value.map(item => {
          const currentDropdown = item;

          if (currentDropdown.sDropDownID === oOption.sDropDownID) {
            currentDropdown.bSelected = !currentDropdown.bSelected;
          } else if (!multiple) {
            // avoid resetting other options when using mutiple select options
            currentDropdown.bSelected = false;
          }
          return currentDropdown;
        });
      }

      oValidDropdowns[key as DropDownKeyCommon] = value;
    });

    setSelectableDropdown(oValidDropdowns);
  };

  const dataRefresh = () => {
    if (aType === DropDownDataType.LOCAL) {
      dispatch(loadLocalDropDown(lData));
    } else {
      dispatch(getDropdowns({ oData: aData, oType: aType || DropDownDataType.COMMON }));
    }
  };

  if (sStatus === 'LOADING') {
    return {
      oDropdowns: oSelectableDropdown,
      toggleSelection,
      bHasError: false,
      bLoading: true,
      dataRefresh,
    };
  }

  if (sStatus === 'FAILED') {
    return {
      oDropdowns: oSelectableDropdown,
      toggleSelection,
      bHasError: true,
      bLoading: false,
      dataRefresh,
    };
  }

  return {
    oDropdowns: oSelectableDropdown,
    toggleSelection,
    bHasError: false,
    bLoading: false,
    dataRefresh,
  };
}

export function useDropdownListProject(
  aData: DropDownKeyProject[],
  iProjectId?: number,
  isAncList?: boolean,
  iCheckpointId?: number,
): DropdownListProjectData {
  const dispatch = useAppDispatch();
  const { oProjectDropdowns, sStatus } = useAppSelector((state: RootState) => state.generalContext);
  const [oSelectableDropdown, setSelectableDropdown] = useState<
    Record<DropDownKeyProject, DropDownSelectable[]>
  >({} as Record<DropDownKeyProject, DropDownSelectable[]>);

  useEffect(() => {
    if (iProjectId) {
      dispatch(
        getProjectDropdowns({
          oData: aData,
          oType: isAncList ? DropDownDataType.ANCLIST : DropDownDataType.PROJECT,
          iProjectId,
          iCheckpointId,
        }),
      );
    }
  }, [JSON.stringify(aData), iProjectId, iCheckpointId]);

  useEffect(() => {
    const oValidDropdowns: Record<DropDownKeyProject, DropDownSelectable[]> = <
      Record<DropDownKeyProject, DropDownSelectable[]>
    >{};

    Object.entries(oProjectDropdowns).forEach(dropdown => {
      const [key, value] = dropdown;

      if (aData.includes((key as DropDownKeyProject) || DropDownKeyCommon)) {
        let aDropdownSelectable: DropDownSelectable[] | null = [];

        aDropdownSelectable = value.map((item: DropDown) => {
          const sKey = item.sDropDownID;
          const sValue = item.sDropDrownName;

          return {
            bSelected: false,
            sDropDownID: sKey,
            sDropDrownName: sValue,
          };
        });

        oValidDropdowns[(key as DropDownKeyProject) || DropDownKeyCommon] = aDropdownSelectable;
      }
    });

    const newlists: Record<DropDownKeyProject, DropDownSelectable[]> = {} as Record<
      DropDownKeyProject,
      DropDownSelectable[]
    >;
    aData.map((element: DropDownKeyProject) => {
      if (oValidDropdowns[(element as DropDownKeyProject) || DropDownKeyCommon]) {
        newlists[element] = oValidDropdowns[(element as DropDownKeyProject) || DropDownKeyCommon];
      } else {
        newlists[element] = [];
      }

      return true;
    });
    setSelectableDropdown(newlists);
  }, [oProjectDropdowns]);

  const toggleSelection = (
    sKey: DropDownKeyProject,
    oOption: DropDownSelectable,
    multiple?: boolean,
  ) => {
    const oValidDropdowns: Record<DropDownKeyProject, DropDownSelectable[]> = <
      Record<DropDownKeyProject, DropDownSelectable[]>
    >{};

    Object.entries(oSelectableDropdown).forEach(dropdown => {
      const [key, value] = dropdown;

      if (key === sKey) {
        value.map(item => {
          const currentDropdown = item;

          if (currentDropdown.sDropDownID === oOption.sDropDownID) {
            currentDropdown.bSelected = !currentDropdown.bSelected;
          } else if (!multiple) {
            // avoid resetting other options when using mutiple select options
            currentDropdown.bSelected = false;
          }
          return currentDropdown;
        });
      }

      oValidDropdowns[key as DropDownKeyProject] = value;
    });

    setSelectableDropdown(oValidDropdowns);
  };

  const dataRefresh = () => {
    if (iProjectId) {
      dispatch(getProjectDropdowns({ oData: aData, oType: DropDownDataType.PROJECT, iProjectId }));
    }
  };

  if (sStatus === 'LOADING') {
    return {
      oDropdowns: oSelectableDropdown,
      toggleSelection,
      bHasError: false,
      bLoading: true,
      dataRefresh,
    };
  }

  if (sStatus === 'FAILED') {
    return {
      oDropdowns: oSelectableDropdown,
      toggleSelection,
      bHasError: true,
      bLoading: false,
      dataRefresh,
    };
  }

  return {
    oDropdowns: oSelectableDropdown,
    toggleSelection,
    bHasError: false,
    bLoading: false,
    dataRefresh,
  };
}

export function useDropdownListModel(
  aData: DropDownKeyModel[],
  oProjectData?: ProjectModelInfo | ProjectModelInfo[],
): DropdownListModelData {
  const dispatch = useAppDispatch();
  const { oDropdowns, sStatus } = useAppSelector((state: RootState) => state.generalContext);
  const [oSelectableDropdown, setSelectableDropdown] = useState<
    Record<DropDownKeyModel, DropDownSelectable[]>
  >({} as Record<DropDownKeyModel, DropDownSelectable[]>);

  const toggleSelection = (
    sKey: DropDownKeyModel,
    oOption: DropDownSelectable,
    multiple?: boolean,
  ) => {
    const oValidDropdowns: Record<DropDownKeyModel, DropDownSelectable[]> = <
      Record<DropDownKeyModel, DropDownSelectable[]>
    >{};

    Object.entries(oSelectableDropdown).forEach(dropdown => {
      const [key, value] = dropdown;

      if (key === sKey) {
        value.map(item => {
          const currentDropdown = item;

          if (currentDropdown.sDropDownID === oOption.sDropDownID) {
            currentDropdown.bSelected = !currentDropdown.bSelected;
          } else if (!multiple) {
            // avoid resetting other options when using mutiple select options
            currentDropdown.bSelected = false;
          }
          return currentDropdown;
        });
      }

      oValidDropdowns[key as DropDownKeyModel] = value;
    });

    setSelectableDropdown(oValidDropdowns);
  };

  const dataRefresh = () => {
    dispatch(getDropdowns({ oData: aData, oType: DropDownDataType.MODEL, oProjectData }));
  };

  useEffect(() => {
    if (Array.isArray(oProjectData)) {
      if ((oProjectData as ProjectModelInfo[]).length > 0) {
        dispatch(
          getDropdowns({
            oData: aData,
            oType: DropDownDataType.MULTI_MODEL,
            oProjectData,
          }),
        );
      } else {
        dispatch(clearCompareByDropdownData());
      }
    } else if (oProjectData) {
      dispatch(
        getDropdowns({
          oData: aData,
          oType: DropDownDataType.MODEL,
          oProjectData,
        }),
      );
    }
  }, [JSON.stringify(aData), JSON.stringify(oProjectData)]);

  useEffect(() => {
    const oValidDropdowns: Record<DropDownKeyModel, DropDownSelectable[]> = <
      Record<DropDownKeyModel, DropDownSelectable[]>
    >{};

    Object.entries(oDropdowns).forEach(dropdown => {
      const [key, value] = dropdown;

      if (aData.includes(key as DropDownKeyModel)) {
        let aDropdownSelectable: DropDownSelectable[] | null = [];

        aDropdownSelectable = value.map((item: DropDown) => {
          const sKey = item.sDropDownID;
          const sValue = item.sDropDrownName;

          return {
            bSelected: false,
            sDropDownID: sKey,
            sDropDrownName: sValue,
          };
        });

        oValidDropdowns[key as DropDownKeyModel] = aDropdownSelectable;
      }
    });

    const newlists: Record<DropDownKeyModel, DropDownSelectable[]> = {} as Record<
      DropDownKeyModel,
      DropDownSelectable[]
    >;
    aData.map((element: DropDownKeyModel) => {
      if (oValidDropdowns[element as DropDownKeyModel]) {
        newlists[element] = oValidDropdowns[element as DropDownKeyModel];
      } else {
        newlists[element] = [];
      }

      return true;
    });
    setSelectableDropdown(newlists);
  }, [oDropdowns]);

  if (sStatus === 'LOADING') {
    return {
      oDropdowns: oSelectableDropdown,
      toggleSelection,
      bHasError: false,
      bLoading: true,
      dataRefresh,
    };
  }

  if (sStatus === 'FAILED') {
    return {
      oDropdowns: oSelectableDropdown,
      toggleSelection,
      bHasError: true,
      bLoading: false,
      dataRefresh,
    };
  }

  return {
    oDropdowns: oSelectableDropdown,
    toggleSelection,
    bHasError: false,
    bLoading: false,
    dataRefresh,
  };
}
