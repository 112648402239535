import { AsyncThunk, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  AncExchangeRateRequest,
  AncExchangeRateResponse,
  AncListBOMModel,
  AncListRequest,
  AncListResponse,
  GetAncListRequest,
  UploadAncListRequest,
} from '../../models/ancList';
import AncListService from '../../services/ancList/ancListService';
import { AppStatus } from '../generalTypes';

export interface AncListContextState {
  sAncListBomStatus: AppStatus;
  oAncListBomObj: AncListBOMModel;
  onSubmitExchangeRateStatus: AppStatus;
}

const oInitialState: AncListContextState = {
  sAncListBomStatus: 'IDLE',
  oAncListBomObj: {} as AncListBOMModel,
  onSubmitExchangeRateStatus: 'IDLE',
};

const onStateChanging = (oState: AncListContextState, oNewObj: Partial<AncListContextState>) =>
  ({ ...oState, ...oNewObj } as AncListContextState);

const onSuccessAncListBOM = (oState: AncListContextState, oAncListBomObj: AncListBOMModel) =>
  onStateChanging(oState, {
    sAncListBomStatus: 'SUCCEEDED',
    oAncListBomObj,
  });

const onStartAnListBOM = (oState: AncListContextState) =>
  onStateChanging(oState, { sAncListBomStatus: 'LOADING' });
const onErrorAncListBOM = (oState: AncListContextState) =>
  onStateChanging(oState, { sAncListBomStatus: 'FAILED' });

const getAncListBOM: AsyncThunk<AncListBOMModel, GetAncListRequest, {}> = createAsyncThunk(
  'getAncList',
  async (oData: GetAncListRequest) => {
    const response = await AncListService.getAncList(oData);
    return response;
  },
);

const submitExchangeRate: AsyncThunk<AncExchangeRateResponse, AncExchangeRateRequest, {}> =
  createAsyncThunk('submitExchangeRate', async (oData: AncExchangeRateRequest) => {
    const response = await AncListService.submitExchangeRate(oData);
    return response;
  });

const onSuccessExchangeRateSetting = (oState: AncListContextState) =>
  onStateChanging(oState, {
    sAncListBomStatus: 'SUCCEEDED',
  });

const onStartSubmitExchangeRate = (oState: AncListContextState) =>
  onStateChanging(oState, { sAncListBomStatus: 'LOADING' });
const onErrorSubmitExchangeRate = (oState: AncListContextState) =>
  onStateChanging(oState, { sAncListBomStatus: 'FAILED' });

const submitAncList: AsyncThunk<AncListResponse, AncListRequest, {}> = createAsyncThunk(
  'submitAncList',
  async (oData: AncListRequest) => {
    const response = await AncListService.submitAncList(oData);
    return response;
  },
);

const onSuccessAncList = (oState: AncListContextState) =>
  onStateChanging(oState, {
    sAncListBomStatus: 'SUCCEEDED',
  });

const onStartSubmitAncList = (oState: AncListContextState) =>
  onStateChanging(oState, { sAncListBomStatus: 'LOADING' });
const onErrorSubmitAncList = (oState: AncListContextState) =>
  onStateChanging(oState, { sAncListBomStatus: 'FAILED' });

const uploadAncListMethod: AsyncThunk<any, UploadAncListRequest, {}> = createAsyncThunk(
  'uplaodAncList',
  async (oData: UploadAncListRequest) => {
    const response = await AncListService.uploadAncList(oData);
    return response;
  },
);

const onStateUploadChanging = (
  oState: AncListContextState,
  oNewObj: Partial<AncListContextState>,
) => ({ ...oState, ...oNewObj } as AncListContextState);

const onAncListUploadStart = (oState: AncListContextState) =>
  onStateUploadChanging(oState, { sAncListBomStatus: 'LOADING' });

const onSuccessUpload = (oState: AncListContextState) =>
  onStateUploadChanging(oState, {
    sAncListBomStatus: 'SUCCEEDED',
  });

const onAncListUploadError = (oState: AncListContextState) =>
  onStateUploadChanging(oState, { sAncListBomStatus: 'FAILED' });

export const AncListContext = createSlice({
  name: 'ancListContext',
  initialState: oInitialState,
  reducers: {
    clearAncListData: () => oInitialState,
  },
  extraReducers: oBuilder => {
    oBuilder
      // get bom for model view
      .addCase(getAncListBOM.pending, onStartAnListBOM)
      .addCase(getAncListBOM.fulfilled, (oState: AncListContextState, oAction) =>
        onSuccessAncListBOM(oState, oAction.payload),
      )
      .addCase(getAncListBOM.rejected, onErrorAncListBOM)

      // submit exchange rate save section
      .addCase(submitExchangeRate.pending, onStartSubmitExchangeRate)
      .addCase(submitExchangeRate.fulfilled, (oState: AncListContextState) =>
        onSuccessExchangeRateSetting(oState),
      )
      .addCase(submitExchangeRate.rejected, onErrorSubmitExchangeRate)

      // submit anc list save section
      .addCase(submitAncList.pending, onStartSubmitAncList)
      .addCase(submitAncList.fulfilled, (oState: AncListContextState) => onSuccessAncList(oState))
      .addCase(submitAncList.rejected, onErrorSubmitAncList)

      // upload overloaded BOM
      .addCase(uploadAncListMethod.pending, onAncListUploadStart)
      .addCase(uploadAncListMethod.fulfilled, (oState: AncListContextState) =>
        onSuccessUpload(oState),
      )
      .addCase(uploadAncListMethod.rejected, onAncListUploadError);
  },
});

export { submitExchangeRate, submitAncList };

export { getAncListBOM, uploadAncListMethod };
export const { clearAncListData } = AncListContext.actions;
export default AncListContext.reducer;
